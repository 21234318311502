@use "../abstract" as *;

:root {
  --toastify-color-progress-loading: green;

  // -----------------------------------------
  --main-hading-base-size: 0.75vw;

  @include screen-size-px(720) {
    --main-hading-base-size: 1vw;
  }

  @include screen-size-px(512) {
    --main-hading-base-size: 1.25vw;
  }

  @include screen-size-px(320) {
    --main-hading-base-size: 1.5vw;
  }

  // ---------------------------------------
  --base-particle-size: 3vw;

  @include screen-size-px(1024) {
    --base-particle-size: 6vw;
  }

  @include color-theme(
    // name light dark
    base-1 #7a41a8 #7a41a8,
    base-2 #8a14e7 #8a14e7,
    base-3 #7203cc #7203cc,
    base-4 rgba(#7203cc, 0.19) rgba(#7203cc, 0.19),
    base-5 #4eca2f #4eca2f,
    base-6 #18c2b8 #18c2b8,
    base-7 #f8f1fc #f8f1fc,
    base-8 rgba(#b479e4, 0.6) rgba(#b479e4, 0.6),
    base-9 #EDEBEB#EDEBEB,

    warn-1 #f54119 #f54119,

    comp-1 #000000 #000000,
    comp-2 #3c3c3c #3c3c3c,
    comp-3 #444444 #444444,
    comp-4 #666666 #666666,
    comp-5 #707070 #707070,
    comp-6 #868686 #868686,
    comp-7 #898989 #898989,
    comp-8 #8e8a8a #8e8a8a,
    comp-9 #a0a0a0 #a0a0a0,
    comp-10 #a2a2a2 #a2a2a2,
    comp-11 #d9d9d9 #d9d9d9,
    comp-12 #edebeb #edebeb,
    comp-13 #efefef #efefef,
    comp-14 #f4f4f4 #f4f4f4,
    comp-15 #f6eefc #f6eefc,
    comp-16 #ffffff #ffffff,
    comp-17 #bcbcbc #bcbcbc,

    shadow-1 rgba(black, 0.5) rgba(black, 0.5),
    shadow-2 rgba(black, 0.15) rgba(black, 0.15)
  );

  @include cards-variables;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  text-size-adjust: 100%;

  scroll-behavior: smooth;

  @include root-font-size-query;
}

body {
  font-family: $font-family-default;

  background-color: var(--color-comp-16);

  @include text-default;

  user-select: none;

  @include grid(
    $template-columns: 100%,
    $template-rows: minmax(100vh, 1fr),
    $align: start
  );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#container,
#root,
#__next {
  grid-column: 1 / -1;
  grid-row: 1 / -1;

  overflow: hidden;

  width: 100%;
  min-height: 100%;

  $side-space: 0rem;

  @include grid(
    $template-columns: $side-space 1fr $side-space,
    // $align-content: space-between,
    // $align-items: start
    $align: start
  );

  & > * {
    grid-column: 2 / 3;
  }
}

.layout-main-contents {
  $side-content: minmax(0rem, min-content);

  align-self: start;

  width: 100%;

  @include grid(
    $template-columns: $side-content 1fr $side-content,
    $template-rows: min-content,
    $align-content: space-between,
    $align-items: start // $align: start
  );

  & > * {
    grid-row: 1 / -1;
  }

  & > main {
    width: 100%;
  }
}

.footer {
  grid-column: 1 / -1;
  grid-row: 4 / 5;

  align-self: end;

  width: 100%;
  min-height: 5rem;

  @include layout-col;

  @include screen-size-l2 {
    min-height: 40rem;
  }
}

#modals-root {
  position: absolute;
  top: 0;
  left: 0;

  width: 0px;
  height: 0px;

  overflow: visible;
}

.container-modal {
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(black, 0.5);

  backdrop-filter: blur(2px);

  z-index: 2000;

  @include grid($template-columns: max-content, $template-rows: max-content);

  & > div {
    width: auto;
    height: auto;

    background-color: transparent;

    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
  }
}

// All scroll bars
::-webkit-scrollbar {
  width: $scroll-bar-size;
  height: $scroll-bar-size;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--color-comp-9);
  border-radius: $scroll-bar-border-radius;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-comp-3);
  border-radius: $scroll-bar-border-radius;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-comp-1);
}
