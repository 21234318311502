@use "../../abstract" as *;

.scrollable {
  width: 100%;
  height: 100%;

  overflow-y: auto;
  overflow-x: hidden;

  transition: $transition-default;

  @include layout-col-start;

  @include screen-size-l2 {
    overflow-y: hidden;
    overflow-x: auto;

    @include layout-row-start;
  }

  @include scroll-bar-default;

  // With Filters
  &-wf {
    width: 100%;
    height: 100%;

    overflow: hidden;

    @include layout-col-mic-1fr;

    .filters {
      width: 100%;

      margin: 1rem 0;

      padding-left: $shadow-resolve-padding;
      padding-right: $shadow-resolve-padding;
    }
  }
}
