@use "../../abstract" as *;

.select {
  & > * {
    width: 100%;
  }
}

.progress-bar {
  width: 100%;

  $progress-bar-height: 1.8rem;
  $progress-bar-border-size: 0.3rem;

  height: $progress-bar-height;

  border-radius: calc($progress-bar-height / 2);
  border: $progress-bar-border-size solid rgba(black, 0.1);

  background-color: transparent;

  transition: $transition-default;

  & > div {
    height: 100%;
    border-radius: calc(
      ($progress-bar-height - 2 * $progress-bar-border-size) / 2
    );
    background-color: var(--color-base-2);
  }
}

.divider {
  &-horizontal {
    width: 100%;
    height: 0.1rem;

    background-color: var(--color-comp-9);
  }

  &-vertical {
    width: 0.1rem;
    min-height: 90vh;
    height: 100%;

    background-color: var(--color-comp-9);
  }
}

.calendar {
  width: 100%;

  & > * {
    width: 100% !important;
  }
}

.general-slider {
  width: 100%;

  overflow: hidden;

  .slick-list {
    padding: 0.8rem;
  }
}

.btn {
  transition: $transition-default;

  &:hover {
    @include interact-default;
  }

  &:active {
    @include interact-default(active);
  }

  &-primary {
    background-color: var(--color-base-1);

    color: var(--color-comp-16);
    font-size: $font-size-default;
    font-weight: $font-weight-l2;
    line-height: $line-height-l2;

    .icon-processing {
      @include layout-row;
      margin: 0 1rem;
    }

    padding: 1rem;

    border: none;
    border-radius: 0.8rem;

    & > svg {
      fill: var(--color-comp-16);
      height: 1.8rem;
      width: 1.8rem;

      margin-right: 0.8rem;
    }
  }

  &-tertiary {
    background-color: var(--color-comp-13);

    color: var(--color-comp-1);
    font-size: $font-size-l7;
    font-weight: $font-weight-l4;
    line-height: $line-height-l2;

    padding: 1rem;

    border: 1px solid var(--color-comp-10);
    border-radius: 0.8rem;

    & > svg {
      fill: var(--color-comp-16);
      height: 1.8rem;
      width: 1.8rem;

      margin-right: 0.8rem;
    }
  }

  &-insert {
    background-color: var(--color-base-1);

    color: var(--color-comp-16);
    font-size: $font-size-default;
    font-weight: $font-weight-l2;
    line-height: $line-height-l2;

    .icon-processing {
      @include layout-row;
      margin: 0 1rem;
    }

    @include grid(
      $template-columns: max-content 1fr repeat(2, max-content),
      $justify-content: start
    );

    padding: 1rem;

    border: none;
    border-radius: 0.8rem;

    & span {
      justify-self: center;
    }

    & > svg {
      fill: var(--color-comp-16);
      height: 1.8rem;
      width: 1.8rem;

      margin-right: 0.8rem;
    }
  }
}

.react-select {
  &-container {
    // height: 3.8rem;

    justify-self: end;

    @include screen-size-l2 {
      font-size: px2rem-l1(17px);
    }

    .react-select-control {
      // height: 3.8rem;

      width: 100%;

      transition: $transition-default;
    }

    .react-select-indicators-container {
      @include layout-row;
    }

    &-focused {
      width: 100%;
    }

    &-not-focused {
      width: 100%;

      @include screen-size-l2 {
        // width: 11rem;
        width: auto;
      }

      @include screen-size-l1 {
        // width: 11rem;
        width: auto;
      }
    }

    & > div {
      // width: 100%;

      @include layout-row-space-between;

      & > div {
        & > div {
          & > div {
            // width: 100%;
            overflow: hidden;

            white-space: nowrap;
          }
        }
      }
    }
  }
}

.theme-selector {
  .theme-name {
    margin-right: 1.2rem;

    font-size: $font-size-default;
    font-weight: $font-weight-l4;
    line-height: $line-height-default;
    font-family: $font-family-1;
    color: var(--color-comp-2);

    @include screen-size-px(400) {
      display: none;
    }
  }
}

.svg {
  &-search {
    $size: 2.4rem;

    width: $size;
    height: $size;

    fill: var(--color-comp-10);
  }

  &-user {
    $size: 2.7rem;

    width: $size;
    height: $size;

    fill: var(--color-comp-4);
  }

  &-install {
    width: 4rem;
    height: 4rem;

    cursor: pointer;

    transition: $transition-default;

    fill: var(--color-base-1);

    &:hover {
      @include interact-svg-default;
    }

    &:active {
      @include interact-svg-default(active);
    }
  }
}

// Padding
.pd {
  &-h {
    &-1 {
      padding: 0 1rem;
    }
  }
}

// Margin
.mg {
  // Left 1rem
  &-l-1 {
    margin-left: 1rem;
  }

  // Right 1rem
  &-r-1 {
    margin-right: 1rem;
  }

  // Vertical
  &-v {
    &-1 {
      margin: 1rem 0;
    }

    &-2 {
      margin: 2rem 0;
    }
  }

  // Horizontal
  &-h {
    &-1 {
      margin: 0 1rem;
    }

    &-2 {
      margin: 0 2rem;
    }
  }

  &-t {
    &-8 {
      margin-top: 8rem;
    }

    &-n-7 {
      margin-top: -7rem;
    }
  }
}

// Width
.w {
  // 100%
  &-fill {
    width: 100%;
  }

  &-7 {
    width: 7rem;
  }

  &-26 {
    width: 26rem;
  }
}

// Height
.h {
  // 100%
  &-fill {
    height: 100%;
  }
}

.wh-fill {
  width: 100%;
  height: 100%;
}

.sort-by {
  width: 100%;
  padding: $shadow-resolve-padding;

  @include grid(
    $template-columns: min-content 1fr,
    $template-rows: min-content,
    $justify-content: start
  );

  & > * {
    grid-row: 1 / -1;
  }

  .svg-sort-by {
    width: 2.4rem;
    height: 2.4rem;

    fill: var(--color-comp-10);

    margin-right: 1rem;
  }
}

.virtualized-grid {
  & > * {
    display: flex;
    flex-wrap: wrap;
    // space between
    justify-content: space-between;
    align-content: space-between;
  }
}

.text-editable {
  @include grid($template-columns: max-content min-content);

  padding: 0 1rem;

  transition: $transition-default;

  &-active {
    box-shadow: $shadow-default;
    border-radius: 1.5rem;

    transform: translateY(-0.3rem);
  }

  .edit-icon-wrapper {
    width: 3rem;
    height: 3rem;

    padding: 0.5rem;

    border-radius: 50%;

    & svg {
      width: 100%;
      height: 100%;

      fill: var(--color-comp-6);

      transition: $transition-default;

      &:hover {
        @include interact-svg-default;
      }

      &:active {
        @include interact-svg-default(active);
      }
    }
  }

  & input {
    text-wrap: nowrap;

    border: none;
    outline: none;

    background-color: transparent;

    color: var(--color-comp-4);
    font-size: $font-size-default;
    font-weight: $font-weight-l4;
    line-height: $line-height-default;

    &:disabled {
      color: var(--color-comp-6);
    }
  }
}

.suspense-fallback {
  &-title {
    font-size: $font-size-l3;
    font-weight: $font-weight-l4;
    line-height: $line-height-l2;
    color: var(--color-base-1);

    padding: $shadow-resolve-padding;

    text-shadow: 0rem 0.5rem 1rem rgba(black, 0.2);
  }
}

.no-content-message {
  font-size: $font-size-default;
  font-weight: $font-weight-l4;
  line-height: $line-height-l2;
  color: var(--color-base-1);

  padding: $shadow-resolve-padding;

  text-shadow: 0rem 0.5rem 1rem rgba(black, 0.2);
}

.end {
  justify-self: end;
}

.time-picker {
  padding: 2rem;
  border-radius: 1rem;
  @include interact-default;

  @include grid($template-columns: 1fr, $others: start);

  background-color: var(--color-comp-16);

  grid-gap: 1rem;

  overflow: hidden;

  // @include screen-size-px(720) {
  //   width: calc(100vw - 2rem);
  // }

  &-event-name {
    width: 100%;

    @include screen-size-px(720) {
      width: 25rem;
    }
  }

  &-actions {
    width: 100%;

    @include layout-row-space-between;
  }

  &-heading {
    width: 100%;
    @include layout-row-space-between;

    &-title {
      font-size: $font-size-l3;
      font-weight: $font-weight-l4;
      line-height: $line-height-l2;
      color: var(--color-comp-4);
    }

    & svg {
      width: 2.4rem;
      height: 2.4rem;

      fill: var(--color-comp-4);
    }
  }

  &-time-slots {
    width: 100%;
    @include grid(
      $template-columns: repeat(2, max-content),
      $justify-content: space-between
    );

    grid-gap: 3rem;

    margin-top: 1rem;

    @include screen-size-px(720) {
      grid-template-columns: 1fr;
      justify-content: start;
      justify-items: start;
    }
  }

  &-time-slot {
    @include grid($template-columns: 1fr, $others: start);

    grid-gap: 0.5rem;

    border: 1px solid var(--color-comp-11);

    padding: $shadow-resolve-padding;

    border-radius: 1rem;

    &-date {
      font-size: $font-size-l5;
      font-weight: $font-weight-l4;
      line-height: $line-height-l2;
      color: var(--color-comp-4);
    }

    &-title {
      font-size: $font-size-default;
      font-weight: $font-weight-l4;
      line-height: $line-height-l2;
      color: var(--color-comp-4);

      background-color: var(--color-comp-16);

      padding: 0 1rem;

      margin-top: -2.5rem;

      margin-left: -1rem;
    }

    &-time {
      @include grid($template-columns: 10rem min-content 10rem);

      grid-gap: 0.5rem;

      &-separator {
        font-size: $font-size-l3;
        font-weight: $font-weight-l1;
        color: var(--color-comp-10);

        margin-bottom: -1.5rem;
      }
    }
  }
}

.date-time-picker {
  width: calc(100vw - 5rem);
  height: calc(100vh - 8rem);

  @include grid(
    $template-columns: 1fr,
    $template-rows: min-content 1fr min-content,
    $others: start
  );

  grid-gap: 1rem;

  padding: $shadow-resolve-padding;

  border-radius: 1rem;

  @include interact-default;

  background-color: var(--color-comp-16);

  @include screen-size-px(1024) {
    width: calc(100vw - 2rem);
    height: calc(100vh - 4rem);
  }

  &-title {
    font-size: $font-size-l3;
    font-weight: $font-weight-l4;
    line-height: $line-height-l2;
    color: var(--color-comp-4);
  }

  &-calendar {
    width: 100%;
    height: 100%;

    overflow-x: hidden;

    overflow-y: auto;
  }

  &-actions {
    width: 100%;

    @include layout-row-space-between;
  }
}

.input-drawing {
  width: 100%;
  // height: 100%;

  padding: $shadow-resolve-padding;

  @include grid(
    $template-columns: 1fr,
    $template-rows: 1fr min-content,
    $others: start
  );

  grid-gap: $shadow-resolve-padding;

  & > * {
    grid-column: 1 / -1;
  }

  &-canvas {
    width: 100%;

    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 1 / 1) {
      height: 20rem;
    }

    border: 1px solid var(--color-comp-13);

    border-radius: 1rem;

    transition: $transition-default;

    background-color: transparent;

    &:hover {
      @include interact-default;
    }
  }

  &-actions {
    width: 100%;

    @include layout-row-space-between;
  }
}

.default-container {
  padding: $shadow-resolve-padding;

  border-radius: 1rem;

  @include interact-default;

  background-color: var(--color-comp-16);
}

.btn-add-file {
  position: relative;

  cursor: pointer;

  & input {
    display: block;
    width: 100%;
    height: 100%;

    cursor: pointer;

    opacity: 0;

    z-index: 999;

    position: absolute;
    top: 0;
    left: 0;
  }

  @include layout-row;

  &-1 {
    padding: 0 1rem;

    border-radius: 0.5rem;

    color: var(--color-base-1);
    font-size: $font-size-default;
    font-weight: $font-weight-l3;
    line-height: $line-height-default;
    font-family: $font-family-1;

    transition: $transition-default;

    & svg {
      width: $font-size-default;
      height: $font-size-default;

      fill: var(--color-base-1);

      margin-right: 0.5rem;
    }

    &:hover {
      @include interact-default;
    }

    &:active {
      @include interact-default(active);
    }
  }
}

.editor-file-name {
  @include grid($template-columns: min-content 1fr min-content);

  transition: $transition-default;

  &-icon {
    transition: $transition-default;

    cursor: pointer;
  }

  &-enabled &-icon {
    &:hover {
      @include interact-svg-default;
    }

    &:active {
      @include interact-svg-default(active);
    }
  }

  &-1 {
    padding: 0.5rem 1rem;
    width: 100%;

    border-radius: 0.5rem;

    border: 1px solid var(--color-comp-11);

    &:hover {
      @include interact-default;
    }

    &:active {
      @include interact-default(active);
    }

    &-enabled .editor-file-name-icon {
      fill: var(--color-base-1);
    }

    &-disabled .editor-file-name-icon {
      fill: var(--color-base-11);
    }

    .editor-file-name-icon {
      width: 2.2rem;
      height: 2.2rem;
    }

    & input {
      width: 100%;
      margin: 0 1rem;

      padding: 0 0.5rem;

      border: none;
      outline: none;

      overflow: hidden;

      color: var(--color-comp-5);
      font-size: $font-size-default;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;

      &::placeholder {
        color: var(--color-comp-11);
      }

      &:disabled {
        color: var(--color-comp-11);
      }
    }
  }
}

.client-category {
  @include layout-col-start;

  grid-gap: $shadow-resolve-padding;

  &-heading {
    color: var(--color-comp-10);
    font-size: $font-size-l5;
    font-weight: $font-weight-l2;
    line-height: $line-height-default;
    font-family: $font-family-1;

    @include layout-col-start;

    &-name {
      color: var(--color-comp-4);
      font-size: $font-size-l3;
      font-weight: $font-weight-l2;
      line-height: $line-height-l2;
      font-family: $font-family-1;
    }
  }
}

.modal-services {
  padding: $shadow-resolve-padding;
}

.doc-container {
  width: 100%;
  height: 100%;

  & .ck-editor,
  & .ck-editor__editable {
    height: 100% !important;

    &__main {
      height: calc(100% - 4rem) !important;
    }
  }
}

.tox {
  z-index: 9999 !important;
}
