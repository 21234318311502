@use "../abstract" as *;

// Layout responsive workspace
.layout-res-workspace {
  @include layout-row-start;
}

.workspace {
  &-heading {
    $margin-left: -3.6rem;

    @include screen-size-l4 {
      // $margin-left: 1.2rem;
      $margin-left: 0;
    }

    width: 100%;

    margin-top: -2rem;

    .title {
      justify-self: start;
      margin-left: $margin-left;

      font-size: $font-size-l1;
      font-weight: $font-weight-l2;
      line-height: $line-height-l3;
      font-family: $font-family-1;

      margin-bottom: 2rem;

      @include screen-size-l4 {
        font-size: $font-size-l2;

        margin-top: 2rem;
      }
    }

    .greet {
      justify-self: start;
      margin-left: $margin-left;

      font-size: $font-size-default;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
    }

    .actions {
      width: 100%;

      @include grid($template-rows: min-content, $justify-content: end);
      grid-gap: 1rem;

      margin: 1rem 0;

      @include screen-size-l4 {
        justify-content: start;
      }

      @include screen-size-l2 {
        justify-content: start;
      }
    }
  }

  &-today {
    height: 34rem;

    width: 100%;

    align-self: end;

    @include screen-size-l4 {
      min-height: px2rem-l1(230px);

      margin-top: px2rem-l1(30px);
    }

    @include screen-size-l2 {
      min-height: px2rem-l1(230px);

      margin-top: px2rem-l1(30px);
    }

    &-card {
      width: 100%;

      height: 8.2rem;

      overflow: hidden;

      margin-bottom: 1rem;

      transition: $transition-default;

      padding: 0 0.5rem;

      border-radius: 0.5rem;

      // border: 1px solid var(--color-comp-12);

      &:hover {
        @include interact-default;
      }

      &:active {
        @include interact-default(active);
      }

      @include grid(
        $template-columns: min-content 1fr,
        $template-rows: min-content,
        $content: start,
        $justify-items: start
      );

      @include screen-size-l4 {
        margin-bottom: 0;

        width: px2rem-l1(120px);
        height: px2rem-l1(120px);

        padding: px2rem-l1(9px) px2rem-l1(12px);

        border-radius: px2rem-l1(8px);

        box-shadow: $shadow-1;

        @include grid($template-columns: 1fr, $justify-content: start);
      }

      @include screen-size-l2 {
        margin-bottom: 0;

        width: px2rem-l1(120px);
        height: px2rem-l1(120px);

        padding: px2rem-l1(9px) px2rem-l1(12px);

        border-radius: px2rem-l1(8px);

        box-shadow: $shadow-1;

        @include grid($template-columns: 1fr, $justify-content: start);
      }

      .preview {
        grid-column: 1 / 2;
        grid-row: 1 / -1;

        justify-self: start;

        align-self: start;

        @include screen-size-l4 {
          grid-column: 1 / -1;
          grid-row: auto;
        }

        @include screen-size-l2 {
          grid-column: 1 / -1;
          grid-row: auto;
        }

        display: block;
        width: 7.2rem;
        height: 7.2rem;
        margin: 0.5rem 1.6rem 0.5rem 0;

        & img {
          width: 100%;
          height: 100%;

          object-fit: cover;

          border-radius: 0.8rem;
        }
      }

      .info {
        grid-column: 2 / -1;
        grid-row: 1 / -1;

        @include screen-size-l4 {
          grid-column: 1 / -1;
          grid-row: auto;
        }

        @include screen-size-l2 {
          grid-column: 1 / -1;
          grid-row: auto;
        }

        width: 100%;
        height: 100%;

        @include grid(
          $template-columns: 1fr,
          $justify-content: start,
          $align-items: space-between
        );

        & > * {
          grid-column: 1 / -1;
        }

        font-size: $font-size-l5;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        margin-bottom: -1rem;

        .info-item {
          width: 100%;
          @include layout-row-start;

          margin-bottom: 0.7rem;

          .description {
            width: 100%;

            @include grid($template-columns: max-content 1fr);

            & p {
              display: inline-block;
              width: 100%;
              overflow: hidden;

              white-space: nowrap;
            }
          }

          & svg {
            width: 1.6rem;
            height: 1.6rem;

            fill: var(--color-comp-4);

            margin-right: 0.8rem;
          }
        }
      }
    }
  }

  &-in-progress {
    height: 52.5rem;

    @include screen-size-l4 {
      height: px2rem-l1(260px);
    }

    @include screen-size-l2 {
      height: px2rem-l1(260px);
    }

    align-self: end;

    // Cards Container
    // &-cc {
    //   width: 100%;
    //   height: 100%;

    //   padding: 1.8rem;
    //   padding-left: $shadow-resolve-padding;
    //   padding-right: $shadow-resolve-padding;

    //   @include grid(
    //     $template-columns: repeat(auto-fit, minmax(15.7rem, 1fr)),
    //     $justify-content: space-between
    //   );

    //   grid-gap: 1.6rem;

    //   @include screen-size-l2 {
    //     @include layout-row-start;

    //     grid-gap: px2rem-l1(6px);
    //   }
    // }

    &-card {
      width: 17rem;

      @include screen-size-l4 {
        width: px2rem-l1(120px);
      }

      @include screen-size-l2 {
        width: px2rem-l1(120px);
      }

      overflow: hidden;

      border-radius: 0.8rem;

      // box-shadow: $shadow-1;

      transition: $transition-default;

      border: 1px solid var(--color-comp-12);

      &:hover {
        @include interact-default;
      }

      &:active {
        @include interact-default(active);
      }

      grid-gap: 1rem;

      .preview {
        display: block;
        width: 100%;

        & img {
          width: 100%;
          height: 5.7rem;
          object-fit: cover;
        }
      }

      .info {
        width: 100%;
        height: 100%;

        padding: 0 1.6rem;

        @include layout-col-start;
        align-items: space-between;

        font-size: $font-size-l5;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        .info-item {
          width: 100%;
          @include layout-row-start;

          margin-bottom: 0.8rem;
        }
      }

      .actions {
        width: 100%;
        padding: 0 1.6rem;

        @include layout-row-space-between;

        margin-bottom: 1rem;

        .actions-item {
          width: 5.8rem;
          height: 2.2rem;

          border-radius: 0.4rem;

          margin: 0;

          // border: 0.1rem solid var(--color-base-1);

          transition: $transition-default;

          &:hover {
            @include interact-default;
          }

          &:active {
            @include interact-default(active);
          }

          & svg {
            width: 1.6rem;
            height: 1.6rem;

            // fill: var(--color-base-1);
          }
        }

        .btn {
          &-success {
            border: 0.1rem solid #4eca2f;

            & svg {
              fill: #4eca2f;
            }
          }

          &-danger {
            border: 0.1rem solid #ff0000;

            & svg {
              fill: #ff0000;
            }
          }
        }
      }
    }
  }

  &-sub-inspectors {
    height: 46.5rem;
    // Cards Container

    @include screen-size-l4 {
      height: px2rem-l1(350px);
    }

    @include screen-size-l2 {
      height: px2rem-l1(350px);
    }

    // &-cc {
    //   width: 100%;
    //   height: 100%;

    //   padding: 1.8rem;
    //   padding-left: $shadow-resolve-padding;
    //   padding-right: $shadow-resolve-padding;

    //   @include grid(
    //     $template-columns: repeat(auto-fit, minmax(29rem, 1fr)),
    //     $justify-content: space-between
    //   );

    //   grid-gap: 1.6rem;

    //   @include screen-size-l2 {
    //     @include layout-row-start;

    //     grid-gap: px2rem-l1(6px);
    //   }
    // }

    &-card {
      width: 100%;
      height: 26rem;

      padding: 1.6rem 2rem;

      border-radius: 1.2rem;

      box-shadow: $shadow-1;

      .preview {
        width: 6.7rem;
        height: 6.7rem;

        border-radius: 50%;

        margin-right: 1.2rem;

        overflow: hidden;

        & img {
          width: 100%;
          height: 100%;

          border-radius: 50%;

          object-fit: cover;
        }
      }

      .info {
        .info-item {
          font-size: $font-size-l7;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-3);

          &:first-child {
            font-size: $font-size-l5;
            color: var(--color-comp-1);
          }
        }
      }
    }
  }

  &-calendar {
    width: 100%;
    height: 46.5rem;

    @include screen-size-px(1024) {
      height: auto;
    }

    &-cc {
      width: 100%;

      height: 80rem;

      overflow-x: hidden;

      overflow-y: auto;

      @include screen-size-l4 {
        font-size: $font-size-l7;
      }

      @include screen-size-l2 {
        font-size: $font-size-l7;
      }

      padding-left: $shadow-resolve-padding;
      padding-right: $shadow-resolve-padding;

      @include screen-size-px(512) {
        height: 50rem;
        padding: 0;
      }
    }
  }

  &-content {
    width: 100%;

    padding: 0 5rem 0 7rem;

    @include screen-size-l4 {
      padding: 0 1.6rem;

      margin-top: 5rem;
      margin-bottom: 10rem;
    }

    @include screen-size-l2 {
      padding: 0 1.6rem;

      margin-top: 5rem;
      margin-bottom: 10rem;
    }

    @include layout-col-start;

    grid-gap: 4.6rem;

    &-top {
      width: 100%;

      @include grid(
        $template-columns: 1fr 1fr,
        $template-rows: 1fr,
        $content: start
      ) {
        @include screen-size-l4 {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }

        @include screen-size-l2 {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }

        @include screen-size-l1 {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }
      }

      & > * {
        grid-row: 1 / -1;

        @include screen-size-l4 {
          grid-row: auto;

          grid-column: 1 / -1;
        }

        @include screen-size-l2 {
          grid-row: auto;

          grid-column: 1 / -1;
        }

        @include screen-size-l1 {
          grid-row: auto;

          grid-column: 1 / -1;
        }
      }

      grid-gap: 4.8rem;

      &-left {
        width: 100%;
        height: 100%;

        @include layout-col-space-between;
      }
    }
  }
}
