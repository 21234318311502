@use "../abstract" as *;

.settings {
  &-container {
    width: 100%;

    padding: 0 3.5rem;

    @include screen-size-px(1256) {
      padding: 0 1rem;
    }

    @include layout-col-start;

    .settings-heading {
      width: 100%;

      @include layout-row-space-between;

      padding: 0 3.5rem;

      .titles {
        align-self: start;

        .main-title {
          color: var(--color-comp-2);
          font-size: $font-size-l1;
          font-weight: $font-weight-l1;
          line-height: $line-height-default;
          font-family: $font-family-default;

          margin-bottom: 0.8rem;
        }

        .main-subtitle {
          color: var(--color-comp-6);
          font-size: $font-size-l4;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-default;
        }
      }
    }

    .filters {
      width: 100%;

      padding: 0 3.5rem;

      @include grid(
        $template-columns: 33rem,
        $template-rows: min-content,
        $justify-content: end
      );
    }

    .main-navigation {
      width: 100%;
      padding: 2rem 3.5rem;

      @include layout-col;

      transition: $transition-default;

      @include screen-size-px(1256) {
        padding: 1.6rem 1rem;
      }

      .main-category {
        color: var(--color-comp-4);
        font-size: $font-size-l2;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-default;

        user-select: none;
      }
    }
  }

  &-standard {
    width: 100%;

    @include layout-col-start;

    grid-gap: 1.6rem;

    &-actions {
      width: 100%;

      @include layout-row-space-between;
    }

    &-url {
      width: 100%;
    }

    &-context {
      width: 100%;

      aspect-ratio: 1 / 1;

      @supports not (aspect-ratio: 1 / 1) {
        height: 40rem;
      }
    }
  }

  &-signatures {
    width: 100%;

    @include grid($template-columns: 1fr, $others: start);
    grid-gap: $shadow-resolve-padding;

    &-info {
      width: 100%;

      @include grid($template-columns: 1fr, $others: start);

      color: var(--color-comp-10);
      font-size: $font-size-default;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;
    }

    &-content {
      width: 100%;

      @include grid($template-columns: repeat(2, 1fr), $others: start);

      grid-gap: 3rem;

      @include screen-size-px(1024) {
        grid-template-columns: 1fr;
      }

      &-sub {
        width: 100%;
        height: 100%;

        @include grid(
          $template-columns: 1fr,
          $template-rows: min-content 1fr,
          $others: start
        );

        grid-gap: $shadow-resolve-padding;

        @include screen-size-px(1024) {
          grid-row: 1 / 2;
        }
      }
    }

    &-current {
      width: 100%;
      height: 100%;

      @include grid(
        $template-columns: 1fr,
        $template-rows: min-content 1fr,
        $others: start
      );

      grid-gap: $shadow-resolve-padding;

      background-color: var(--color-comp-16);

      border-radius: 1rem;

      padding: $shadow-resolve-padding;

      & picture {
        justify-self: center;

        width: 100%;
        // height: 100%;

        overflow: hidden;

        @include layout-col-start;

        & img {
          width: 100%;

          object-fit: contain;

          vertical-align: middle;
        }
      }
    }

    &-text-container {
      width: 100%;

      @include grid($template-columns: 1fr min-content);

      grid-gap: $shadow-resolve-padding;
    }

    &-title {
      width: 100%;

      color: var(--color-comp-5);
      font-size: $font-size-default;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;

      border-bottom: 1px solid var(--color-comp-10);
    }

    &-drawing {
      width: 100%;

      overflow: hidden;

      padding: $shadow-resolve-padding;

      border-radius: 1rem;

      background-color: var(--color-comp-16);

      &-context {
        width: 100%;
        aspect-ratio: 16 / 9;

        @supports not (aspect-ratio: 1 / 1) {
          height: 20rem;
        }
      }
    }

    &-actions {
      width: 100%;

      @include grid(
        $template-columns: repeat(2, max-content),
        $justify-content: space-between
      );

      grid-gap: 1.6rem;

      @include screen-size-px(512) {
        grid-template-columns: 1fr;
      }
    }
  }

  &-services {
    &-preview {
      width: 100%;

      padding: $shadow-resolve-padding;

      @include layout-col-start;

      @include screen-size-px(512) {
        padding: $shadow-resolve-padding 0;
      }

      &-items {
        width: 100%;
        height: 38rem;

        margin-top: 1rem;
        margin-bottom: 4rem;
      }

      &-actions {
        &-top {
          width: 100%;
          justify-self: end;

          // @include grid(
          //   $template-rows: min-content,
          //   $justify-content: space-between
          // );
          // grid-gap: 1.2rem;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          & > * {
            // grid-row: 1 / -1;

            margin-left: 1.6rem;

            margin-bottom: 1.6rem;

            width: 14rem;
          }
        }

        &-bottom {
          width: 100%;

          // // & .btn {
          // //   width: 15.5rem;
          // // }

          // @include layout-row-space-between;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          & > * {
            // grid-row: 1 / -1;

            margin-left: 1.6rem;

            margin-bottom: 1.6rem;
          }
        }
      }

      .sub-actions {
        @include layout-row-space-between;

        grid-gap: 1.2rem;
      }
    }

    &-card {
      width: 23rem;

      padding: $shadow-resolve-padding;

      min-height: 30rem;

      @include interact-default;

      background-color: var(--color-comp-16);

      border-radius: 1rem;

      transition: $transition-default;

      // font-size: $font-size-default;
      // color: var(--color-comp-8);
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;

      @include grid($template-rows: 1fr repeat(3, min-content), $others: start);
      grid-gap: 2rem;

      @include screen-size-px(512) {
        width: 20rem;
      }

      &-not-selected {
        border: 2px solid var(--color-comp-16);
      }

      &-selected {
        border: 2px solid var(--color-base-1);
      }

      .card-content {
        &-main {
          @include grid($template-columns: 1fr, $others: start);
          font-size: $font-size-default;
          color: var(--color-comp-2);
        }

        &-sub {
          @include grid($template-columns: 1fr, $others: start);

          .services {
            &-title {
              font-size: $font-size-l5;
              color: var(--color-comp-2);
            }

            &-subtitle {
              font-size: $font-size-l6;
              color: var(--color-comp-10);
            }
          }
        }
      }
    }

    &-add-edit {
      width: 100%;

      background-color: var(--color-comp-16);

      border-radius: 1rem;

      @include interact-default;

      padding: $shadow-resolve-padding;

      margin-top: 1.2rem;

      @include layout-col-start;

      &-heading {
        width: 100%;

        @include grid(max-content 1fr);

        margin-bottom: 1.6rem;

        @include screen-size-px(720) {
          grid-template-columns: 1fr;
        }

        // @include screen-size-px(512) {
        // }

        .services {
          &-title {
            justify-self: start;
            color: var(--color-comp-2);
            font-size: $font-size-l4;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
          }

          &-shared {
            justify-self: end;
            @include grid(1fr min-content);

            grid-gap: 1rem;

            color: var(--color-comp-6);
            font-size: $font-size-l5;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;

            @include screen-size-px(512) {
              margin-top: 2rem;
            }
          }
        }
      }

      &-items {
        width: 100%;

        padding: $shadow-resolve-padding;

        // @include layout-col-start;

        @include grid($template-columns: repeat(2, 1fr), $others: start);

        grid-gap: 2rem;

        @include screen-size-px(1024) {
          padding: 0;
        }

        @include screen-size-px(720) {
          grid-template-columns: 1fr;
        }

        &-actions {
          justify-self: end;
        }

        .add-edit-items {
          &-container {
            width: 100%;

            @include grid($template-columns: repeat(2, 1fr), $others: start);

            grid-gap: 2rem;

            &-1 {
              width: 100%;

              @include grid($template-columns: 1fr, $others: start);

              & > * {
                grid-column: 1 / -1;
              }

              grid-gap: $shadow-resolve-padding;
            }
          }

          &-cost-duration {
            justify-self: start;

            // width: calc(50% - 1rem);

            width: 100%;

            @include grid(
              $template-columns: 15rem 10rem,
              $template-rows: min-content,
              $others: start
            );

            grid-gap: 1rem;

            & > * {
              grid-row: 1 / -1;
            }
          }

          &-address {
            width: 100%;

            grid-column: 1 / -1;

            @include grid(
              $template-columns: 1fr 20rem,
              $template-rows: min-content
            );

            grid-gap: 2rem;

            @include screen-size-px(720) {
              grid-template-columns: 1fr;
              grid-template-rows: auto;
            }

            &-actions {
              justify-self: end;

              &-btn {
                transition: $transition-default;

                &:hover {
                  @include interact-default;
                }

                &:active {
                  @include interact-default(active);
                }
              }
            }
          }

          &-tax {
            width: 100%;

            grid-column: 1 / -1;

            @include grid($template-columns: 1fr, $others: start);

            &-title {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;
              line-height: $line-height-default;
              font-family: $font-family-1;
              color: var(--color-comp-8);
            }

            &-contents {
              width: 100%;

              @include grid(
                $template-columns: 1fr 20rem,
                $template-rows: min-content,
                $others: start
              );

              grid-gap: 2rem;

              @include screen-size-px(720) {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
              }
            }
          }
        }
      }

      &-actions {
        width: 100%;

        margin-top: 2rem;

        padding: $shadow-resolve-padding;

        // @include layout-row-space-between;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
          margin-left: 1.6rem;
          margin-bottom: 1.6rem;

          width: 21rem;

          @include screen-size-px(720) {
            margin-left: 0;
            width: 100%;
          }
        }

        @include screen-size-px(1024) {
          padding: 0;
        }
      }
    }

    &-conditions {
      width: 100%;

      border-radius: 1rem;

      padding: 0 $shadow-resolve-padding;

      @include screen-size-px(1024) {
        padding: 0;
      }

      &-heading {
        width: 100%;

        @include grid($template-columns: 1fr min-content, $others: start);

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;
        }
      }

      &-actions {
        justify-self: end;
        padding-top: $shadow-resolve-padding;
      }

      &-title {
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-8);
      }

      &-subtitle {
        font-size: $font-size-l6;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-10);

        padding: 0 $shadow-resolve-padding;
      }

      &-content {
        width: 100%;

        margin-top: 2rem;

        padding: 0 $shadow-resolve-padding;

        @include grid($template-columns: 1fr, $others: start);

        grid-gap: 3rem;

        @include screen-size-px(1024) {
          padding: 0;

          grid-gap: 1.6rem;

          padding: 0;
        }
      }

      &-category {
        width: 100%;

        // padding: $shadow-resolve-padding;

        border-radius: 1rem;

        border: 1px solid var(--color-comp-11);

        @include grid($template-columns: 1fr, $others: start);

        &-divider {
          justify-self: center;
          width: calc(100% - 1rem);

          height: 0;

          border-bottom: 1px solid var(--color-comp-14);

          margin: 0.4rem 0;
        }

        &-title {
          margin-top: -1.6rem;
          margin-left: 0.8rem;

          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-8);

          & > * {
            padding: 0 1rem;

            background-color: var(--color-comp-16);
          }

          @include grid($template-columns: max-content, $others: start);
        }

        &-content {
          width: 100%;

          padding: $shadow-resolve-padding;

          @include grid($template-columns: 1fr, $others: start);

          // grid-gap: $shadow-resolve-padding;

          @include screen-size-px(1024) {
            padding: 1rem;
          }

          @include screen-size-px(512) {
            padding: 1rem 0.2rem;
          }
        }

        &-card {
          width: 100%;

          border-radius: 1rem;

          transition: $transition-default;

          padding: 0 0.5rem;

          font-size: $font-size-l6;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          // cursor: pointer;

          &:hover {
            @include interact-default;
          }

          &:active {
            @include interact-default(active);
          }

          @include grid(
            // $template-columns: 1fr repeat(3, min-content),
            $template-rows: min-content,
            $justify-content: space-between
          );

          grid-gap: 1rem;

          & > * {
            grid-row: 1 / -1;
          }

          @include screen-size-px(512) {
            grid-template-columns: 1fr min-content;
            grid-template-rows: auto;
            grid-gap: 0;

            // padding-top: 1rem;
            // padding-bottom: 1rem;
          }

          &-arrow {
            padding: 0 2rem;

            font-size: $font-size-l2;
            font-weight: $font-weight-l1;
            line-height: 0;
            color: var(--color-comp-8);

            @include layout-col;

            @include screen-size-px(512) {
              display: none;
            }
          }

          &-action {
            padding: 0.5rem;

            border-radius: 50%;

            // @include screen-size-px(512) {
            //   display: none;
            // }

            & svg {
              width: 2rem;
              height: 2rem;

              fill: var(--color-base-1);

              transition: $transition-default;

              &:hover {
                @include interact-svg-default;
              }

              &:active {
                @include interact-svg-default(active);
              }
            }
          }

          &-addition {
            width: 100%;
            @include grid(
              $template-columns: repeat(2, 10rem),
              $justify-content: space-between
            );

            grid-gap: 1rem;

            @include screen-size-px(720) {
              grid-template-columns: repeat(2, max-content);
            }

            @include screen-size-px(512) {
              grid-row: 2 / 3;
            }
          }

          &-condition {
            width: 100%;

            .condition-range {
              width: 100%;
              @include grid(
                $template-columns: 10rem min-content 10rem,
                $others: start
              );

              grid-gap: 1rem;

              @include screen-size-px(720) {
                grid-template-columns: repeat(3, max-content);
              }

              @include screen-size-px(512) {
                justify-content: space-between;
              }

              &-max {
                justify-self: end;
              }
            }
          }
        }
      }
    }

    &-trash {
      width: 100%;

      border-radius: 1rem;

      padding: $shadow-resolve-padding;

      // @include interact-default;

      // background-color: var(--color-comp-16);

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 2rem;

      @include screen-size-px(512) {
        padding: 0;
      }

      &-heading {
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: 0;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        margin-top: 1rem;
      }

      &-actions {
        width: 100%;

        // @include layout-row-space-between;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        & > * {
          margin-right: 1.6rem;
          margin-bottom: 1.6rem;
        }
      }
    }

    &-interact-condition {
      width: 100%;

      border-radius: 1rem;

      padding: $shadow-resolve-padding;

      @include interact-default;

      background-color: var(--color-comp-16);

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 2rem;

      .interact-condition {
        &-heading {
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: 0;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          margin-top: 1rem;
        }

        &-actions {
          width: 100%;

          @include layout-row-space-between;
        }

        &-selectors {
          width: 100%;

          @include grid($template-columns: 1fr 0.5fr 0.5fr);
          grid-gap: 1rem;

          @include screen-size-px(720) {
            grid-template-columns: 1fr;
          }
        }

        &-entries {
          width: 100%;

          @include grid(
            $template-columns: repeat(2, 1fr) min-content repeat(2, 1fr)
          );
          grid-gap: 3rem;

          @include screen-size-px(1024) {
            grid-template-columns: repeat(4, 1fr);

            grid-gap: 1.6rem;
          }

          @include screen-size-px(720) {
            grid-template-columns: repeat(2, 1fr);
          }

          @include screen-size-px(512) {
            grid-template-columns: 1fr;
          }

          &-addition {
            width: 100%;

            @include grid($template-columns: 12rem 12rem);

            grid-gap: 1rem;
          }

          &-arrow {
            padding: 0 2rem;

            font-size: $font-size-l2;
            font-weight: $font-weight-l1;
            line-height: 0;
            color: var(--color-comp-8);

            @include layout-col;

            @include screen-size-px(1024) {
              display: none;
            }
          }
        }
      }
    }
  }

  &-agreements {
    &-preview {
      width: 100%;

      @include grid($template-columns: 1fr, $others: start);

      background-color: var(--color-comp-16);

      border-radius: 1rem;

      padding: $shadow-resolve-padding;

      @include interact-default;

      font-size: $font-size-default;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-2);

      grid-gap: 2rem;

      &,
      & > * {
        padding: $shadow-resolve-padding;
      }

      &-actions {
        width: 100%;

        @include grid(
          $template-columns: repeat(2, max-content),
          $justify-content: space-between
        );

        grid-gap: 1.6rem;

        @include screen-size-px(512) {
          grid-template-columns: 1fr;
        }

        &-bottom {
          width: 100%;

          @include grid(
            $template-columns: 1fr max-content,
            $justify-content: space-between
          );

          grid-gap: 2rem;

          @include screen-size-px(512) {
            grid-template-columns: 1fr;
            grid-gap: 1.6rem;
          }
        }
      }

      &-items {
        width: 100%;

        height: 20rem;

        padding: $shadow-resolve-padding;

        // .grid-virtualized {
        //   padding-left: $shadow-resolve-padding;
        // }

        @include screen-size-px(512) {
          padding: 0;
        }
      }

      &-card {
        width: 25.5rem;
        height: 11.5rem;

        padding: $shadow-resolve-padding;

        border-radius: 1.5rem;

        background-color: var(--color-comp-16);

        border: 3px solid var(--color-comp-16);

        overflow: hidden;

        @include interact-default;

        @include layout-col-start;

        grid-gap: 1rem;

        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-10);

        @include screen-size-px(512) {
          width: 20rem;
        }

        & svg {
          width: 3.4rem;
          height: 3.4rem;

          fill: var(--color-comp-10);
          stroke: var(--color-comp-10);
        }

        &-name {
          width: 100%;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &-container {
          @include layout-col;

          padding: $shadow-resolve-padding;
        }

        &-selected {
          border-color: var(--color-base-1);
        }
      }
    }

    &-add-edit {
      width: 100%;

      padding: $shadow-resolve-padding;

      background-color: var(--color-comp-16);

      border-radius: 1.5rem;

      @include interact-default;

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 1rem;

      & > * {
        padding: 0 $shadow-resolve-padding;
      }

      .agreement-editor {
        width: 100%;

        @include grid(
          $template-columns: 1fr max-content,
          $template-rows: 1fr,
          $others: start
        );

        grid-gap: 1.6rem;

        @include screen-size-px(1256) {
          grid-template-columns: 1fr;
        }

        &-placeholders {
          width: 100%;

          @include grid(
            $template-columns: repeat(2, max-content),
            $others: start
          );

          grid-gap: 1.6rem;

          @include screen-size-px(1256) {
            grid-template-columns: 1fr;
          }

          &-buttons {
            width: 100%;

            @include grid(
              $template-columns: repeat(auto-fit, 19rem),
              // $justify-content: space-between,
              $others: start
            );

            grid-gap: 1.6rem;

            & .btn {
              font-size: 1.2rem;
            }
          }

          &-group {
            width: 100%;

            @include grid($template-columns: 1fr, $others: start);

            & > * {
              grid-column: 1 / -1;
            }

            grid-gap: 1rem;
          }
        }
      }

      &-doc {
        width: 100%;

        height: 100%;

        overflow: hidden;

        .ck-editor__editable_inline:not(.ck-comment__input *) {
          height: 30rem;
        }

        &-title {
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);
        }

        &-content {
          width: 100%;

          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 1 / 1) {
            height: 40rem;
          }

          @include screen-size-px(720) {
            aspect-ratio: 1 / 1;
          }

          @include screen-size-px(512) {
            aspect-ratio: 1 / 2;
          }
        }
      }

      &-p-select {
        width: 100%;

        @include grid($template-columns: 1fr min-content);
        grid-gap: $shadow-resolve-padding;
      }

      &-info {
        width: 100%;

        & > * {
          max-width: 55ch;

          text-align: justify;

          font-size: $font-size-l5;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-8);
        }
      }

      &-actions {
        width: 100%;

        margin-top: 2rem;

        @include grid(
          $template-columns: repeat(3, max-content),
          $justify-content: space-between
        );

        grid-gap: 1.6rem;

        @include screen-size-px(512) {
          grid-template-columns: 1fr;
        }

        // &-sub {
        //   @include layout-row;
        //   grid-gap: 1rem;
        // }
      }
    }

    &-instructions {
      width: 100%;
      padding: $shadow-resolve-padding;

      background-color: var(--color-comp-16);

      @include interact-default;

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 2rem;

      border-radius: 1.5rem;

      overflow: hidden;

      &-title {
        font-size: $font-size-l3;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);
      }

      &-doc {
        width: 100%;

        overflow: hidden;

        // @include grid($template-columns: 1fr, $others: start);

        // & > * {
        //   grid-column: 1 / -1;
        // }

        aspect-ratio: 1 / 1;

        @supports not (aspect-ratio: 1 / 1) {
          height: 20rem;
        }

        @include screen-size-px(512) {
          aspect-ratio: 1 / 2;
        }
      }
    }
  }

  &-discount {
    &-preview {
      width: 100%;

      @include grid($template-columns: 1fr, $others: start);

      background-color: var(--color-comp-16);

      border-radius: 1rem;

      padding: $shadow-resolve-padding;

      @include interact-default;

      font-size: $font-size-default;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-2);

      grid-gap: 2rem;

      &,
      & > * {
        padding: $shadow-resolve-padding;

        @include screen-size-px(512) {
          padding: 0.5rem;
        }
      }

      &-actions {
        width: 100%;

        @include grid(
          $template-columns: repeat(2, max-content),
          $justify-content: space-between
        );

        grid-gap: 1.6rem;

        @include screen-size-px(512) {
          grid-template-columns: 1fr;
        }
      }

      &-items {
        width: 100%;

        height: 25rem;

        padding: $shadow-resolve-padding;
      }

      &-card {
        width: 25.5rem;
        height: 14rem;

        padding: $shadow-resolve-padding;

        border-radius: 1.5rem;

        background-color: var(--color-comp-16);

        border: 3px solid var(--color-comp-16);

        overflow: hidden;

        @include interact-default;

        @include layout-col-start;

        grid-gap: 1rem;

        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-10);

        @include screen-size-px(512) {
          width: 20rem;
        }

        & svg {
          width: 3.4rem;
          height: 3.4rem;

          fill: var(--color-comp-10);
          stroke: var(--color-comp-10);
        }

        &-container {
          @include layout-col;

          padding: $shadow-resolve-padding;
        }

        &-selected {
          border-color: var(--color-base-1);
        }
      }
    }

    &-add-edit {
      width: 100%;

      padding: $shadow-resolve-padding;

      background-color: var(--color-comp-16);

      border-radius: 1.5rem;

      @include interact-default;

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 1rem;

      & > * {
        padding: 0 $shadow-resolve-padding;
      }

      &-doc {
        width: 100%;

        overflow: hidden;

        .ck-editor__editable_inline:not(.ck-comment__input *) {
          height: 30rem;
        }

        &-title {
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);
        }
      }

      &-p-select {
        width: 100%;

        @include grid($template-columns: 1fr min-content);
        grid-gap: $shadow-resolve-padding;
      }

      &-info {
        width: 100%;

        & > * {
          width: 55ch;

          text-align: justify;

          font-size: $font-size-l5;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-8);
        }
      }

      &-actions {
        width: 100%;

        margin-top: 2rem;

        @include layout-row-space-between;

        &-sub {
          @include layout-row;

          grid-gap: 1rem;
        }
      }
    }
  }

  &-financial {
    &-payment {
      width: 100%;

      padding: $shadow-resolve-padding;

      @include grid($template-columns: repeat(2, 1fr), $align: start);

      grid-gap: 2rem;

      @include screen-size-px(1024) {
        grid-template-columns: 1fr;

        padding: 0;
      }

      &-account {
        width: 100%;

        @include grid($template-columns: 1fr, $others: start);

        grid-gap: 1rem;

        & > * {
          grid-column: 1 / -1;
        }

        @include screen-size-px(1024) {
          justify-items: center;
        }

        .payment-account {
          &-title {
            justify-self: start;

            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-2);
          }

          &-states {
            padding: $shadow-resolve-padding;
            border-radius: 1rem;

            border: 1px solid var(--color-comp-11);

            transition: $transition-default;

            &:hover {
              @include interact-default;
            }
          }

          &-state {
            @include layout-row-start;
            grid-gap: 1rem;

            color: var(--color-comp-9);
            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-default;

            svg {
              width: 1.6rem;
              height: 1.6rem;

              opacity: 0.5;
            }

            &-enabled {
              svg {
                fill: green;
              }

              .state-icon {
                color: darken(green, 15%);
              }
            }

            &-disabled {
              svg {
                fill: red;
              }

              .state-icon {
                color: darken(red, 15%);
              }
            }
          }
        }
      }

      &-settings {
        width: 100%;

        @include grid($template-columns: 1fr, $others: start);

        grid-gap: 3rem;

        & > * {
          grid-column: 1 / -1;
        }

        .payment-settings {
          &-sweep {
            @include layout-row-start;

            grid-gap: 1rem;

            padding: 0 $shadow-resolve-padding;

            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-2);
          }

          &-info {
            padding: 0 $shadow-resolve-padding;

            color: var(--color-comp-10);
            font-size: $font-size-l5;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
          }

          &-actions {
            justify-self: end;
          }
        }

        .payment-input-text {
          width: 100%;
          padding: 0 $shadow-resolve-padding;

          @include grid($template-columns: 1fr, $others: start);

          & > * {
            grid-column: 1 / -1;
          }

          &-titles {
            grid-row: 1 / 2;

            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-2);
          }

          & input {
            grid-row: 2 / 3;

            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;

            padding: 0rem $shadow-resolve-padding;

            border-bottom: 1px solid var(--color-comp-10);

            color: var(--color-comp-5);
            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;

            &::placeholder {
              color: var(--color-comp-10);
            }
          }
        }
      }
    }
  }

  &-input {
    &-text {
      width: 100%;

      position: relative;

      height: 5rem;

      cursor: pointer;

      padding: 0.5rem 1.6rem;

      transition: $transition-default;

      border-radius: 0.8rem;

      background-color: var(--color-comp-13);

      @include grid(
        $template-columns: 1fr,
        $others: start,
        $align-content: space-between
      );

      &-titles {
        grid-row: 1 / 2;

        color: var(--color-comp-2);
        font-size: $font-size-l7;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      & input {
        grid-row: 2 / 3;

        cursor: pointer;

        padding: 0 0.8rem;

        width: 100%;

        background-color: transparent;

        border: none;

        outline: none;

        color: var(--color-comp-7);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        &::placeholder {
          color: var(--color-comp-17);
          font-size: $font-size-l6;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }

        // &:focus {
        //   outline: none;
        // }
      }

      &-active {
        outline: none;

        &:hover {
          // @include interact-default;
          box-shadow: $shadow-default;
        }

        &:active {
          // @include interact-default(active);
          box-shadow: none;
        }
      }
    }

    &-text-2 {
      width: 100%;

      position: relative;

      height: 5rem;

      cursor: pointer;

      padding: 0.5rem 1.6rem;

      transition: $transition-default;

      border-radius: 0.8rem;

      background-color: var(--color-comp-16);

      @include grid(
        $template-columns: 1fr,
        $others: start,
        $align-content: space-between
      );

      &-titles {
        grid-row: 1 / 2;

        color: var(--color-comp-2);
        font-size: $font-size-l7;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      & input {
        grid-row: 2 / 3;

        cursor: pointer;

        padding: 0 0.8rem;

        width: 100%;

        background-color: transparent;

        border: none;

        outline: none;

        color: var(--color-comp-7);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        &::placeholder {
          color: var(--color-comp-17);
          font-size: $font-size-l6;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }

        // &:focus {
        //   outline: none;
        // }
      }

      &-active {
        outline: none;

        &:hover {
          @include interact-default;
        }

        &:active {
          @include interact-default(active);
        }
      }
    }

    &-select {
      width: 100%;

      position: relative;

      cursor: pointer;

      height: 5rem;

      padding: 0.5rem 1.6rem;

      transition: $transition-default;

      border-radius: 0.8rem;

      background-color: var(--color-comp-13);

      @include grid($template-columns: 1fr, $others: start);

      &-base {
        width: 100%;
        padding-left: 0.5rem;
      }

      &-ind {
        position: absolute;
        top: 1rem;
        right: 1rem;

        width: 1rem;
        height: 1rem;

        stroke: var(--color-comp-10);

        z-index: 0;
      }

      .react-select-menu {
        grid-template-columns: 1fr;
      }

      .react-select-input {
        padding: 0;
        margin: 0;

        // visibility: hidden;

        overflow: hidden;
      }

      .react-select-container {
        // display: block ;

        // position: relative;

        cursor: pointer;

        border: none;
        outline: none;

        box-shadow: none; // required for removing border

        height: auto;
        min-height: auto;

        background-color: transparent;

        padding: 0;
        margin: 0;
      }

      .react-select-control {
        // display: block ;

        // position: relative;

        cursor: pointer;

        border: none;
        outline: none;

        box-shadow: none;

        height: auto;
        min-height: auto;

        background-color: transparent;

        padding: 0;
        margin: 0;
      }

      .react-select-menu-list {
        width: 100%;
      }

      .react-select-option {
        width: 100%;
      }

      .react-select-value-container {
        padding: 0;
        margin: 0;
      }

      // .react-select-container {
      //   width: 100%;

      //   padding: 0;

      //   cursor: pointer;

      //   border: none;
      //   outline: none;
      // }

      .react-select-menu-portal {
        z-index: 9999 !important;
      }

      .react-select-indicators-container {
        display: none;
      }

      .react-select-placeholder {
        color: var(--color-comp-17);
        font-size: $font-size-l6;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      .react-select-input,
      .react-select-input-single-value,
      .react-select-input-multi-value {
        color: var(--color-comp-7);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      &-active {
        outline: none;

        // z-index: 2000;

        &:hover {
          box-shadow: $shadow-default;
        }

        &:active {
          box-shadow: none;
        }
      }

      &-titles {
        grid-row: 1 / 2;

        color: var(--color-comp-2);
        font-size: $font-size-l7;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }
    }

    &-radios {
      width: 100%;

      padding: 0 $shadow-resolve-padding;

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 0.5rem;

      &-title {
        font-size: $font-size-default;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);
      }

      &-items {
        width: 100%;

        border: none;
        outline: none;

        padding: 0 $shadow-resolve-padding;

        color: var(--color-comp-10);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        display: flex;
        flex-wrap: wrap;

        grid-gap: $shadow-resolve-padding;
      }

      &-item {
        & input {
          display: none;
        }

        padding: 0.5rem 1rem;

        border: 1px solid var(--color-comp-10);

        border-radius: 0.4rem;

        transition: $transition-default;

        user-select: none;

        &:hover {
          @include interact-default;
        }

        &:active {
          @include interact-default(active);
        }

        &-selected {
          color: var(--color-base-2);
          border: 1px solid var(--color-base-1);
        }
      }
    }
  }

  &-button-1 {
    background-color: var(--color-base-9);

    border-radius: 0.8rem;

    border: 1px solid var(--color-base-4);

    color: var(--color-base-1);
    font-size: $font-size-default;
    font-weight: $font-weight-l2;
    line-height: $line-height-l2;

    padding: 1rem;

    & .icon-processing {
      @include layout-row;

      margin-left: 1rem;
    }

    & svg {
      width: 1.6rem;
      height: 1.6rem;

      fill: var(--color-base-1);

      margin-right: 0.8rem;
    }
  }

  &-business-info {
    width: 100%;

    padding: $shadow-resolve-padding;

    @include grid($template-columns: repeat(2, 1fr), $others: start);

    grid-gap: 2rem;

    @include screen-size-px(1024) {
      grid-template-columns: 1fr;
    }

    &-main-actions {
      width: 100%;

      grid-column: 1/-1;

      @include layout-row-space-between;

      margin-top: -5rem;

      @include screen-size-px(512) {
        margin-top: 0;
      }
    }

    &-details {
      width: 100%;

      &-company {
        @include grid(
          $template-columns: 1fr,
          // $template-rows: 1fr,
          $others: start
        );

        grid-gap: 2rem;
      }

      &-profile {
        @include grid($template-columns: 1fr);

        grid-gap: 2rem;
      }

      &-picture {
        justify-self: center;
        width: 100%;

        aspect-ratio: 16 / 9;

        @supports not (aspect-ratio: 1 / 1) {
          width: 20rem;
        }

        display: flex;
        flex-direction: row;
        justify-content: center;

        & > article {
          border-radius: 50%;

          height: 100%;
          aspect-ratio: 1 / 1;
          @supports not (aspect-ratio: 1 / 1) {
            width: 20rem;
          }
        }

        & picture {
          border-radius: 50%;
        }
      }

      &-logo {
        width: 100%;

        & img {
          object-fit: contain;
        }
      }

      &-data {
        width: 100%;

        @include layout-col-start;

        border: none;

        grid-gap: $shadow-resolve-padding;

        font-size: $font-size-l4;
      }
    }

    &-address {
      width: 100%;

      @include layout-col-start;

      grid-gap: $shadow-resolve-padding;

      &-actions {
        width: 100%;

        @include layout-row-space-between;
      }

      &-description {
        width: 100%;

        overflow: hidden;

        padding: $shadow-resolve-padding;
        padding-top: 0;

        @include layout-row-start;

        color: var(--color-comp-8);
        font-size: $font-size-default;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-default;

        text-shadow: $shadow-default;

        & svg {
          width: 3.5rem;
          height: 3.5rem;

          fill: var(--color-comp-10);

          @include interact-svg-default;
        }
      }

      &-map {
        width: 100%;

        aspect-ratio: 16 / 9;

        border-radius: 0.8rem;

        overflow: hidden;

        @supports not (aspect-ratio: 1 / 1) {
          height: 30rem;
        }

        transition: $transition-default;

        &:hover {
          @include interact-default;
        }
      }
    }
  }
}

.input-date-picker-1 {
  width: 100%;

  @include layout-row-space-between;

  & svg {
    width: 1rem;
    height: 1rem;

    fill: var(--color-comp-4);
  }
}

.modal-discount {
  width: 80rem;
  max-height: 80vh;

  border-radius: 1rem;

  background-color: var(--color-comp-16);

  padding: $shadow-resolve-padding;

  @include interact-default;

  @include grid($template-columns: 1fr, $others: start);

  grid-gap: 1rem;

  @include screen-size-px(1024) {
    width: calc(100vw - 2rem);
  }

  &-fields {
    width: 100%;

    @include grid($template-columns: repeat(2, 1fr), $others: start);

    grid-gap: 2rem;

    grid-gap: 2rem;

    @include screen-size-px(720) {
      grid-template-columns: 1fr;
    }

    &-sub {
      width: 100%;

      @include grid($template-columns: repeat(2, 1fr), $others: start);

      grid-gap: 1rem;

      @include screen-size-px(512) {
        grid-template-columns: 1fr;

        grid-gap: 2rem;
      }
    }
  }
}

.sub-settings-container {
  width: 100%;

  padding: 0 3.5rem 3.5rem 3.5rem;

  border-radius: 1.5rem;

  margin: 3.5rem;

  // background-color: var(--color-comp-15);

  box-shadow: $shadow-1;

  @include screen-size-px(1256) {
    padding: 1rem;

    margin: 0;
  }

  @include screen-size-px(1024) {
    padding: 0.5rem;
  }

  .sub-heading {
    width: 100%;

    @include layout-col;
  }

  .sub-settings-content {
    width: 100%;

    min-height: 20rem;

    padding: 1.6rem;

    border: 1px solid var(--color-comp-11);

    border-radius: 1.5rem;

    @include layout-col-start;

    @include screen-size-px(1024) {
      padding: 0.5rem;
    }

    .sub-category {
      color: var(--color-base-1);
      font-size: $font-size-l3;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-default;

      user-select: none;
    }
  }
}

.visible {
  animation: visibility 0.5s ease-in-out;
}

.visible-delayed {
  animation: visibility 1s ease-in-out;
}

.move-down {
  animation: move-down 0.5s ease-in-out;
}

@keyframes visibility {
  0% {
    opacity: 0;
    // transform: translateY(-5rem);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;

    // transform: translateY(0);
  }
}

@keyframes move-down {
  0% {
    opacity: 0;
    transform: translateY(-5rem);
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;

    transform: translateY(0);
  }
}
