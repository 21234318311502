@use "../functions" as *;

@mixin screen-size-l1 {
  @media only screen and (max-width: px2em(360px)) {
    @content;
  }
}

@mixin screen-size-l2 {
  @media only screen and (max-width: px2em(480px)) {
    @content;
  }
}

@mixin screen-size-l3 {
  @media only screen and (max-width: px2em(720px)) {
    @content;
  }
}

@mixin screen-size-l4 {
  @media only screen and (max-width: px2em(860px)) {
    @content;
  }
}

@mixin screen-size-l5 {
  @media only screen and (max-width: px2em(1100px)) {
    @content;
  }
}

@mixin screen-size-px($px) {
  @media only screen and (max-width: px2em($px * 1px)) {
    @content;
  }
}

@mixin root-font-size-query {
  font-size: 62.5%; // 10px/16px = 62.5% -> 1rem = 10px

  $sizes: (1400, 1512, 1768, 2024, 2280, 2536, 2792, 3048, 3304, 3560, 3816);

  // @include screen-size-l4 {
  //   font-size: $screen-font-percent-l1;
  // }

  // @include screen-size-l2 {
  //   font-size: $screen-font-percent-l1;
  // }

  // @include screen-size-l1 {
  //   font-size: $screen-font-percent-l1;
  // }

  @each $size in $sizes {
    @media only screen and (min-width: px2em($size * 1px)) {
      font-size: px2percent($size);
    }
  }

  @media only screen and (min-width: px2em(4064px)) {
    font-size: 0.75vw;
  }
}
