@use "../abstract" as *;

.client-view {
  &-modals {
    &-payment {
      min-width: 40rem;

      color: var(--color-comp-5);

      @include layout-col-start;

      grid-gap: $shadow-resolve-padding;

      @include screen-size-px(512) {
        width: calc(100vw - 2rem);
      }

      &-actions {
        width: 100%;

        @include layout-row-space-between;
      }

      &-titlebar {
        width: 100%;

        @include layout-row-space-between;

        border-bottom: 1px solid var(--color-comp-12);

        color: var(--color-base-1);
        font-size: $font-size-l2;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;

        & svg {
          width: 4rem;
          height: 4rem;

          fill: var(--color-base-1);
        }
      }

      &-info {
        width: 100%;

        padding: $shadow-resolve-padding;

        background-color: var(--color-comp-14);

        border-radius: 0.8rem;

        transition: $transition-default;

        border: 1px solid var(--color-comp-11);

        color: var(--color-comp-9);
        // font-size: $font-size-l2;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;

        &:hover {
          @include interact-default;
        }

        @include layout-col-start;

        &-price {
          color: var(--color-comp-5);
          font-size: $font-size-l2;
        }
      }
    }

    &-agreement {
      min-width: 90vw;
      height: 90vh;

      color: var(--color-comp-5);

      @include grid(
        $template-columns: 1fr,
        $template-rows: min-content 1fr repeat(2, min-content)
      );

      grid-gap: $shadow-resolve-padding;

      @include screen-size-px(1024) {
        width: calc(100vw - 2rem);
        height: calc(100vh - 4rem);
      }

      &-actions {
        width: 100%;

        @include layout-row-space-between;

        padding-top: $shadow-resolve-padding;

        border-top: 1px solid var(--color-comp-12);
      }

      &-titlebar {
        width: 100%;

        @include layout-row-space-between;

        border-bottom: 1px solid var(--color-comp-12);

        color: var(--color-base-1);
        font-size: $font-size-default;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;

        & svg {
          width: 3rem;
          height: 3rem;

          fill: var(--color-base-1);
        }
      }

      &-context {
        width: 100%;
        height: 100%;

        overflow: hidden;
      }

      &-confirm {
        @include layout-row-start;

        grid-gap: 1rem;
      }
    }
  }

  &-report-view {
    &-aside {
      margin-top: 2rem;
      @include grid($template-columns: 1fr min-content, $others: start);

      &-divider {
        width: 0;
        height: 100%;

        border-right: 1px solid var(--color-comp-11);
      }
    }

    &-sidebar {
      width: 20vw;

      padding: $shadow-resolve-padding;

      @include layout-col-start;

      grid-gap: $shadow-resolve-padding;

      @include screen-size-px(1256) {
        display: none;
      }

      &-logo {
        width: 100%;

        & img {
          width: 100%;
          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 1 / 1) {
            height: 17rem;
          }

          // background-color: var(--color-comp-11);

          object-fit: contain;

          border-radius: 0.8rem;

          vertical-align: middle;

          transition: $transition-default;

          &:hover {
            @include interact-default;
          }
        }
      }

      &-inspectors {
        width: 100%;

        @include grid(
          $template-columns: repeat(auto-fit, minmax(12rem, 20rem)),
          $justify-content: space-between
        );

        grid-gap: $shadow-resolve-padding;

        &-item {
          width: 100%;

          overflow: hidden;

          padding: $shadow-resolve-padding;

          border-radius: 0.8rem;

          transition: $transition-default;

          &:hover {
            @include interact-default;
          }

          @include layout-col-start;

          grid-gap: $shadow-resolve-padding;

          color: var(--color-comp-5);
          font-size: $font-size-default;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;

          &-preview {
            width: 100%;

            & img {
              width: 100%;
              aspect-ratio: 1 / 1;

              @supports not (aspect-ratio: 1 / 1) {
                height: 12rem;
              }

              object-fit: cover;

              border-radius: 0.8rem;

              vertical-align: middle;

              transition: $transition-default;

              &:hover {
                @include interact-default;
              }
            }
          }
        }
      }

      &-property-logo {
        width: 100%;
        aspect-ratio: 1 / 1;

        @supports not (aspect-ratio: 1 / 1) {
          height: 17rem;
        }

        fill: var(--color-comp-12);
      }
    }
  }

  &-report-visualizer {
    width: 100%;

    @include layout-col-start;

    padding-right: $shadow-resolve-padding;

    @include screen-size-px(1256) {
      padding-left: $shadow-resolve-padding;
    }
  }

  &-report-cover {
    display: block;
    width: 100%;
    height: 30vh;

    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;

      vertical-align: middle;

      object-fit: cover;
    }
  }

  &-hierarchy {
    width: 100%;

    padding: 0 $shadow-resolve-padding;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-items: center;
    flex-wrap: wrap;

    transition: $transition-default;

    &-instruction {
      color: var(--color-comp-10);
      font-size: $font-size-l3;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;

      padding: 0.5rem;
    }

    &-selected {
      @include layout-row-start;

      transition: $transition-default;

      .main-title {
        color: var(--color-base-1);
        font-size: $font-size-l3;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;

        transition: $transition-default;
      }

      cursor: pointer;

      padding: 0.5rem;
      border-radius: 0.5rem;

      & svg {
        width: 2rem;
        height: 2rem;

        fill: var(--color-base-1);

        margin-right: 0.5rem;
      }

      transition: $transition-default;

      &:hover {
        .main-title {
          text-shadow: 0 0.5rem 1rem rgba(black, 0.6);
        }
      }

      &:active {
        .main-title {
          text-shadow: none;
        }
      }
    }

    &-nav-icon {
      width: 2rem;
      height: 2rem;

      margin: 0 0.5rem;

      fill: var(--color-comp-11);
    }
  }

  &-selector {
    width: 100%;

    @include grid(
      $template-columns: min-content 1fr min-content,
      $template-rows: min-content
    );

    .navigator {
      width: 3.6rem;
      height: 3.6rem;

      padding: 0.8rem;

      border-radius: 50%;

      @include layout-row;

      & svg {
        width: 100%;
        height: 100%;

        fill: var(--color-comp-8);

        transition: $transition-default;

        &:hover {
          @include interact-svg-default;
        }

        &:active {
          @include interact-svg-default(active);
        }
      }
    }

    .items {
      width: 100%;

      height: 8rem;

      padding: 0 1.6rem;

      @include layout-row-start;

      overflow: visible;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .item {
        @include grid($template-columns: max-content, $align-content: start);

        padding: 0 1.6rem;

        transition: $transition-default;

        user-select: none;

        &:hover {
          .title {
            color: var(--color-base-1);

            text-shadow: 0 0.5rem 1rem rgba(black, 0.6);
          }
        }

        .title {
          color: var(--color-comp-9);
          font-size: $font-size-default;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-default;

          transition: $transition-default;

          padding-top: 0.8rem;
        }

        .indicator {
          width: 100%;
          height: 0.8rem;

          border-radius: 0.4rem;

          background-color: var(--color-comp-11);

          transition: $transition-default;
        }

        .nob {
          width: 1.2rem;
          height: 1.2rem;

          border-radius: 50%;

          background-color: var(--color-comp-11);

          outline: 0.2rem solid var(--color-comp-13);

          transition: $transition-default;
        }

        &-selected {
          .indicator {
            animation: focused-indicator 0.3s ease-in-out forwards;

            background-color: var(--color-base-1);
          }

          .nob {
            animation: focused-nob 0.3s ease-in-out forwards;
          }

          .title {
            // animation: focused-title 0.3s ease-in-out forwards;

            color: var(--color-base-1);

            visibility: hidden;
          }
        }
      }

      // &-not-selected {
      //   color: var(--color-comp-2);
      // }
    }
  }

  &-report-record {
    width: 100%;

    @include grid($template-columns: 1fr, $others: start);

    grid-gap: $shadow-resolve-padding;

    &-heading {
      @include layout-row-start;

      grid-gap: $shadow-resolve-padding;

      &-title {
        color: var(--color-base-1);
        font-size: $font-size-l4;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;

        transform: translateY(-5rem);

        opacity: 0;

        animation: move-down-cr 0.5s linear forwards;
      }

      &-answer {
        color: var(--color-comp-10);
        font-size: $font-size-default;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;

        opacity: 0;

        // animation delay is 0.3s
        animation: fade-out-cr 0.3s linear forwards 0.7s;
      }
    }

    &-content {
      width: 100%;

      opacity: 0;

      animation: fade-out-cr 0.3s linear forwards 1.3s;

      @include layout-col-start;

      grid-gap: $shadow-resolve-padding;

      &-description {
        width: 100%;
        font-size: $font-size-default;
      }

      &-images {
        width: 100%;

        padding: $shadow-resolve-padding;

        overflow: hidden;

        @include grid(
          $template-columns: repeat(auto-fit, minmax(34rem, 1fr)),
          $justify-content: space-between
        );

        grid-gap: $shadow-resolve-padding;

        &-item {
          width: 100%;

          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 1 / 1) {
            height: 19rem;
          }

          & img {
            width: 100%;
            height: 100%;

            object-fit: cover;

            border-radius: 0.8rem;

            vertical-align: middle;

            transition: $transition-default;

            overflow: hidden;

            &:hover {
              @include interact-default;
            }
          }
        }
      }

      &-videos {
        width: 100%;

        overflow: hidden;

        padding: $shadow-resolve-padding;

        // aspect-ratio: 16 / 9;

        // @supports not (aspect-ratio: 16 / 9) {
        //   height: 38rem;
        // }

        @include grid($template-columns: max-content 1fr max-content);

        &-navigator {
          & svg {
            width: 10rem;
            height: 10rem;

            fill: rgba(purple, 0.2);

            transition: $transition-default;

            &:hover {
              @include interact-svg-default;
            }

            &:active {
              @include interact-svg-default(active);
            }
          }
        }

        &-slides {
          width: 100%;

          // height: 100%;

          overflow: hidden;

          .general-slider {
            width: 100%;
            overflow: hidden;

            aspect-ratio: 16 / 10;

            @supports not (aspect-ratio: 16/9) {
              height: 35rem;
            }
          }
        }

        &-item {
          width: 100%;

          overflow: hidden;

          aspect-ratio: 16 / 10;

          @supports not (aspect-ratio: 16/9) {
            height: 35rem;
          }

          padding: $shadow-resolve-padding;

          & video {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &-report-interactions {
    width: 100%;

    padding-right: $shadow-resolve-padding;

    // @include layout-row-space-between;

    // grid-gap: $shadow-resolve-padding;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    @include screen-size-px(1256) {
      padding-left: $shadow-resolve-padding;
    }

    & .btn {
      @include layout-row-space-between;

      background-color: var(--color-comp-13);

      color: var(--color-comp-5);

      border: 1px solid var(--color-comp-5);

      font-weight: $font-weight-l4;

      & svg {
        fill: var(--color-comp-5);
      }
    }

    & .react-select-control {
      background-color: var(--color-comp-13);

      border: 1px solid var(--color-comp-5);

      transition: $transition-default;

      cursor: pointer;

      font-size: $font-size-default;

      &:hover {
        @include interact-default;
      }

      & .react-select-placeholder {
        color: var(--color-comp-5);
        font-weight: $font-weight-l4;
      }
    }

    &-sub {
      // @include layout-row;

      // grid-gap: $shadow-resolve-padding;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      & > * {
        width: 22.5rem;

        margin-right: 1.6rem;

        margin-bottom: 1.6rem;
      }
    }
  }

  &-report-main {
    width: 100%;

    padding-left: $shadow-resolve-padding;

    @include layout-col-start;

    grid-gap: 2rem;

    @include screen-size-px(1256) {
      padding-left: 0;
    }
  }

  &-issues-count {
    // width: 29rem;
    // min-height: 21rem;

    overflow: hidden;

    border-radius: 3.75rem;

    overflow: hidden;

    padding: 1rem;

    @include layout-col-start;

    transition: $transition-default;

    &:hover {
      @include interact-default;
    }

    &-value {
      min-width: 6rem;
      height: 4.5rem;

      font-size: 2rem;
      font-weight: 700;
      font-family: $font-family-1;

      border-radius: 3.75rem;

      background-color: white;

      margin-right: 1rem;

      @include layout-row;
    }

    &-title {
      font-size: 2.2rem;
      font-weight: 500;

      font-family: $font-family-1;

      color: white;

      white-space: nowrap;
      overflow: hidden;
    }

    &-description {
      margin-top: 1rem;

      font-size: 1.6rem;
      font-weight: 400;

      line-height: 2;

      text-align: center;

      color: white;
    }
  }
}

@keyframes move-down-cr {
  0% {
    transform: translateY(-5rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-out-cr {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
