@use "./mediaQueries" as *;

@mixin cards-variables {
  --workspace-in-progress-card-dir: vertical;
  --workspace-in-progress-card-width: 157;
  --workspace-in-progress-card-height: 170;

  @include screen-size-l4 {
    --workspace-in-progress-card-dir: horizontal;
    --workspace-in-progress-card-width: 120;
    --workspace-in-progress-card-height: 140;
  }

  @include screen-size-l2 {
    --workspace-in-progress-card-dir: horizontal;
    --workspace-in-progress-card-width: 120;
    --workspace-in-progress-card-height: 140;
  }

  //

  --workspace-today-card-dir: vertical;
  --workspace-today-card-width: 0; // 0 -> 100%
  --workspace-today-card-height: 82;

  @include screen-size-l4 {
    --workspace-today-card-dir: horizontal;
    --workspace-today-card-width: 120;
    --workspace-today-card-height: 120;
  }

  @include screen-size-l2 {
    --workspace-today-card-dir: horizontal;
    --workspace-today-card-width: 120;
    --workspace-today-card-height: 120;
  }

  --workspace-sub-inspectors-card-dir: vertical;
  --workspace-sub-inspectors-card-width: 290;
  --workspace-sub-inspectors-card-height: 260;

  @include screen-size-l4 {
    --workspace-sub-inspectors-card-dir: horizontal;
    --workspace-sub-inspectors-card-width: 159;
    --workspace-sub-inspectors-card-height: 222;
  }

  @include screen-size-l2 {
    --workspace-sub-inspectors-card-dir: horizontal;
    --workspace-sub-inspectors-card-width: 159;
    --workspace-sub-inspectors-card-height: 222;
  }
}
