@use "../../abstract" as *;

.address {
  &-modal {
    width: 80rem;
    height: 75vh;

    border-radius: 1.5rem;

    background-color: var(--color-comp-16);

    overflow: hidden;

    padding: $shadow-resolve-padding;

    & > * {
      padding: $shadow-resolve-padding;
    }

    @include interact-default;

    @include grid(
      $template-columns: 1fr,
      $template-rows: min-content 1fr min-content,
      $others: start
    );

    @include screen-size-px(1024) {
      width: calc(100vw - 2rem);
      height: calc(100vh - 4rem);

      padding: 1rem;
    }

    &-street-view-captured {
      width: 100%;
      aspect-ratio: 16/9;

      @supports not (aspect-ratio: 16/9) {
        height: 30rem;
      }

      border-radius: 1rem;

      overflow: hidden;

      img {
        width: 100%;
        height: 100%;

        vertical-align: middle;

        object-fit: contain;
      }
    }

    &-content {
      width: 100%;
      height: 100%;

      margin-top: -$shadow-resolve-padding;

      overflow-x: hidden;
      overflow-y: auto;

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: $shadow-resolve-padding;
    }

    &-location-picker {
      width: 100%;
      height: 30rem;

      overflow: hidden;
    }

    &-actions {
      width: 100%;

      @include layout-row-space-between;
    }

    &-form {
      width: 100%;

      @include layout-col-start;

      grid-gap: $shadow-resolve-padding;

      &-row {
        width: 100%;

        @include grid($template-columns: repeat(2, 1fr));

        grid-gap: 2rem;

        & > * {
          width: 100%;
        }

        @include screen-size-px(1024) {
          @include grid($template-columns: 1fr);
        }

        &-fill {
          width: 100%;
        }
      }
    }

    &-location {
      width: 100%;

      height: 5rem;

      padding: 0.5rem 1.6rem;

      transition: $transition-default;

      border-radius: 0.8rem;

      border: 1px solid var(--color-comp-13);

      @include grid($template-columns: 1fr, $others: start);

      &-title {
        color: var(--color-comp-2);
        font-size: $font-size-l7;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      &-coordinates {
        width: 100%;

        padding: 0 0.5rem;

        color: var(--color-comp-7);
        font-size: $font-size-l5;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        @include grid($template-columns: repeat(2, 1fr), $others: start);
      }
    }
  }
}
