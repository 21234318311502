@use "../variables" as *;

@mixin interact-text-default($type: hover) {
  color: var(--color-comp-2);
  font-weight: $font-weight-l1;
  line-height: $line-height-l2;

  @if $type == action {
    text-shadow: none;
    transform: translateY(0);
  } @else {
    text-shadow: $shadow-default;
    transform: translateY(-0.2rem);
  }
}

@mixin text-default() {
  color: var(--color-comp-2);
  font-size: $font-size-default;
  font-weight: $font-weight-default;
  line-height: $line-height-default;
}
