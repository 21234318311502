@use "../../abstract" as *;

.navbar {
  $navbar-padding-sides: 3.6rem;
  $navbar-padding-top: 1.6rem;
  $navbar-height: #{3.8rem + 2 * $navbar-padding-top};

  width: 100%;
  height: $navbar-height;

  z-index: 999;

  margin-bottom: 3rem;

  @include screen-size-px(512) {
    margin-bottom: 4.5rem;
  }

  .navbar-sign-in-btn {
    transition: $transition-default;

    padding: 0.6rem 1rem;

    border-radius: 0.5rem;

    background-color: var(--color-comp-4);

    color: white;
    font-size: $font-size-default;
    font-weight: $font-weight-l4;
    line-height: $line-height-l2;
    font-family: $font-family-1;

    &:hover {
      @include interact-default;
    }

    &:active {
      @include interact-default(active);
    }
  }

  &-container {
    width: 100%;

    z-index: 1000;

    position: fixed;
    top: 0;
    left: 0;

    padding: $navbar-padding-top $navbar-padding-sides;

    background: linear-gradient(
      180deg,
      rgba(white, 1) 0%,
      rgba(white, 1) 70%,
      rgba(white, 0) 100%
    );

    @include layout-col;

    @include screen-size-l2 {
      padding: $navbar-padding-top 1.6rem;
    }
  }

  &-content {
    width: 100%;
    // height: $navbar-height;

    padding-bottom: 2rem;

    @include layout-row-space-between;

    & a {
      text-decoration: none;
      color: inherit;
    }

    .logo {
      // position: fixed;
      // top: $navbar-padding-top;
      // left: $navbar-padding-sides;

      color: var(--color-comp-2);
      font-size: $font-size-l3;
      font-weight: $font-weight-l1;
      line-height: $line-height-l2;
    }

    .links {
      color: var(--color-comp-4);
      font-size: $font-size-l4;
      font-weight: $font-weight-l4;
      line-height: $line-height-l2;

      display: block;

      @include screen-size-px(1024) {
        display: none;
      }

      & > a {
        display: inline-block;

        padding: 0rem 1rem;

        transition: $transition-default;

        &:hover {
          @include interact-text-default;
        }

        &:active {
          @include interact-text-default(action);
        }
      }

      .active {
        @include interact-text-default;
      }
    }

    .selectors {
      @include layout-row;
      grid-gap: 1rem;

      .search {
        width: 19rem;

        transition: $transition-default;

        @include grid($template-columns: 1fr, $justify-content: end);

        @include screen-size-l2 {
          display: none;
        }

        @include screen-size-l1 {
          display: none;
        }
      }
    }
  }

  .separated-search {
    transition: $transition-default;

    padding: 2rem 0;

    @include grid($template-columns: 1fr, $justify-content: start);

    & > * {
      justify-self: start;
    }

    display: none;

    @include screen-size-l2 {
      display: grid;
      width: 100%;
    }

    @include screen-size-l1 {
      display: grid;
      width: 100%;
    }
  }

  .account-user-picture {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;

    border: none;

    outline: none;

    transition: $transition-default;

    &:hover {
      @include interact-default;
    }

    &:active {
      @include interact-default(active);
    }

    fill: var(--color-comp-10);

    & img {
      width: 100%;
      height: 100%;
      border-radius: 50%;

      object-fit: cover;

      vertical-align: middle;
    }
  }

  &-account-modal {
    width: 21.5rem;
    // height: 24.5rem;
    height: 16rem;

    border-radius: 1.2rem;

    box-shadow: $shadow-default;

    background-color: var(--color-comp-16);

    padding-bottom: 3rem;

    overflow: hidden;

    @include grid(
      $template-columns: 1fr,
      $template-rows: min-content 1fr,
      $align-content: start
    );

    .user {
      &-bg {
        width: 100%;
        height: 7.5rem;

        background-color: var(--color-base-7);
      }

      &-contents {
        width: 100%;
        height: 100%;

        padding: 0 2rem;

        @include layout-col-space-between;
      }

      &-identity {
        width: 100%;

        margin-top: -2.3rem;

        @include layout-row-start;

        &-picture {
          width: 4.6rem;
          height: 4.6rem;
          border-radius: 50%;

          border: 0.2rem solid var(--color-comp-16);

          & img {
            width: 100%;
            height: 100%;

            border-radius: 50%;

            object-fit: cover;

            vertical-align: middle;
          }
        }

        &-name {
          color: var(--color-comp-2);
          font-size: $font-size-l6;
          font-weight: $font-weight-l4;
          line-height: $line-height-l2;
          font-family: $font-family-1;

          align-self: end;
        }
      }

      &-interacts {
        width: 100%;

        padding: 0.2rem;

        color: var(--color-comp-2);
        font-size: $font-size-l6;
        font-weight: $font-weight-l4;
        line-height: $line-height-l2;
        font-family: $font-family-1;

        @include layout-col;

        &-credit {
          width: 100%;

          @include layout-row-space-between;

          .credit-amount {
            color: var(--color-base-1);
          }
        }

        &-nav-link {
          justify-self: start;

          margin-top: 1.2rem;

          transition: $transition-default;

          color: var(--color-comp-2);

          cursor: pointer;

          text-decoration: none;

          border: none;

          background-color: var(--color-comp-16);

          &:hover {
            @include interact-text-default;
          }

          &:active {
            @include interact-text-default(action);
          }
        }
      }
    }
  }
}

//
