@use "sass:math";

@use "../abstract" as *;

.templates {
  &-new {
    padding: 0 4.2rem;
    grid-gap: 3rem;

    @include screen-size-px(1256) {
      padding: 0 5vw;
    }

    &-heading {
      width: 100%;

      @include grid($template-columns: repeat(3, 1fr));

      @include screen-size-px(1024) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include screen-size-px(720) {
        grid-template-columns: 1fr;
      }

      &-actions {
        justify-self: end;

        // grid-column: 3 / 4;
        // grid-row: 2 / 3;

        @include layout-row;

        grid-gap: 3rem;

        @include screen-size-px(1024) {
          // justify-self: start;
          grid-column: 1 / -1;
          // grid-row: 3 / 4;

          margin-top: 2rem;
        }

        @include screen-size-px(720) {
          justify-self: start;
        }
      }

      .title {
        grid-column: 1 / -1;
        grid-row: 1 / 2;

        justify-self: start;

        color: var(--color-comp-2);
        font-size: $font-size-l1;
        font-weight: $font-weight-l2;
      }

      .name {
        // grid-column: 1 / 2;
        // grid-row: 2 / 3;

        justify-self: start;
      }

      .action-share {
        // grid-column: 2 / 3;
        // grid-row: 2 / 3;

        justify-self: center;

        & * {
          color: var(--color-comp-4);
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
        }

        & input {
          margin-right: 1rem;
        }

        @include screen-size-px(1024) {
          justify-self: end;
        }

        @include screen-size-px(720) {
          justify-self: start;

          margin-top: 2rem;
        }
      }

      .action-create {
        & * {
          color: var(--color-comp-4);
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
        }

        width: 15.5rem;
        height: 3.2rem;

        border-radius: 0.8rem;

        border: 1px solid var(--color-comp-10);
      }
    }

    &-content {
      width: 100%;

      @include grid(
        $template-columns: 1fr 1fr 1.65fr,
        $template-rows: 1fr,
        $align: start
      );

      grid-gap: 3rem;

      @include screen-size-px(1256) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
      }

      @include screen-size-px(860) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;

        margin-bottom: 10rem;
      }
    }

    &-sections {
      align-self: start;

      width: 100%;

      .sections-content {
        width: 100%;
        @include grid(
          $template-columns: 1fr,
          $template-rows: min-content min-content 20rem min-content 15rem
            min-content 15rem max-content
        );

        grid-gap: 2rem;

        & > * {
          width: 100%;

          // padding: 0 $shadow-resolve-padding;
        }

        & > .scrolling-area {
          height: 100%;

          margin-top: -3rem;

          // padding: $shadow-resolve-padding 0;
        }

        .item-action {
          margin-top: -2rem;
        }

        .zone-services-title {
          margin-top: -2rem;
        }
      }

      .sub-section-title {
        width: 100%;
        height: 3.2rem;

        color: var(--color-comp-2);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        margin-top: -1rem;

        @include layout-row-space-between;

        & span {
          width: 3rem;
          height: 3rem;

          padding: 0.5rem;

          border-radius: 50%;

          background-color: transparent;

          @include layout-col;

          &:hover {
            & svg {
              fill: var(--color-base-1);

              @include interact-svg-default;
            }
          }

          &:active {
            & svg {
              fill: var(--color-base-1);

              @include interact-svg-default(active);
            }
          }

          & svg {
            width: 100%;
            height: 100%;

            transition: $transition-default;

            fill: var(--color-base-1);
          }
        }
      }
    }

    &-items {
      width: 100%;
      height: 100%;

      @include grid(
        $template-columns: 1fr,
        $template-rows: 1fr min-content,
        $align-content: start
      );

      grid-gap: 1.6rem;

      & > * {
        width: 100%;
      }

      .sub-section-items {
        height: 100%;

        @include layout-col-start;

        .divider-horizontal {
          width: 100%;
          margin-bottom: 1rem;
        }

        .items-content {
          width: 100%;
          height: 38rem;

          @include grid(
            $template-columns: 1fr,
            $template-rows: 1fr min-content,
            $align-content: start
          );

          grid-gap: 2rem;

          & > * {
            width: 100%;
            // padding: 0 $shadow-resolve-padding;
          }

          .scrolling-area {
            width: 100%;
            height: 100%;

            // padding-left: $shadow-resolve-padding;

            .grid-virtualized {
              padding: $shadow-resolve-padding 0;
            }
          }

          // .templates-new-card-title {
          //   width: 120px;
          // }
        }
      }

      .sub-section-attachments {
        height: 100%;

        .attachments-content {
          height: 100%;
          padding: 0 $shadow-resolve-padding;

          overflow: hidden;

          @include grid($template-columns: 1fr, $align: start);

          & > * {
            grid-column: 1 / -1;
          }

          .btn {
            background-color: transparent;
            color: var(--color-base-1);
            font-weight: $font-weight-l4;

            & svg {
              fill: var(--color-base-1);
            }
          }
        }
      }
    }

    &-observations {
      width: 100%;
      height: 100%;

      .divider-horizontal {
        width: 100%;
        margin-bottom: 1rem;
      }

      .observations-content {
        width: 100%;
        min-height: 100%;

        & > * {
          width: 100%;

          padding: 0 $shadow-resolve-padding;
        }

        .observations-scrollable {
          width: 100%;
          height: 20rem;

          // margin-bottom: 1.5rem;

          padding: $shadow-resolve-padding 0;
        }

        .observations-add {
          //   $size: 23rem + 2 * $shadow-resolve-padding;
          width: 100%;

          margin-top: 2rem;

          // @include layout-row;
        }

        // .templates-new-card-title {
        //   width: 300px;
        // }
      }
    }

    &-attachments {
      &-divider {
        width: 100%;

        border-bottom: 1px solid var(--color-comp-12);
      }

      &-card {
        width: 100%;

        @include grid($template-columns: min-content 1fr min-content);

        color: var(--color-comp-10);
        font-size: $font-size-l5;
        font-weight: $font-weight-l2;
        line-height: $line-height-l1;
        font-family: $font-family-1;

        & svg {
          width: 1.2rem;
          height: 1.2rem;

          margin-right: 0.5rem;

          fill: var(--color-comp-10);

          cursor: pointer;

          transition: $transition-default;

          &:hover {
            @include interact-svg-default;
            fill: var(--color-base-1);
          }

          &:active {
            @include interact-svg-default(active);
            fill: var(--color-base-1);
          }
        }

        &-name {
          justify-self: start;

          cursor: pointer;

          transition: $transition-default;

          &:hover {
            color: var(--color-base-1);
          }
        }
      }

      &-groups {
        width: 100%;

        height: 18rem;

        overflow-x: hidden;
        overflow-y: auto;

        @include grid($template-columns: 1fr, $others: start);

        & > * {
          grid-column: 1 / -1;
        }

        grid-gap: 1rem;
      }

      &-group {
        width: 100%;

        @include grid($template-columns: 1fr, $others: start);

        & > * {
          grid-column: 1 / -1;
        }

        &-heading {
          @include grid($template-columns: min-content 1fr);

          color: var(--color-base-1);
          font-size: $font-size-l3;
          font-weight: $font-weight-l3;
          line-height: $line-height-l1;
          font-family: $font-family-1;

          & svg {
            width: 1.6rem;
            height: 1.6rem;

            fill: var(--color-base-1);

            margin-right: 1rem;
          }
        }

        &-list {
          width: 100%;

          padding: 0 $shadow-resolve-padding;

          @include grid($template-columns: 1fr, $others: start);

          & > * {
            grid-column: 1 / -1;
          }
        }
      }
    }

    &-col-observations {
      width: 100%;
      height: 100%;

      @include grid(
        $template-columns: 1fr,
        $template-rows: 1fr min-content,
        $align: start
      );

      grid-gap: 1.6rem;

      & > * {
        width: 100%;
      }

      @include screen-size-px(1256) {
        grid-column: 1 / -1;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: max-content;
      }

      @include screen-size-px(860) {
        grid-column: 1 / -1;

        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }

    &-cover {
      width: 100%;

      &-select {
        width: 100%;
        // height: 10rem;

        position: relative;

        overflow: hidden;

        @include layout-col-start;

        & picture {
          grid-row: 1 / 2;

          img {
            height: 12rem;
            aspect-ratio: 16 / 9;

            z-index: 10;
          }
        }

        & input {
          width: 100%;
          height: 100%;

          position: absolute;
          left: 0;
          top: 0;

          z-index: 1000;

          opacity: 0;

          cursor: pointer;

          background-color: red;

          &:hover + svg {
            opacity: 1;
          }
        }

        & svg {
          width: 10rem;
          height: 10rem;

          fill: var(--color-comp-16);

          transition: $transition-default;

          opacity: 0;

          z-index: 20;

          grid-row: 1 / 2;
        }
      }

      &-content {
        width: 100%;
        padding: $shadow-resolve-padding;

        aspect-ratio: 16 / 9;

        @supports not (aspect-ratio: 16 / 9) {
          height: 22rem;
        }

        overflow: hidden;

        @include grid($template-columns: min-content, $template-rows: 1fr);
      }

      &-actions {
        width: 100%;
        height: 4.2rem;

        @include layout-row-space-between;
      }
    }

    &-delete-modal {
      padding: 2.5rem;

      background-color: var(--color-comp-16);

      border-radius: 1rem;

      @include interact-default;

      @include grid($template-columns: 1fr, $others: start);

      grid-gap: 1rem;

      & > * {
        grid-column: 1 / -1;
      }

      &-content {
        @include grid($template-columns: repeat(2, max-content));
        grid-gap: 2rem;

        & svg {
          width: 7rem;
          height: 7rem;

          fill: var(--color-base-1);
        }

        .note-title {
          color: var(--color-comp-2);
          font-size: $font-size-l3;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }

        .note-description {
          color: var(--color-comp-5);
          font-size: $font-size-l5;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }
      }

      &-divider {
        justify-self: center;
        width: 70%;
        border-bottom: 1px solid var(--color-comp-12);
      }

      &-actions {
        width: 100%;
        @include layout-row-space-between;
      }
    }

    .card-dropdown {
      width: 100%;

      transition: $transition-default;

      &-open {
        .dropdown-icon-arrow {
          & svg {
            stroke: var(--color-base-1);
          }
        }

        .dropdown-icon-edit {
          & svg {
            fill: var(--color-base-1);
          }
        }

        .dropdown-content {
          animation: open-height 0.5s ease-in-out forwards;
        }

        .dropdown-title {
          color: var(--color-base-1);
          text-shadow: $shadow-default;
        }
      }

      &-close {
        .dropdown-icon-arrow {
          transition: $transition-default;

          & svg {
            stroke: var(--color-comp-10);
          }
        }

        .dropdown-icon-edit {
          transition: $transition-default;

          & svg {
            fill: var(--color-comp-10);
          }
        }

        .dropdown-content {
          animation: close-height 0.5s ease-in-out forwards;
        }

        .dropdown-title {
          color: var(--color-comp-10);
          text-shadow: none;
        }
      }

      .dropdown-icon {
        width: 3rem;
        height: 3rem;

        padding: 0.5rem;

        border-radius: 50%;

        &:hover {
          & svg {
            @include interact-svg-default;
          }
        }

        &:active {
          & svg {
            @include interact-svg-default(active);
          }
        }

        & svg {
          width: 100%;
          height: 100%;

          transition: $transition-default;
        }
      }

      .dropdown-titlebar {
        width: 100%;

        cursor: pointer;

        @include grid(
          $template-columns: 1fr min-content,
          $template-rows: min-content,
          $justify-items: start
        );
      }

      .dropdown-titles {
        .dropdown-title {
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;

          transition: $transition-default;
        }
      }

      .dropdown-content {
        width: 100%;
        height: 0;
        overflow: hidden;

        transition: $transition-default;

        padding: 0;
        margin: 0;
      }
    }

    &-card {
      width: 100%;

      min-height: 4rem;

      overflow: hidden;

      @include grid(
        $template-columns: min-content 1fr min-content,
        $template-rows: min-content,
        $justify: start
      );

      & > * {
        grid-row: 1 / -1;
      }

      // padding: 0;
      // padding-right: 0;
      padding-left: 0.5rem;

      font-size: $font-size-l5;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-10);

      transition: $transition-default;

      cursor: pointer;

      border-radius: 0.8rem;

      &-wrapper {
        width: 100%;
      }

      &-edit {
        display: block;
        & svg {
          fill: var(--color-base-1);
          height: 3.2rem;
          width: 3.2rem;
        }
      }

      &-title {
        justify-self: start;

        width: 100%;

        position: relative;

        overflow: hidden;

        @include layout-row-start;

        &-text {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // text-wrap: nowrap;
          // text-overflow: ellipsis;
        }

        &-filler {
          position: absolute;
          top: 50%;
          right: 0;

          font-size: $font-size-l6;

          z-index: 99;
        }
      }

      &:hover {
        @include interact-default;
        color: var(--color-base-1);

        .templates-new-card-logo {
          & svg {
            fill: var(--color-base-1);
          }
        }
      }

      &-selected {
        @include interact-default;
        color: var(--color-base-1);

        .templates-new-card-logo {
          & svg {
            fill: var(--color-base-1) !important;
          }
        }
      }

      &:active {
        @include interact-default(active);
        color: var(--color-base-1);

        .templates-new-card-logo {
          & svg {
            fill: var(--color-base-1);
          }
        }
      }

      .templates-new-card-logo {
        & svg {
          height: 3.2rem;
          width: 3.2rem;

          fill: var(--color-comp-10);

          transition: $transition-default;

          margin-right: 0.2rem;
        }
      }

      .templates-new-card-edit {
        justify-self: end;

        &:hover {
          & svg {
            fill: var(--color-base-1);
            @include interact-svg-default;
          }
        }

        &:active {
          & svg {
            fill: var(--color-base-1);
            @include interact-svg-default(active);
          }
        }

        & svg {
          height: 2.2rem;
          width: 2.2rem;

          fill: var(--color-comp-10);

          transition: $transition-default;

          margin-right: 1rem;
        }
      }
    }

    &-upload-file {
      width: 75vw;

      border-radius: 1rem;

      padding: $shadow-resolve-padding;

      background-color: var(--color-comp-16);

      @include interact-default;

      @include grid($template-columns: 1fr, $others: start);

      &-heading {
        width: 100%;

        @include layout-row-space-between;

        & svg {
          width: 2.4rem;
          height: 2.4rem;

          fill: var(--color-base-1);
        }
      }

      &-title {
        color: var(--color-base-1);
        font-size: $font-size-l4;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      .select-file {
        width: 100%;
        position: relative;

        overflow: hidden;

        padding: $shadow-resolve-padding;

        @include layout-col-start;

        & svg {
          width: 6rem;
          height: 6rem;

          fill: var(--color-base-1);

          transition: $transition-default;
        }

        & strong {
          display: block;

          color: var(--color-base-1);
          font-size: $font-size-default;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }

        & input {
          position: absolute;
          background-color: goldenrod;

          width: 100%;
          height: 100%;

          cursor: pointer;

          opacity: 0;

          z-index: 1000;
        }

        & input:hover + svg {
          @include interact-svg-default;
        }

        & input:active + svg {
          @include interact-svg-default(active);
        }
      }

      .upload-file {
        width: 100%;

        overflow: hidden;

        padding: $shadow-resolve-padding;

        @include layout-col-start;
        grid-gap: 1rem;

        &-icon {
          width: 6rem;
          height: 6rem;

          fill: var(--color-base-1);

          transition: $transition-default;
        }

        &-name {
          width: 100%;

          @include grid($template-columns: 1fr min-content);

          grid-gap: 1rem;

          padding: 0 $shadow-resolve-padding;

          border-radius: 0.5rem;

          transition: $transition-default;

          &:hover {
            @include interact-default;
          }

          & input {
            width: 100%;

            color: var(--color-comp-5);
            font-size: $font-size-default;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;

            border: none;
            border-bottom: 1px solid var(--color-comp-13);
            outline: none;
          }

          & svg {
            width: $font-size-default;
            height: $font-size-default;

            fill: var(--color-base-1);

            cursor: pointer;

            transition: $transition-default;

            &:hover {
              @include interact-svg-default;
            }

            &:active {
              @include interact-svg-default(active);
            }
          }
        }
      }

      .upload-actions {
        width: 100%;

        @include layout-row-space-between;
      }
    }
  }

  &-hub {
    align-self: start;

    width: 100%;

    @include layout-col-start;

    &-pagination {
      @include layout-row;
      grid-gap: $shadow-resolve-padding;

      list-style: none;

      color: var(--color-comp-10);
      font-size: $font-size-l4;
      font-weight: $font-weight-l2;
      line-height: $line-height-default;
      font-family: $font-family-1;

      border-top: 1px solid var(--color-comp-12);

      padding: $shadow-resolve-padding;

      .disabled {
        opacity: 0;
      }

      .selected {
        color: var(--color-base-1);
        & a {
          border-color: var(--color-base-1);
        }
      }

      & a {
        display: block;
        width: 3rem;
        height: 3rem;
        padding: 0.5rem;

        border-radius: 50%;

        background-color: var(--color-comp-16);

        border: 1px solid var(--color-comp-16);

        transition: $transition-default;

        text-align: center;

        @include layout-col;

        &:hover {
          @include interact-default;

          color: var(--color-base-1);
        }

        &:active {
          @include interact-default(active);
        }
      }
    }

    &-divider {
      width: 85%;
      padding: 0;

      margin: 3rem;

      height: 1px;

      background-color: var(--color-comp-10);
    }

    &-featured-header {
      justify-self: start;

      padding: 3rem;
    }

    &-heading {
      width: 100%;

      padding: 0 3.3rem;

      @include grid($template-columns: max-content 1fr max-content);

      .hub-heading {
        &-title {
          grid-column: 1 / -1;
          grid-row: 1 / 2;

          justify-self: start;

          color: var(--color-comp-2);
          font-size: $font-size-l2;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }

        &-subtitle {
          grid-column: 1 / 2;
          grid-row: 2 / 3;

          justify-self: start;

          color: var(--color-comp-2);
          font-size: $font-size-l4;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }

        &-actions {
          grid-column: 3 / 4;
          grid-row: 2 / 3;

          @include grid($template-columns: 1fr);

          grid-gap: $shadow-resolve-padding;

          @include screen-size-px(512) {
            width: 100%;
            grid-column: 1 / -1;
            grid-row: 3 / 4;

            margin-top: 2rem;

            grid-template-columns: repeat(2, 1fr);

            justify-content: space-between;
          }

          &-btn {
            color: var(--color-comp-2);
            font-size: $font-size-l6;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;

            padding: 0.8rem;

            border-radius: 0.8rem;

            outline: none;

            border: 1px solid var(--color-comp-10);
          }
        }
      }
    }

    &-selectors {
      width: 100%;

      padding: $shadow-resolve-padding 3.3rem;

      @include grid(
        $template-columns: repeat(2, 1fr),
        $template-rows: min-content
      );

      grid-gap: 1.6rem;

      @include screen-size-px(720) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      .hub-filters-select {
        justify-self: start;
        width: 80%;

        font-size: 1.6rem;

        @include screen-size-px(512) {
          width: 100%;
        }
      }

      .hub-sorts {
        width: 100%;

        @include grid($template-rows: min-content, $justify-content: end);

        & > * {
          grid-row: 1 / 2;
        }

        .hub-sorts-icon {
          width: 2.4rem;
          height: 2.4rem;

          fill: var(--color-comp-10);

          margin-right: 1rem;
          margin-bottom: -1rem;
        }

        .hub-sorts-select {
          width: 20rem;
          justify-self: end;
        }
      }
    }

    &-list {
      width: 100%;

      padding: $shadow-resolve-padding 3.3rem;

      @include grid(
        $template-columns: repeat(auto-fit, minmax(25rem, 42rem)),
        $justify-content: space-between
      );

      grid-gap: 2rem;
    }

    &-card {
      width: 100%;

      cursor: pointer;

      position: relative;

      @include layout-col-start;

      @include interact-default;

      padding: 1.6rem;

      border-radius: 0.8rem;

      .hub-card-shared {
        width: 3rem;
        height: 3rem;

        padding: 0.5rem;

        background-color: rgba(white, 0.6);

        border-radius: 50%;

        position: absolute;
        top: 1.8rem;
        right: 0.2rem;

        & svg {
          width: 100%;
          height: 100%;

          fill: var(--color-base-1);
        }
      }

      .hub-card-preview {
        width: 100%;

        aspect-ratio: 16 / 9;

        @supports not (aspect-ratio: 16 / 9) {
          height: 18rem;
        }

        margin-bottom: 1rem;

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;

          border-radius: 0.8rem;
        }
      }

      .hub-card-description {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        // text-wrap: wrap;

        text-align: center;

        color: var(--color-comp-4);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }
    }
  }
}

.generic-modal {
  width: 70rem;
  height: 80vh;

  background-color: var(--color-comp-16);
  border-radius: 1rem;
  padding: 1.5rem;

  @include grid(
    $template-columns: 1fr,
    $template-rows: min-content 1fr min-content
  );

  & > * {
    width: 100%;
  }

  @include screen-size-px(720) {
    width: calc(100vw - 2rem);
    height: calc(100vh - 4rem);
  }

  .modal-actions {
    align-self: end;

    padding: $shadow-resolve-padding;
  }

  .modal-heading {
    @include layout-col-start;

    padding: 0.8rem $shadow-resolve-padding;

    .modal-titlebar {
      width: 100%;

      @include grid(
        $template-columns: 1fr min-content,
        $template-rows: min-content,
        $justify: start
      );

      .modal-title {
        width: 100%;

        color: var(--color-comp-2);
        font-size: $font-size-l3;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;

        overflow: hidden;

        white-space: nowrap;

        @include screen-size-px(512) {
          font-size: $font-size-l4;
        }
      }

      .modal-close {
        width: 2.4rem;
        height: 2.4rem;

        padding: 0.5rem;

        border-radius: 50%;

        border: none;
        outline: none;

        background-color: transparent;

        @include layout-col;

        & svg {
          width: 1.6rem;
          height: 1.6rem;

          transition: $transition-default;

          fill: var(--color-comp-2);

          &:hover {
            @include interact-svg-default;
          }

          &:active {
            @include interact-svg-default(active);
          }
        }
      }
    }

    .modal-divider {
      width: 100%;
      height: 1px;

      background-color: var(--color-comp-10);

      // margin: 1rem 0;
      margin-top: 0.6rem;
    }

    .modal-subtitle {
      justify-self: start;

      color: var(--color-comp-10);
      font-size: $font-size-l6;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
    }
  }

  .modal-items-wrapper {
    width: 100%;
    height: 100%;

    overflow-x: hidden;
    overflow-y: auto;

    @include grid(
      $template-columns: 1fr,
      $template-rows: repeat(20, max-content),
      $others: start
    );

    grid-gap: $shadow-resolve-padding;

    .modal-titles {
      grid-row: 1 / 2;

      @include layout-row-start;
      grid-gap: 0.6rem;

      user-select: none;

      .modal-title {
        color: var(--color-comp-3);
        font-size: $font-size-default;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      .modal-subtitle {
        color: var(--color-warn-1);
        font-size: $font-size-l7;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        margin-bottom: -0.2rem;
      }
    }

    .modal-text {
      width: 100%;

      padding: 0 $shadow-resolve-padding;

      @include grid($template-columns: 1fr, $others: start);

      & input {
        grid-row: 2 / 3;

        width: calc(100% - 1.6rem);

        padding: 0 0.8rem;

        outline: none;
        border: none;
        border-bottom: 1px solid var(--color-comp-10);

        color: var(--color-comp-5);
        font-size: $font-size-default;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;

        &::placeholder {
          color: var(--color-comp-10);
          font-weight: $font-weight-l4;
        }
      }
    }

    .modal-icons {
      width: 100%;

      padding: 0 $shadow-resolve-padding;

      .modal-icon-selected {
        @include interact-svg-default;
        fill: var(--color-base-1);
      }

      .modal-icon-not-selected {
        fill: var(--color-comp-10);
      }

      .modal-icons-items {
        width: 100%;

        grid-row: 2 / 3;

        padding: $shadow-resolve-padding;

        @include grid(
          $template-columns: repeat(auto-fit, minmax(5rem, 1fr)),
          $justify-content: space-between
        );

        grid-gap: $shadow-resolve-padding;

        & svg {
          width: 4rem;
          height: 4rem;

          // fill: var(--color-comp-10);

          transition: $transition-default;

          &:hover {
            @include interact-svg-default;
            fill: var(--color-base-1);
          }

          &:active {
            @include interact-svg-default(active);
            fill: var(--color-base-1);
          }
        }
      }
    }

    .modal-radios {
      width: 100%;

      padding: 0 $shadow-resolve-padding;

      @include grid($template-columns: 1fr, $others: start);

      &-items {
        width: 100%;

        border: none;
        outline: none;

        padding: 0 $shadow-resolve-padding;

        color: var(--color-comp-10);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        // @include grid(
        //   $template-columns: repeat(auto-fit, minmax(max-content, 1fr)),
        //   $justify-content: space-between
        // );

        // grid-gap: $shadow-resolve-padding;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      &-item {
        & input {
          display: none;
        }

        padding: 0.5rem 1rem;

        border: 1px solid var(--color-comp-10);

        border-radius: 0.4rem;

        transition: $transition-default;

        user-select: none;

        &:hover {
          @include interact-default;
        }

        &:active {
          @include interact-default(active);
        }

        margin: 0 1rem 1rem 0;

        &-selected {
          color: var(--color-base-2);
          border: 1px solid var(--color-base-1);
        }
      }
    }

    .modal-doc {
      width: 100%;

      padding: 0 $shadow-resolve-padding;

      @include grid($template-columns: 1fr, $others: start);

      &-content {
        justify-self: center;
        width: 100%;
        height: 30rem;

        margin-top: 1rem;

        overflow: hidden;

        .ck-editor__editable_inline:not(.ck-comment__input *) {
          height: 22rem;
        }
      }
    }
  }
}

.modal-input-selector {
  width: 100%;

  padding: 0 $shadow-resolve-padding;

  @include grid($template-columns: 1fr, $others: start);

  grid-gap: 1.6rem;

  &-range {
    width: 100%;

    @include grid($template-columns: 1fr min-content 1fr);

    grid-gap: 1rem;

    &-separator {
      color: var(--color-comp-10);
      font-size: $font-size-l1;
      font-weight: $font-weight-l1;
    }
  }

  &-date {
    width: 20rem;

    border-bottom: 1px solid var(--color-comp-10);

    padding: 0 1rem;

    & input {
      width: 100%;

      border: none;
      outline: none;

      color: var(--color-comp-5);
      font-size: $font-size-default;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;

      &::placeholder {
        color: var(--color-comp-10);
        font-weight: $font-weight-l4;
      }
    }
  }

  &-text {
    width: 100%;

    border-bottom: 1px solid var(--color-comp-10);

    & input {
      width: 100%;

      border: none;
      outline: none;

      color: var(--color-comp-5);
      font-size: $font-size-default;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;

      &::placeholder {
        color: var(--color-comp-10);
        font-weight: $font-weight-l4;
      }
    }
  }

  &-signatures {
    width: 100%;

    @include layout-col-start;

    // background-color: rgba(red, 0.15);

    .signature {
      &-canvas {
        width: "100%";
        height: 0;

        overflow: hidden;

        transition: $transition-default;

        &-open {
          transition: $transition-default;

          height: 55rem;
        }

        &-content {
          width: 100%;
          height: 50rem;
        }
      }

      &-items {
        width: 100%;

        padding: $shadow-resolve-padding;

        @include grid(
          $template-columns: repeat(auto-fit, 13rem),
          $others: start
        );

        grid-gap: 1rem;
      }

      &-item {
        width: 12rem;
        height: 12rem;

        position: relative;

        border-radius: 5px;

        border: 1px solid var(--color-comp-12);

        overflow: hidden;

        transition: $transition-default;

        background-color: var(--color-comp-16);

        &:hover {
          @include interact-default;

          transition: $transition-default;

          & svg {
            display: block;
          }
        }

        &-delete {
          position: absolute;

          top: 0;
          right: 0;

          padding: 0.5rem;

          transition: $transition-default;

          & svg {
            display: none;

            width: 2rem;
            height: 2rem;

            fill: var(--color-comp-12);

            transition: $transition-default;

            &:hover {
              @include interact-svg-default;
              fill: var(--color-base-1);
            }

            &:active {
              @include interact-svg-default(active);
              fill: var(--color-base-1);
            }
          }
        }

        & picture {
          width: 100%;
          height: 100%;

          & img {
            width: 100%;
            height: 100%;

            object-fit: contain;

            vertical-align: middle;
          }
        }
      }
    }
  }

  &-selection {
    width: 100%;

    .selection-title {
      width: 100%;

      color: var(--color-comp-2);
      font-size: $font-size-default;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
    }

    .selection-wrapper {
      width: 50%;

      z-index: 2000;

      @include screen-size-px(720) {
        width: 100%;
      }

      &-select {
        width: 100%;

        position: relative;

        // color: var(--color-comp-5);
        // font-size: $font-size-default;
        // font-weight: $font-weight-l3;
        // line-height: $line-height-default;
        // font-family: $font-family-1;

        font-size: 1.6rem;

        .react-select-input {
          &::placeholder {
            color: var(--color-comp-10);
            font-weight: $font-weight-l1;
          }
        }
      }
    }
  }

  .modal-input-selector-options-generator {
    width: 100%;

    @include layout-col;

    .options-generator-titlebar {
      width: 100%;

      @include layout-row-space-between;

      .options-generator-titles {
        @include layout-row-space-between;

        color: var(--color-comp-2);
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;

        .options-generator-title {
          margin-right: 1rem;
        }

        .options-generator-subtitle {
          color: var(--color-comp-10);
          font-size: $font-size-l7;

          margin-bottom: -0.2rem;
        }
      }

      .options-generator-actions {
        @include layout-row;
      }
    }

    .options-generator-add {
      padding: 0.5rem;

      border-radius: 0.8rem;

      // border: 1px solid var(--color-comp-10);

      background-color: transparent;

      @include grid(
        $template-columns: max-content,
        $template-rows: min-content,
        $justify-content: start
      );

      color: var(--color-base-1);
      font-size: $font-size-l6;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;

      transition: $transition-default;

      &:hover {
        @include interact-default;

        // border-color: var(--color-base-1);

        // & svg {
        //   fill: var(--color-base-1);

        //   @include interact-svg-default;
        // }
      }

      &:active {
        @include interact-default(active);

        // border-color: var(--color-base-1);

        // & svg {
        //   fill: var(--color-base-1);

        //   @include interact-svg-default(active);
        // }
      }

      & svg {
        width: 2rem;
        height: 2rem;

        transition: $transition-default;

        fill: var(--color-base-1);
      }
    }

    .options-generator-content {
      width: 100%;
      padding: $shadow-resolve-padding;

      border-radius: 0.5rem;

      border: 1px solid var(--color-comp-11);

      margin-top: 0.5rem;

      @include grid(
        $template-columns: repeat(auto-fit, minmax(20rem, 1fr)),
        $justify: start
      );

      grid-gap: 1rem;
    }
  }
}

.options-generator-item {
  width: 20rem;

  border: 1px solid var(--color-comp-12);

  padding: 0.5rem;

  transition: $transition-default;

  border-radius: 0.8rem;

  &-selected {
    @include interact-default;
  }

  &:hover {
    @include interact-default;
  }

  @include grid(
    $template-columns: min-content 1fr min-content,
    $template-rows: min-content,
    $justify-content: start
  );

  .options-generator-item-inputs {
    width: 100%;

    @include layout-row-start;

    border: none;
    outline: none;
    padding: 0;
    margin: 0;

    .options-generator-item-checkbox {
      margin: 0 1rem;
    }

    .options-generator-item-text {
      width: 100px;

      border: none;
      outline: none;
      padding: 0;
      margin: 0;

      &:disabled {
        background-color: transparent;
      }
    }
  }

  .options-generator-item-icon {
    width: 2.4rem;
    height: 2.4rem;

    padding: 0.5rem;

    border-radius: 50%;

    background-color: transparent;

    cursor: pointer;

    @include layout-col;

    transition: $transition-default;

    &:hover {
      & svg {
        fill: var(--color-base-1);

        @include interact-svg-default;
      }
    }

    &:active {
      & svg {
        fill: var(--color-base-1);

        @include interact-svg-default(active);
      }
    }

    & svg {
      width: 100%;
      height: 100%;

      transition: $transition-default;

      fill: var(--color-comp-9);
    }
  }
}

@keyframes open-height {
  from {
    height: 0;
  }
  to {
    height: auto;
  }
}

@keyframes close-height {
  from {
    height: auto;
  }
  to {
    height: 0;
  }
}

.modal-tags {
  width: 100%;

  padding: 0 $shadow-resolve-padding;

  @include grid($template-columns: 1fr, $others: start);

  & > * {
    grid-column: 1 / 2;
  }

  &-container {
    width: 100%;

    @include grid($template-columns: 1fr min-content, $others: start);

    grid-gap: 2rem;
  }

  &-selector {
    width: 100%;

    .react-select-menu {
      grid-template-columns: 1fr;
    }

    .react-select-input {
      padding: 0;
      margin: 0;

      // visibility: hidden;
    }

    .react-select-container {
      // display: block ;

      // position: relative;

      cursor: pointer;

      border: none;
      outline: none;

      box-shadow: none; // required for removing border

      height: auto;
      min-height: auto;

      background-color: transparent;

      padding: 0;
      margin: 0;
    }

    .react-select-control {
      // display: block ;

      // position: relative;

      cursor: pointer;

      border: none;
      outline: none;

      box-shadow: none;

      height: auto;
      min-height: auto;

      background-color: transparent;

      padding: 0;
      margin: 0;
    }

    .react-select-menu-list {
      width: 100%;
    }

    .react-select-option {
      width: 100%;
    }

    .react-select-value-container {
      padding: 0;
      margin: 0;
    }

    // .react-select-container {
    //   width: 100%;

    //   padding: 0;

    //   cursor: pointer;

    //   border: none;
    //   outline: none;
    // }

    .react-select-menu-portal {
      z-index: 9999 !important;
    }

    .react-select-indicators-container {
      display: none;
    }

    .react-select-placeholder {
      color: var(--color-comp-17);
      font-size: $font-size-l6;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
    }

    .react-select-input,
    .react-select-input-single-value,
    .react-select-input-multi-value {
      color: var(--color-comp-7);
      font-size: $font-size-default;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
    }

    border-bottom: 1px solid var(--color-comp-2);

    height: 100%;
  }
}

.dropdown-as-main-section {
  & > .dropdown-titlebar {
    margin-bottom: 1rem;

    .dropdown-titles {
      .dropdown-title {
        font-family: $font-family-1;
        font-size: $font-size-l3;
        font-weight: $font-weight-l3;
        line-height: $line-height-l1;
        color: var(--color-comp-2);

        text-shadow: none;
      }
    }

    .dropdown-icon {
      &-arrow {
        display: none;
      }

      &-add {
        display: none;
      }
    }
  }
}
