@use "../variables" as *;

@mixin interact-default($type: hover) {
  @if $type == active {
    box-shadow: none;
    transform: translateY(0);
  } @else {
    box-shadow: $shadow-default;
    transform: translateY(-0.2rem);
  }
}

@mixin interact-svg-default($type: hover) {
  @if $type == active {
    filter: none;
    transform: translateY(0);
  } @else {
    filter: drop-shadow($shadow-default);
    transform: translateY(-0.2rem);
  }
}

@mixin scroll-bar-default() {
  &::-webkit-scrollbar {
    width: $scroll-bar-size;
    height: $scroll-bar-size;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--color-comp-9);
    border-radius: $scroll-bar-border-radius;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--color-comp-3);
    border-radius: $scroll-bar-border-radius;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-comp-1);
  }
}
