@use "../../abstract" as *;

.generic-inputs {
  &-1 {
    &-container {
      width: 100%;
      min-height: 8rem;

      padding: 1.4rem;

      border-radius: 1.5rem;

      box-shadow: $shadow-1;

      text-wrap: nowrap;

      @include screen-size-px(512) {
        padding: 1.4rem 0.6rem;
      }

      @include grid(
        $template-columns: 1fr min-content,
        $template-rows: min-content,
        $justify-content: start
      );

      & > * {
        grid-row: 1 / -1;
      }

      @include screen-size-l2 {
        height: auto;

        @include grid(
          $template-columns: 1fr,
          $template-rows: repeat(2, min-content),
          $justify-content: start
        );

        & > * {
          grid-row: auto;
          grid-column: 1 / -1;
        }

        // grid-gap: 1.6rem;
      }

      .sub-container {
        width: 100%;

        padding: 1rem;

        @include grid(
          $template-columns: min-content 1fr min-content,
          $template-rows: min-content,
          $justify-content: start
        );

        & > * {
          grid-row: 1 / -1;
        }
      }

      @include screen-size-px(512) {
        padding: 0;
      }

      .logo {
        &-before {
          width: 2.5rem;
          height: 2.5rem;

          margin: 0.6rem;
          margin-right: 0rem;

          @include layout-row;

          & svg {
            width: 100%;
            height: 100%;
          }

          &-valid {
            & svg {
              fill: var(--color-base-1);
            }
          }

          &-invalid {
            & svg {
              fill: var(--color-comp-11);
            }
          }
        }

        &-after {
          margin: 0.6rem;

          @include layout-row;

          & svg {
            width: 2.5rem;
            height: 2.5rem;

            fill: var(--color-comp-4);
          }
        }
      }

      .why-need {
        width: 22rem;

        margin-left: 0.6rem;

        display: block;

        @include screen-size-l2 {
          display: none;
        }

        .btn {
          background-color: var(--color-comp-12);

          border-radius: 1.2rem;

          color: var(--color-base-1);
          font-size: $font-size-default;
          line-height: $line-height-default;
          font-weight: $font-weight-l4;
          font-family: $font-family-1;
        }
      }

      .generic-text {
        width: 100%;

        margin-left: 1.6rem;

        color: var(--color-comp-1);
        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-l4;
        font-family: $font-family-1;

        border: none;
        outline: none;

        @include screen-size-l2 {
          height: 5.8rem;
        }

        @include screen-size-l2 {
          font-size: px2rem-l1(17px);

          &::placeholder {
            font-size: $font-size-default;
          }
        }
      }

      .generic-select-menu {
        z-index: 999;
      }

      .generic-select {
        // height: 5rem;
        .generic-select-input {
          @include screen-size-l2 {
            font-size: px2rem-l1(17px);
          }
        }
      }

      .datepicker-1-container {
        width: 100%;
        height: 2.5rem;

        @include screen-size-l2 {
          height: 5.8rem;
        }

        margin-left: 1.6rem;

        overflow: hidden;

        @include grid(
          $template-columns: 1fr,
          $template-rows: min-content,
          $justify-content: space-between
        );

        & > * {
          grid-row: 1 / -1;
          grid-column: 1 / -1;
        }

        &:focus {
          outline: none;
          background-color: #fff;
        }

        .display {
          width: 100%;

          @include grid(
            $template-columns: 1fr min-content,
            $template-rows: min-content,
            $justify-content: start
          );

          & > * {
            grid-row: 1 / -1;
          }

          .date-time-value {
            color: var(--color-comp-8);
            font-size: $font-size-default;
            line-height: $line-height-default;
            font-weight: $font-weight-l4;
            font-family: $font-family-1;

            justify-self: start;
            width: 100%;
          }

          & svg {
            width: 2.6rem;
            height: 2.8rem;

            fill: var(--color-comp-4);
          }
        }

        .react-datepicker__input-container {
          // display: none;

          width: 100%;

          grid-column: 2 / 3;

          & input {
            display: none;
            width: 100%;
          }
        }

        .react-datepicker-wrapper {
          width: 100%;
        }
      }

      .content {
        width: 100%;

        @include layout-col;
      }
    }

    &-date-picker {
      width: 100%;

      padding-left: 1rem;

      @include grid(
        $template-columns: 1fr min-content,
        $template-rows: min-content,
        $justify-content: space-between
      );

      & > * {
        grid-row: 1 / -1;
      }

      grid-gap: 1rem;

      &-date {
        width: 100%;

        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-l4;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        overflow: hidden;
        white-space: wrap;
      }

      &-placeholder {
        width: 100%;

        font-size: $font-size-default;
        line-height: $line-height-default;
        font-weight: $font-weight-l4;
        font-family: $font-family-1;
        color: var(--color-comp-10);
      }

      &-icon {
        padding: 0.5rem;
        border-radius: 50%;

        & svg {
          width: 2.6rem;
          height: 2.8rem;

          fill: var(--color-comp-4);

          cursor: pointer;

          transition: $transition-default;

          &:hover {
            @include interact-svg-default;
          }

          &:active {
            @include interact-svg-default(active);
          }
        }
      }
    }
  }
}

.real-estate-category {
  width: 22rem;
  border-radius: 1.5rem;

  padding: $shadow-resolve-padding;

  @include interact-default;

  background-color: var(--color-comp-16);

  font-size: $font-size-l5;
  line-height: $line-height-default;
  font-weight: $font-weight-l4;
  font-family: $font-family-1;

  & b {
    color: var(--color-comp-2);
    font-size: $font-size-default;
  }
}
