@use "../abstract" as *;

.reports {
  &-cover {
    $icon-distance: 1rem;

    height: 100%;
    aspect-ratio: 16 / 9;
    @supports not (aspect-ratio: 16 / 9) {
      width: 27rem;
    }

    position: relative;

    transition: $transition-default;

    &:hover > &-icon {
      display: block;
    }

    &:hover > &-preview {
      @include interact-default;
    }

    &-view {
      top: $icon-distance;
      right: $icon-distance;

      @include screen-size-px(512) {
        display: none !important;
      }
    }

    &-cancel {
      bottom: $icon-distance;
      left: $icon-distance;
    }

    &-upload {
      bottom: $icon-distance;
      right: $icon-distance;
    }

    &-select {
      width: 10rem !important;
      height: 10rem !important;

      @include screen-size-px(512) {
        width: 5rem !important;
        height: 5rem !important;
      }

      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%);

      // ***

      & input {
        cursor: pointer;

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;

        z-index: 1000;

        &::-webkit-file-upload-button {
          display: none;
        }

        &:active + svg {
          @include interact-svg-default(active);
          // fill: var(--color-base-1);
        }
      }
    }

    &-preview {
      display: block;
      height: 100%;
      width: 100%;

      overflow: hidden;

      transition: $transition-default;

      border-radius: 0.8rem;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        vertical-align: middle;

        transition: $transition-default;

        border-radius: 0.8rem;

        border: 1px solid var(--color-comp-12);
      }
    }

    &-icon {
      display: none;
      transition: $transition-default;

      width: 3rem;
      height: 3rem;

      position: absolute;

      & svg {
        width: 100%;
        height: 100%;
        fill: var(--color-comp-12);

        cursor: pointer;

        transition: $transition-default;

        filter: drop-shadow(0px 0px 5px rgba(black, 1));
        transform: translateY(-0.2rem);

        z-index: 10;

        &:active {
          @include interact-svg-default(active);
          // fill: var(--color-base-1);
        }
      }
    }
  }

  &-new {
    @include grid($template-columns: 1fr 1fr 1.5fr, $others: start);

    @include screen-size-px(1256) {
      grid-template-columns: repeat(2, 1fr);
    }

    &-cover {
      grid-row: 1 / 3;
      grid-column: 1 / 2;

      width: 100%;
      aspect-ratio: 16 / 9;
      @supports not (aspect-ratio: 16 / 9) {
        height: 17rem;
      }

      @include screen-size-px(1024) {
        grid-row: 1 / 2;
      }

      @include screen-size-px(720) {
        grid-column: 1 / -1;
        grid-row: 3 / 4;
      }

      &-card {
        width: 100%;

        @include layout-col-start;

        .cover-card-preview {
          display: block;
          width: 100%;
          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 16 / 9) {
            height: 17rem;
          }

          border-radius: 0.8rem;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0.8rem;
          }
        }

        .cover-card-action {
          margin-top: 0.6rem;
          width: auto;

          background-color: transparent;

          outline: none;
          border: none;

          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          padding: 0.4rem 0.8rem;

          border-radius: 0.8rem;

          &:hover,
          &:active {
            color: var(--color-base-1);
          }
        }
      }
    }

    &-heading {
      width: 100%;
      grid-column: 2 / -1;
      grid-row: 1 / 2;

      @include layout-col-start;

      @include screen-size-px(720) {
        grid-column: 1 / -1;
        grid-row: 1 / 2;
      }

      &-content {
        width: 100%;

        // @include grid($template-columns: 1fr 1fr, $others: start);

        // @include screen-size-px(1256) {
        //   grid-template-columns: 1fr;
        // }

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .heading-content-titles {
          // @include grid(
          //   $template-columns: repeat(2, max-content),
          //   $others: start
          // );

          // @include screen-size-px(1024) {
          //   grid-template-columns: 1fr;
          // }

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;

          .heading-content-title {
            margin-right: 1rem;

            font-size: $font-size-l2;
            font-weight: $font-weight-l1;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-2);

            margin-top: -1rem;

            @include screen-size-px(1024) {
              margin-bottom: 1rem;
            }
          }
        }

        .heading-content-actions {
          justify-self: end;
          // @include grid($template-columns: repeat(2, max-content));

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;

          grid-gap: $shadow-resolve-padding;

          @include screen-size-px(1256) {
            justify-self: start;

            margin-top: 2rem;
          }

          // @include screen-size-px(1024) {
          //   grid-template-columns: 1fr;
          // }

          .heading-content-action {
            width: 17rem;
            font-size: $font-size-l6;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-10);

            padding: 0.6rem 2.3rem;

            border-radius: 0.4rem;

            border: 1px solid var(--color-comp-10);

            outline: none;

            & svg {
              width: 2rem;
              height: 2rem;
              fill: var(--color-comp-6);

              margin-left: 0.5rem;
            }
          }
        }
      }
    }

    &-progressbar {
      width: 100%;

      padding-bottom: 3rem;

      grid-row: 5 / 6;
      grid-column: 2 / 4;

      @include screen-size-px(1256) {
        grid-row: 2 / 3;
        grid-column: 2 / -1;
      }

      @include screen-size-px(1024) {
        grid-row: 2 / 3;
        grid-column: 1 / -1;
      }

      .new-report-progressbar {
        width: 100%;

        @include grid($template-columns: 1fr, $others: start);

        .progressbar-titles {
          width: 100%;

          margin-bottom: 1.6rem;

          @include layout-row-start;

          .progressbar-title {
            font-size: $font-size-l3;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-2);
          }

          .progressbar-subtitle {
            font-size: $font-size-default;
            font-weight: $font-weight-l1;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-10);

            margin-left: 3.2rem;

            margin-bottom: -0.5rem;
          }
        }

        .progressbar-progress {
          width: 100%;

          position: relative;

          .report-progress-bar {
            height: 1.6rem;

            border-radius: 1rem;

            border: none;

            overflow: hidden;

            background-color: var(--color-comp-14);

            .report-progress-bar-inner {
              width: 110%;
              height: 100%;

              border-radius: 1rem;

              background-image: linear-gradient(
                to right,
                transparent,
                var(--color-base-5)
              );

              border-radius: 0.4rem;
            }
          }

          .progress-nob {
            width: 3.6rem;
            height: 3.6rem;

            border-radius: 50%;

            border: 1px solid var(--color-base-1);

            position: absolute;

            top: 50%;

            transform: translate(-50%, -50%);

            &-25 {
              left: 25%;

              background-color: #dff0da;
            }

            &-50 {
              left: 50%;
            }

            &-75 {
              left: 75%;
            }
          }
        }
      }
    }

    .templates-new-sections {
      grid-column: 1 / 2;
      grid-row: 3 / 6;

      height: 100%;

      @include screen-size-px(720) {
        grid-column: 1 / -1;
        grid-row: 4 / 5;
      }
    }

    .templates-new-items {
      grid-column: 2 / 3;
      grid-row: 2 / 5;

      @include screen-size-px(1256) {
        grid-column: 2 / -1;
        grid-row: 3 / 6;
      }

      @include screen-size-px(720) {
        grid-column: 1 / -1;
        grid-row: 5 / 6;
      }
    }

    .templates-new-observations {
      grid-column: 3 / 4;
      grid-row: 2 / 5;

      @include screen-size-px(1256) {
        grid-column: 1 / -1;
        grid-row: 6 / 7;
      }
    }
  }
}

.report-modal {
  &-files {
    width: 100%;

    // height: 39rem;

    padding: 0 $shadow-resolve-padding;

    overflow: hidden;

    @include grid(
      $template-columns: 1fr,
      $template-rows: repeat(4, max-content),
      $align-content: start
    );

    // grid-gap: $shadow-resolve-padding;

    &-titlebar {
      width: 100%;

      padding-top: $shadow-resolve-padding;

      padding-bottom: 2rem;

      & h6 {
        color: var(--color-comp-3);
        font-size: $font-size-l4;
        font-weight: 500;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }

      &-actions {
        margin-bottom: -2rem;
      }

      @include layout-row-space-between;
    }

    &-slider {
      width: 100%;

      overflow: hidden;

      @include grid($template-columns: min-content 1fr min-content);

      &-navigator {
        & svg {
          width: 4rem;
          height: 4rem;

          fill: var(--color-comp-6);

          transition: $transition-default;

          &:hover {
            @include interact-svg-default;
          }

          &:active {
            @include interact-svg-default(active);
          }
        }
      }

      &-slides {
        width: 100%;

        padding: 0 $shadow-resolve-padding;

        overflow: hidden;

        .general-slider {
          width: 100%;
          overflow: hidden;

          aspect-ratio: 16 / 10;

          @supports not (aspect-ratio: 16/9) {
            height: 35rem;
          }
        }

        &-item {
          width: 100%;
          position: relative;

          padding: $shadow-resolve-padding;

          overflow: hidden;

          @include grid(
            $template-columns: 1fr,
            $template-rows: repeat(2, max-content)
          );

          & picture {
            width: 100%;
            aspect-ratio: 16 / 9;

            @supports not (aspect-ratio: 16/9) {
              height: 30rem;
            }

            // padding: $shadow-resolve-padding;

            // overflow: hidden;

            & img {
              width: 100%;
              height: 100%;

              overflow: hidden;

              vertical-align: middle;

              object-fit: cover;

              transition: $transition-default;

              border-radius: 0.5rem;

              &:hover {
                @include interact-default;
              }
            }
          }

          & video {
            width: 100%;
            aspect-ratio: 16 / 9;

            @supports not (aspect-ratio: 16/9) {
              height: 30rem;
            }
          }

          & svg {
            width: 2.2rem;
            height: 2.2rem;

            position: absolute;
            top: 2.5rem;
            right: 2.5rem;

            fill: var(--color-comp-10);

            transition: $transition-default;

            &:hover {
              @include interact-svg-default;
              fill: var(--color-base-1);
            }

            &:active {
              @include interact-svg-default(active);
              fill: var(--color-base-1);
            }
          }
        }
      }
    }
  }
}
