@use "../../abstract" as *;

.card {
  // Horizontal Info
  &-ih {
    @include grid(
      $template-columns: min-content 1fr,
      $template-rows: min-content,
      $content: start,
      $justify-items: start
    );

    .preview {
      grid-column: 1 / 2;
      grid-row: 1 / -1;
    }

    .info {
      grid-column: 2 / -1;
      grid-row: 1 / -1;
    }

    // Vertical Details
    &-dv {
      // Vertical Actions
      &-av {
        @include grid(
          $template-columns: min-content 1fr,
          $template-rows: repeat(3, min-content),
          $content: start,
          $justify-items: start
        );

        .preview {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }

        .info {
          grid-column: 2 / -1;
          grid-row: 1 / 2;
        }

        .details {
          grid-column: 1 / -1;
          grid-row: 2 / 3;
        }

        .actions {
          grid-column: 1 / -1;
          grid-row: 3 / -1;
        }
      }
    }
  }
}
