.tippy-box[data-theme~="base"] {
  background-color: var(--color-base-1);

  color: white;
  font-size: 1.6rem;
  font-weight: 600;

  opacity: 0.5;

  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.tippy-box[data-theme~="base"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: var(--color-base-1);

  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
}
.tippy-box[data-theme~="base"][data-placement^="bottom"]
  > .tippy-arrow::before {
  border-bottom-color: var(--color-base-1);

  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
}
.tippy-box[data-theme~="base"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: var(--color-base-1);

  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
}
.tippy-box[data-theme~="base"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: var(--color-base-1);

  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15));
}
