$font-size-default: 1.6rem;
$font-size-lxx1: 9.6rem;
$font-size-lx1: 6.4rem;
$font-size-l1: 4.8rem;
$font-size-lx2: 3.6rem;
$font-size-l2: 3.2rem;
$font-size-l3: 2.4rem;
$font-size-l4: 2rem;
$font-size-l5: 1.4rem;
$font-size-l6: 1.2rem;
$font-size-l7: 1rem;

$font-weight-default: 300;
$font-weight-l1: 700;
$font-weight-l2: 600;
$font-weight-l3: 500;
$font-weight-l4: 400;

$line-height-default: 1.6;
$line-height-l1: 1.5;
$line-height-lx1: 1.3;
$line-height-l2: 1.2;
$line-height-l3: 1;

$font-family-default: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue";

$font-family-1: "Lexend", "Roboto", sans-serif;
