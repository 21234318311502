// Grid Mixins ********************************************************************
@mixin grid(
  $template-columns: false,
  $template-rows: false,

  $justify-content: false,
  $align-content: false,
  $align-items: false,
  $justify-items: false,

  $others: center,
  $justify: false,
  $align: false,
  $content: false,
  $items: false
) {
  display: grid;
  @if $template-columns {
    grid-template-columns: $template-columns;
  }
  @if $template-rows {
    grid-template-rows: $template-rows;
  }

  @if $align-content {
    align-content: $align-content;
  } @else if $align {
    align-content: $align;
  } @else if $content {
    align-content: $content;
  } @else {
    align-content: $others;
  }

  @if $align-items {
    align-items: $align-items;
  } @else if $align {
    align-items: $align;
  } @else if $items {
    align-items: $items;
  } @else {
    align-items: $others;
  }

  @if $justify-content {
    justify-content: $justify-content;
  } @else if $justify {
    justify-content: $justify;
  } @else if $content {
    justify-content: $content;
  } @else {
    justify-content: $others;
  }

  @if $justify-items {
    justify-items: $justify-items;
  } @else if $justify {
    justify-items: $justify;
  } @else if $items {
    justify-items: $items;
  } @else {
    justify-items: $others;
  }

  @content;
}

@mixin grid-item-area(
  $column-start: false,
  $column-end: -1,
  $row-start: false,
  $row-end: -1
) {
  @if $column-start {
    grid-column: #{$column-start} / #{$column-end};
  }

  @if $row-start {
    grid-row: #{$row-start} / #{$row-end};
  }
}
