@use "../../abstract" as *;

.quick-access {
  $quick-access-width: 26rem;

  align-self: start;
  justify-self: start;

  width: $quick-access-width;
  height: 100%;

  @include screen-size-px(1400) {
    width: 0;
  }

  &-nav {
    $quick-access-pos-top: 9rem;

    width: $quick-access-width;
    height: calc(100% - #{$quick-access-pos-top});

    position: fixed;
    top: $quick-access-pos-top;
    left: 0rem;

    padding-bottom: 5rem;

    @include layout-col-space-between;

    @include screen-size-px(1400) {
      display: none;
    }

    .user {
      @include layout-col-start;

      .preview {
        width: 7.1rem;
        height: 7.1rem;

        border-radius: 50%;

        overflow: hidden;

        margin: 2.5rem;

        & img {
          width: 100%;
          height: 100%;

          border-radius: 50%;

          object-fit: cover;
        }
      }

      .name {
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);
      }
    }

    .links {
      @include layout-col-start;

      & a {
        text-decoration: none;
        color: inherit;
      }

      color: var(--color-comp-10);
      font-size: $font-size-l5;
      font-weight: $font-weight-l4;
      line-height: $line-height-l2;
      font-family: $font-family-1;

      & > a {
        display: inline-block;

        padding: 0rem 1rem;

        margin: 1.2rem 0;

        transition: $transition-default;

        &:hover {
          @include interact-text-default;
        }

        &:active {
          @include interact-text-default(action);
        }
      }

      .active {
        @include interact-text-default;
      }
    }

    .actions {
      width: 100%;

      @include layout-col-start;

      .btn {
        width: 11.4rem;
      }

      .title {
        margin-top: 3.2rem;
      }
    }
  }
}
