@use "../abstract" as *;

$space-horizontal: 7vw;
$space-vertical: 5vw;

.homepage {
  width: 100%;

  @include layout-col-start;

  & > * {
    width: 100%;
  }

  &-spacer {
    width: 100%;
    padding: $space-vertical 0;
  }

  .invisible {
    opacity: 0;
  }

  .section {
    &-1 {
      margin-top: -8rem;

      @include grid($template-columns: 1fr, $template-rows: 1fr);

      & > * {
        grid-row: 1 / -1;
        grid-column: 1 / -1;
      }

      &-content {
        width: 100%;

        // padding: 9.5rem 15.5rem;
        padding: $space-vertical $space-horizontal;

        @include grid(
          $template-columns: repeat(2, 1fr),
          $template-rows: min-content
        );

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;

          grid-template-rows: repeat(2, min-content);
        }

        // grid-gap: 7.5rem;
        grid-gap: $space-horizontal;

        .section-1-heading {
          margin-top: 5rem;
          width: 100%;
          height: 100%;

          z-index: 200;

          line-height: $line-height-lx1;
          font-family: $font-family-1;

          @include grid(
            $template-columns: 1fr,
            // $align-content: space-between,
            // $template-rows: min-content min-content min-content 1fr,
            $others: start
          );

          .heading-name {
            font-size: calc(var(--main-hading-base-size) * 6.4);
            font-weight: $font-weight-l1;
            color: var(--color-base-1);
          }

          .heading-services {
            font-size: calc(var(--main-hading-base-size) * 6.4);
            font-weight: $font-weight-l1;
            color: var(--color-comp-1);

            span {
              display: inline-block;
            }
          }

          .heading-description {
            margin-top: 1rem;
            font-size: calc(var(--main-hading-base-size) * 3.2);
            font-weight: $font-weight-l4;
            color: var(--color-comp-8);

            margin-bottom: 1rem;
          }

          .heading-action {
            margin-top: 2rem;

            .btn {
              font-size: $font-size-l3;
              font-weight: $font-weight-l4;

              padding: 1.2rem 5.2rem;
            }
          }
        }

        .section-1-preview {
          width: 100%;

          animation: homepage-preview 1s linear 0s forwards;

          @include grid(
            $template-columns: 1.6fr 1fr,
            $template-rows: repeat(2, min-content)
          );

          grid-gap: 1rem;

          .preview-top {
            grid-row: 1 / 3;
            grid-column: 1 / 3;

            width: 100%;
            height: 100%;

            z-index: 100;

            border-top-left-radius: 3.5rem;

            position: relative;
            overflow: hidden;

            &::after {
              content: "";
              position: absolute;
              width: 200%;
              height: 200%;
              top: -50%;
              left: -50%;
              z-index: -1;
              background-image: linear-gradient(
                to right,
                rgba(white, 0.4) 0px,
                rgba(white, 0.4) 1px,
                rgba(white, 0.1) 100%
              );
              transform: rotate(30deg);

              // 3s delay
              animation: anime-slide-flash 1s linear 6s forwards;
            }
          }

          .preview-1 {
            grid-row: 1 / 2;
            grid-column: 1 / 3;

            border-radius: 3.8rem;
            border-top-right-radius: 0;
          }

          .preview-2 {
            grid-row: 2 / 3;
            grid-column: 1 / 2;

            border-radius: 3.8rem;
            border-bottom-left-radius: 0;
          }

          .preview-3 {
            grid-row: 2 / 3;
            grid-column: 2 / 3;

            border-radius: 3.8rem;
            border-bottom-right-radius: 0;
          }

          & picture {
            width: 100%;

            height: auto;

            padding: 0;
            margin: 0;

            overflow: hidden;

            @include interact-default;

            img {
              width: 100%;

              object-fit: contain;

              vertical-align: middle;
            }
          }
        }
      }

      &-colored-bg {
        width: 75%;
        height: 100%;

        justify-self: start;

        background-color: var(--color-base-7);

        border-bottom-right-radius: 3rem;
      }
    }

    &-2 {
      @include layout-col-start;

      .section-2-heading {
        font-size: calc(var(--main-hading-base-size) * 5);
        font-weight: $font-weight-l1;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        margin-bottom: 3.6rem;

        padding: 0 $space-horizontal;

        .heading-colored {
          color: var(--color-base-1);
        }
      }

      .section-2-content {
        // padding: 0 15.5rem;
        padding: 0 $space-horizontal;

        width: 100%;

        @include grid(
          $template-columns: repeat(auto-fit, minmax(28rem, 1fr)),
          $justify-content: space-between,
          $others: start
        );

        grid-gap: 4rem;
      }

      .section-2-card {
        width: 100%;

        @include grid($template-columns: 1fr, $justify: start);

        .card-index-container {
          @include grid($template-rows: 1fr);

          & > * {
            grid-row: 1 / -1;
          }

          .card-index-colored-bg {
            justify-self: start;
            height: 10rem;
            width: 6rem;

            background-color: var(--color-base-7);

            border-radius: 1.5rem;
            border-bottom-left-radius: 0;
          }

          .card-index {
            align-self: end;

            margin-left: -3.3rem;
            margin-bottom: -1rem;

            font-size: $font-size-lx1;
            font-weight: $font-weight-l1;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-base-1);
          }
        }

        .card-heading {
          font-size: $font-size-l3;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-1);
        }

        .card-content {
          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-l2;
          font-family: $font-family-1;
          color: var(--color-comp-2);
        }
      }
    }

    &-3 {
      @include grid($template-columns: 1fr, $template-rows: 1fr);

      & > * {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }

      &-dots {
        justify-self: start;

        fill: var(--color-base-1);

        transform: translateX(-3rem);

        opacity: 0.2;
      }

      &-content {
        justify-self: center;
        // padding: 0 15.5rem;
        padding: 0 $space-horizontal;

        @include grid($template-columns: max-content 1fr);

        grid-gap: 4.5rem;

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;
        }

        .section-3-payment {
          @include layout-col-start;

          @include interact-default;

          padding: 4.5rem;

          z-index: 99;

          background-color: var(--color-base-1);

          border-radius: 3.5rem;
          border-bottom-right-radius: 0;

          color: var(--color-comp-16);
          line-height: $line-height-default;
          font-family: $font-family-1;

          .payment-prices {
            @include layout-col-start;
          }

          .price-main {
            margin-bottom: 4.5rem;

            &-title {
              font-size: $font-size-lxx1;
              font-weight: $font-weight-l1;
              line-height: $line-height-lx1;
            }

            &-subtitle {
              font-size: $font-size-l3;
              font-weight: $font-weight-l3;
            }
          }

          .price-sub {
            &-title {
              font-size: $font-size-lx2;
              font-weight: $font-weight-l2;

              padding: 0 0.5rem;

              @include screen-size-px(512) {
                font-size: $font-size-l3;
              }
            }

            &-subtitle {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;
            }
          }
        }

        .section-3-description {
          @include grid($template-columns: 1fr, $justify: start);

          z-index: 100;

          // @include interact-text-default;

          // font-size: $font-size-l1;
          // font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          .description {
            &-title {
              font-size: $font-size-lx2;
              font-weight: $font-weight-l1;
            }

            &-subtitle {
              font-size: $font-size-l3;
              font-weight: $font-weight-l3;
            }

            &-content {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;

              text-align: justify;
            }
          }
        }
      }

      &-colored-bg {
        justify-self: end;

        height: 100%;
        width: 42%;

        background-color: var(--color-base-7);

        border-bottom-left-radius: 3.5rem;
      }
    }

    &-4 {
      @include grid($template-columns: 1fr, $template-rows: 1fr);

      & > * {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }

      &-content {
        width: 100%;
        justify-self: center;
        // padding: 0 4.5rem 0 15.5rem;

        padding: 0 $space-vertical 0 $space-horizontal;

        @include grid($template-columns: 54ch 1fr);

        grid-gap: 2rem;

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;
        }

        .section-4-description {
          justify-self: start;
          @include grid($template-columns: 1fr, $justify: start);

          z-index: 100;

          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          .description {
            &-title {
              font-size: $font-size-lx2;
              font-weight: $font-weight-l1;

              margin-bottom: 1.6rem;
            }

            &-subtitle {
              font-size: $font-size-l3;
              font-weight: $font-weight-l3;
            }

            &-content {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;

              text-align: justify;
            }
          }
        }

        .section-4-items {
          width: 100%;

          @include layout-col-start;

          .items {
            width: 100%;

            overflow: hidden;

            .slick-list {
              padding: 0.8rem;
            }

            & picture {
              display: block;
              padding: 0.5rem;

              border: none;

              & img {
                // border: 1px solid var(--color-base-4);
                padding: 0.5rem;

                width: 15vw;

                border-radius: 0.8rem;

                // box-shadow: $shadow-default;

                @include screen-size-px(1024) {
                  width: 27.5vw;
                }
              }
            }
          }

          .items-navigator {
            margin-top: 1.6rem;

            z-index: 99;

            font-size: $font-size-default;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-10);

            @include grid(
              $template-columns: min-content max-content min-content
            );

            & > * {
              grid-row: 1 / 2;
            }

            & svg {
              width: 3.2rem;
              height: 3.2rem;

              fill: var(--color-base-1);

              transition: $transition-default;

              &:hover {
                @include interact-svg-default;
              }

              &:active {
                @include interact-svg-default(active);
              }
            }

            .navigation-wrapper {
              padding: 0.5rem;

              border-radius: 50%;

              @include layout-col;
            }
          }
        }
      }

      &-colored-bg {
        justify-self: start;

        height: 100%;
        width: 47%;

        background-color: var(--color-base-7);

        border-bottom-right-radius: 3.5rem;
      }
    }

    &-5 {
      @include grid($template-columns: 1fr, $template-rows: 1fr);

      & > * {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }

      &-dots {
        justify-self: start;

        fill: var(--color-base-1);

        transform: translateX(-3rem);

        opacity: 0.2;

        @include screen-size-px(1024) {
          opacity: 0.1;
        }
      }

      &-content {
        justify-self: center;
        // padding: 0 15.5rem;
        padding: 0 $space-horizontal;

        z-index: 99;

        @include grid($template-columns: max-content 1fr);

        grid-gap: 4.5rem;

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;
        }

        .section-5-preview {
          width: 100%;
          display: block;
          padding: $shadow-resolve-padding;

          img {
            display: block;

            width: 100%;
            border-radius: 4rem;
            border-bottom-right-radius: 0;

            @include interact-default;
          }
        }

        .section-5-description {
          @include grid($template-columns: 1fr, $justify: start);

          z-index: 100;

          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          .description {
            &-title {
              font-size: $font-size-lx2;
              font-weight: $font-weight-l1;

              margin-bottom: 1.6rem;

              @include screen-size-px(512) {
                font-size: $font-size-l3;
              }
            }

            &-subtitle {
              font-size: $font-size-l3;
              font-weight: $font-weight-l3;
            }

            &-content {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;

              text-align: justify;
            }
          }
        }
      }

      &-colored-bg {
        justify-self: end;

        height: 100%;
        width: 30%;

        background-color: var(--color-base-7);

        border-bottom-left-radius: 3.5rem;
      }
    }

    &-6 {
      @include grid($template-columns: 1fr, $template-rows: 1fr);

      & > * {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
      }

      &-content {
        width: 100%;
        justify-self: center;
        // padding: 0 4.5rem 0 15.5rem;
        padding: 0 $space-vertical 0 $space-horizontal;

        @include grid($template-columns: 54ch 1fr);

        @include screen-size-px(1256) {
          grid-template-columns: 1fr;
        }

        grid-gap: 4.5rem;

        .section-6-description {
          justify-self: start;
          @include grid($template-columns: 1fr, $justify: start);

          z-index: 100;

          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          .description {
            &-title {
              font-size: $font-size-lx2;
              font-weight: $font-weight-l1;

              margin-bottom: 1.6rem;
            }

            &-subtitle {
              font-size: $font-size-l3;
              font-weight: $font-weight-l3;
            }

            &-content {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;

              text-align: justify;
            }
          }
        }

        .section-6-items {
          width: 100%;

          @include grid(
            $template-columns: repeat(auto-fit, 21rem),
            $others: start
          );

          grid-gap: $shadow-resolve-padding;
        }

        .section-6-card {
          width: 100%;
          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 16 / 9) {
            height: auto;
          }

          padding: 2.4rem 0;

          border: 1px solid var(--color-base-4);

          border-radius: 0.8rem;

          @include interact-default;

          @include layout-col-start;

          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-base-1);

          background-color: var(--color-comp-16);

          .card-icon {
            width: 4.8rem;
            height: 5.2rem;

            margin-bottom: 0.5rem;
          }
        }
      }

      &-colored-bg {
        justify-self: start;

        height: 100%;
        width: 47%;

        background-color: var(--color-base-7);

        border-bottom-right-radius: 3.5rem;
      }
    }

    &-7 {
      width: 100%;

      background-color: var(--color-base-7);

      &-content {
        width: 100%;
        justify-self: center;
        // padding: 0 15.5rem;
        padding: 0 $space-horizontal;

        @include grid($template-columns: repeat(2, 1fr));

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;
        }

        grid-gap: 4.5rem;

        .section-7-description {
          justify-self: start;
          @include grid($template-columns: 1fr, $justify: start);

          z-index: 100;

          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          .description {
            &-title {
              font-size: calc(var(--main-hading-base-size) * 5);
              font-weight: $font-weight-l1;

              margin-bottom: 1.6rem;
            }

            &-subtitle {
              font-size: $font-size-l3;
              font-weight: $font-weight-l3;
            }

            &-content {
              font-size: $font-size-default;
              font-weight: $font-weight-l4;

              text-align: justify;
            }

            &-actions {
              justify-self: start;

              margin-top: 1.6rem;

              .btn {
                font-size: $font-size-l3;
                font-weight: $font-weight-l4;

                padding: 1.2rem 5.2rem;
              }
            }
          }
        }

        .phone-particles {
          width: 34vw;

          height: calc(var(--base-particle-size) * 15);

          @include screen-size-px(1024) {
            width: 68vw;
          }

          overflow: visible;

          position: relative;

          .phone-particle {
            position: absolute;

            @include interact-svg-default;

            $size-2: calc(var(--base-particle-size) * 2);
            $size-3: calc(var(--base-particle-size) * 3);
            $size-4: calc(var(--base-particle-size) * 4);
            $size-5: calc(var(--base-particle-size) * 5);
            $size-6: calc(var(--base-particle-size) * 6);

            &-1 {
              width: $size-6;
              height: $size-6;

              top: 0%;
              left: 20%;

              z-index: 100;
            }

            &-2 {
              width: $size-4;
              height: $size-4;

              top: 30%;
              right: 0%;

              z-index: 99;
            }

            &-3 {
              width: $size-3;
              height: $size-3;

              top: 60%;
              left: 15%;

              z-index: 98;
            }

            &-4 {
              width: $size-6;
              height: $size-6;

              top: 30%;
              left: 0%;

              z-index: 50;
            }

            &-5 {
              width: $size-4;
              height: $size-4;

              top: 5%;
              right: 2.5%;

              z-index: 49;
            }

            &-6 {
              width: $size-2;
              height: $size-2;

              top: 70%;
              right: 0%;

              z-index: 48;
            }
          }

          & picture {
            position: absolute;

            top: 55%;
            left: 57%;

            z-index: 120;

            transform: translate(-50%, -50%);

            & img {
              width: 20vw;

              object-fit: contain;

              vertical-align: middle;

              @include screen-size-px(1024) {
                width: 40vw;
              }
            }
          }
        }
      }
    }
  }

  &-footer {
    width: 100%;
    // padding: 0 15.5rem;
    padding: 0 $space-horizontal;

    background-color: var(--color-base-1);

    @include layout-col-start;

    color: var(--color-comp-16);

    &-content {
      width: 100%;

      // @include grid(
      //   $template-columns: repeat(auto-fit, minmax(28rem, 1fr)),
      //   $justify-content: space-between,
      //   $others: start
      // );

      // grid-gap: 7.5rem;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-terms {
      width: 100%;

      &-content {
        width: 100%;

        margin-top: 1.6rem;
        margin-bottom: 3rem;

        @include layout-row-space-between;

        font-size: $font-size-l6;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-16);

        .footer-terms {
          @include layout-row-start;

          grid-gap: 3.6rem;

          & a {
            color: var(--color-comp-16);
            text-decoration: none;

            transition: $transition-default;

            &:hover {
              transform: translateY(-0.2rem);
              text-shadow: $shadow-default;
            }

            &:active {
              transform: translateY(0);
              text-shadow: none;
            }
          }
        }
      }
    }

    &-divider {
      width: 100%;
      background-color: var(--color-comp-16);
      height: 1px;
    }

    &-card {
      padding: 0 $shadow-resolve-padding 5rem $shadow-resolve-padding;

      .card-title {
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-16);

        margin-bottom: 1.6rem;

        text-shadow: $shadow-default;
        transform: translateY(-0.2rem);
      }
      .card-items {
        color: var(--color-comp-16);

        &-vertical {
          @include grid($template-columns: 1fr, $others: start);

          grid-gap: 1.4rem;
        }

        &-horizontal {
          @include grid($template-rows: 1fr, $others: start);

          & > * {
            grid-row: 1 / 2;
          }

          grid-gap: 1.4rem;
        }
      }
      .card-item-container {
        color: var(--color-comp-16);

        @include layout-row-start;

        transition: $transition-default;

        &:hover {
          transform: translateY(-0.2rem);

          text-shadow: $shadow-default;

          & svg {
            filter: drop-shadow($shadow-default);
          }
        }

        &:active {
          transform: translateY(0);

          text-shadow: none;

          & svg {
            filter: none;
          }
        }
      }

      .card-icon {
        align-self: start;

        transition: $transition-default;

        width: 2.4rem;
        height: 2.4rem;

        fill: var(--color-comp-16);

        margin-left: -0.4rem;
        margin-right: 0.5rem;
      }

      .card-label {
        max-width: 40ch;

        transition: $transition-default;

        color: var(--color-comp-16);

        font-size: $font-size-l6;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
      }
    }
  }
}

// anime gradient 45deg
@keyframes anime-slide-flash {
  0% {
    top: -50%;
    left: -50%;
  }

  100% {
    top: 150%;
    left: 150%;
  }
}

@keyframes homepage-preview {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
