@use "../../abstract" as *;

// Layout grid row space between
.layout-gr-sb {
  @include layout-row-space-between;
}

// Layout grid row start
.layout-gr-start {
  @include layout-row-start;
}

// Layout grid row
.layout-gr {
  @include layout-row;
}

// Layout grid col space between
.layout-gc-sb {
  @include layout-col-space-between;
}

// Layout grid col start
.layout-gc-start {
  @include layout-col-start;
}

// Layout grid col
.layout-gc {
  @include layout-col;
}

// Layout grid col (min-content 1fr)
.layout-gc-mic-1fr {
  @include layout-col-mic-1fr;
}

.layout-gc-2x-1fr {
  @include layout-col-2x-1fr;
}

.layout-gr-mic-1fr {
  @include layout-row-mic-1fr;
}
