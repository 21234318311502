@use "../abstract" as *;
.inspections {
  &-svg {
    &-magic {
      width: 2.5rem;
      height: 2.5rem;

      fill: var(--color-base-1);
    }
  }

  &-confirmations {
    width: 100%;

    margin-left: 3rem;

    @include grid(
      $template-columns: 1fr,
      $justify-content: start,
      $align-content: start,
      $justify-items: start
    );

    & > * {
      grid-column: 1 / -1;
    }

    .confirmation {
      & input {
        padding: 0;

        margin-right: 0.8rem;
      }

      .label {
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-8);
      }
    }
  }

  &-forms {
    &-checkbox {
      justify-self: start;

      padding: 0 3.2rem;

      .label {
        font-size: $font-size-l5;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-8);
      }

      & input {
        margin-right: 0.8rem;
      }
    }
  }

  &-list {
    width: 100%;

    .filters {
      // margin: 0 #{7.3rem - $shadow-resolve-padding};
      padding: 0 3.2rem;

      font-size: $font-size-default;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-2);

      .react-select-container {
        width: 100%;

        font-size: $font-size-default;
      }

      @include grid(
        $template-columns: 28rem,
        $template-rows: min-content,
        $justify-content: start
      );

      grid-gap: 2rem;

      & > * {
        grid-row: 1 / -1;
      }

      @include screen-size-px(1400) {
        padding: 1.6rem;
      }
    }

    .actions-main {
      padding: 0 3.2rem;

      // margin: 0 #{5.6rem - $shadow-resolve-padding};

      @include grid(
        $template-columns: 12rem,
        $template-rows: min-content,
        $justify-content: end
      );

      grid-gap: 2rem;

      & > * {
        grid-row: 1 / -1;
      }

      @include screen-size-px(1400) {
        padding: 1.6rem;
      }

      .actions-item {
        width: 100%;

        height: 3.2rem;

        border-radius: 0.8rem;

        border: 1px solid var(--color-comp-10);

        font-size: $font-size-l6;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);
      }
    }

    .heading {
      // margin-left: 10rem;

      padding: 0 3.2rem;

      font-size: $font-size-l1;
      font-weight: $font-weight-l2;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-2);

      @include screen-size-px(1400) {
        padding: 1.6rem;
      }

      @include screen-size-px(512) {
        font-size: $font-size-l2;
      }
    }

    &-cc {
      width: 100%;

      padding: 3.5rem;

      @include grid(
        $template-columns: repeat(auto-fit, minmax(32rem, 42rem)),
        $justify-content: center
      );

      grid-gap: 2.8rem;

      @include screen-size-px(1400) {
        padding: 1.6rem;

        grid-template-columns: repeat(auto-fit, minmax(28rem, 42rem));

        grid-gap: 1.6rem;
      }
    }

    &-card {
      width: 100%;

      min-height: 42.1rem;

      overflow: hidden;

      cursor: pointer;

      border-radius: 1rem;

      @include grid(
        $template-columns: 1fr,
        $template-rows: min-content 1fr min-content,
        $others: start
      );

      transition: $transition-default;

      box-shadow: none;

      border: 1px solid var(--color-comp-12);

      &:hover {
        @include interact-default;

        & .inspections-list-card-trash {
          display: block;
        }
      }

      &:active {
        @include interact-default(active);
      }

      &-trash {
        position: absolute;
        top: 1rem;
        right: 1rem;

        width: 2.4rem;
        height: 2.4rem;

        padding: 0.3rem;

        border-radius: 50%;

        background-color: white;

        display: none;

        fill: orangered;

        transition: $transition-default;

        &:hover {
          @include interact-svg-default;
        }

        &:active {
          @include interact-svg-default(active);
        }
      }

      .preview {
        display: block;
        width: 100%;

        & img {
          width: 100%;
          height: 18.1rem;
          object-fit: cover;
        }
      }

      .info {
        width: 100%;
        height: 100%;

        overflow-x: hidden;
        overflow-y: auto;

        padding: 3.5rem;

        font-size: $font-size-l5;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        @include grid(
          $template-columns: repeat(2, 1fr),
          $justify-content: space-between,
          $others: start
        );

        grid-gap: 1.6rem;

        @include screen-size-px(1400) {
          padding: 1.6rem;

          // grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
        }

        @include screen-size-px(512) {
          font-size: $font-size-l6;
          padding: 0.6rem;

          // grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
        }

        .info-item {
          width: 100%;

          @include grid(
            $template-columns: 1fr,
            $content: start,
            $justify-items: start,
            $align: start
          );

          & > * {
            grid-column: 1 / -1;
          }

          .title {
            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-5);

            margin-bottom: 1rem;
          }

          .description {
            width: 100%;

            font-size: $font-size-l5;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-10);

            overflow: hidden;

            white-space: nowrap;
          }
        }
      }

      .actions {
        width: 100%;
        padding: 0;

        @include grid(
          $template-columns: repeat(3, 1fr),
          $template-rows: min-content
        );

        & > * {
          grid-row: 1 / -1;
        }

        margin-bottom: 0;

        .actions-item {
          width: 100%;
          height: 4.9rem;

          margin: 0;

          border: none;
          border-top: 1px solid var(--color-comp-11);

          transition: $transition-default;

          background-color: transparent;

          &-valid {
            & svg {
              fill: #4eca2f;
            }
          }

          &-invalid {
            & svg {
              fill: #ff0000;
            }
          }

          &:nth-child(2) {
            border-left: 1px solid var(--color-comp-11);
            border-right: 1px solid var(--color-comp-11);

            & svg {
              // fill: var(--color-base-1);

              width: 3rem;
              height: 3rem;
            }
          }

          &:first-child {
            border-bottom-left-radius: 1rem;

            & svg {
              // fill: var(--color-base-1);

              width: 3rem;
              height: 3rem;
            }
          }

          &:last-child {
            border-bottom-right-radius: 1rem;

            & svg {
              // fill: var(--color-base-1);

              width: 2.4rem;
              height: 2.4rem;
            }
          }

          &:hover {
            @include interact-default;
          }

          &:active {
            @include interact-default(active);
          }
        }
      }
    }
  }

  &-client-view {
    width: 100%;

    @include grid($template-columns: 1fr, $content: start);

    .divider-horizontal {
      width: 85%;

      margin: 3.6rem 0;
    }

    .client-view-heading {
      align-self: start;

      width: 100%;

      padding: 0 3.3rem;

      @include grid(
        $template-columns: min-content 1fr,
        $template-rows: min-content
      );

      grid-gap: 3.3rem;

      @include screen-size-px(1256) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      .client-view-company-card {
        width: 25rem;
        min-height: 29rem;

        padding: 1.6rem 1.2rem;

        border-radius: 0.8rem;

        @include interact-default;

        @include grid($template-columns: 1fr);

        @include screen-size-px(1256) {
          grid-template-columns: max-content max-content;

          width: auto;

          min-height: auto;

          grid-gap: 2rem;
        }

        @include screen-size-px(720) {
          grid-template-columns: 1fr;

          width: 25rem;
          min-height: 29rem;

          grid-gap: 0;
        }

        &-preview {
          width: 100%;
          height: 15rem;

          margin-bottom: 1.6rem;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-radius: 0.8rem;
          }
        }

        &-content {
          width: 100%;

          // text-align: center;

          font-size: $font-size-l5;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-6);

          @include layout-col-start;
          text-align: center;

          // @include grid($template-columns: 1fr, $others: start);
          // text-align: left;

          grid-gap: 1rem;
        }
      }

      .client-view-timeline {
        width: 100%;

        margin-top: -8.2rem;

        @include layout-col-start;

        @include screen-size-px(1256) {
          margin-top: 0;
        }

        &-title {
          justify-self: start;

          font-size: $font-size-l2;
          font-weight: $font-weight-l1;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          // margin-left: 2.5rem;
        }

        &-wrapper {
          width: 100%;

          padding: $shadow-resolve-padding 0;

          overflow-y: hidden;

          overflow-x: auto;
        }

        &-content {
          width: 100%;

          background-color: transparent;

          @include grid(
            $template-columns: repeat(5, min-content),
            $template-rows: min-content,
            $justify-content: space-around
          );

          & > * {
            grid-row: 1 / -1;
          }

          .timeline-line {
            grid-column: 1 / 6;

            align-self: center;

            width: 100%;
            height: 1.6rem;

            border-radius: 0.8rem;

            // background image gradient left to right - white to blue
            background-image: linear-gradient(
              to right,
              var(--color-base-4),
              var(--color-base-1)
            );
          }

          .timeline-item {
            border-radius: 1.5rem;

            background-color: var(--color-comp-16);

            margin: 0 2rem;

            @include interact-default;

            @include layout-col-start;

            @include screen-size-px(720) {
              margin: 0 1rem;
            }

            // @include screen-size-px(512) {
            //   margin: 0 0.6rem;
            // }

            .timeline-title {
              font-size: $font-size-l4;
              font-weight: $font-weight-l3;
              line-height: $line-height-default;
              font-family: $font-family-1;
              color: var(--color-comp-2);

              @include screen-size-px(720) {
                font-size: $font-size-l5;
              }
            }

            .timeline-description {
              font-size: $font-size-l5;
              font-weight: $font-weight-l4;
              line-height: $line-height-default;
              font-family: $font-family-1;
              color: var(--color-comp-6);

              margin-top: 1rem;

              @include screen-size-px(720) {
                font-size: $font-size-l6;
              }
            }

            &-1 {
              grid-column: 1 / 2;

              width: 14rem;
              height: 14rem;

              padding: 3rem 0;

              @include screen-size-px(720) {
                width: 9rem;
                height: 9rem;

                padding: 2rem 0;
              }
            }

            &-2 {
              grid-column: 2 / 3;

              width: 11rem;
              height: 11rem;

              padding: 1.6rem 0;

              @include screen-size-px(720) {
                width: 7.5rem;
                height: 7.5rem;
              }
            }

            &-3 {
              grid-column: 3 / 4;

              width: 11rem;
              height: 11rem;

              padding: 1.6rem 0;

              @include screen-size-px(720) {
                width: 7.5rem;
                height: 7.5rem;

                padding: 1.6rem 0;
              }
            }

            &-4 {
              grid-column: 4 / 5;

              width: 18rem;
              height: 18rem;

              padding: 5rem 0;

              @include screen-size-px(720) {
                width: 12rem;
                height: 12rem;

                padding: 3rem 0;
              }
            }

            &-5 {
              grid-column: 5 / 6;

              width: 14rem;
              height: 14rem;

              padding: 3rem 0;

              @include screen-size-px(720) {
                width: 9rem;
                height: 9rem;

                padding: 2rem 0;
              }
            }
          }
        }
      }
    }

    .client-view-content {
      width: 100%;

      @include grid(
        $template-columns: 1fr 1.15fr,
        $template-rows: min-content,
        $others: start
      );

      grid-gap: $shadow-resolve-padding;

      @include screen-size-px(1256) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      .client-view-details {
        width: 100%;
        padding: $shadow-resolve-padding;

        @include grid($template-columns: 1fr, $others: start);

        grid-gap: $shadow-resolve-padding;

        .client-view-details-card {
          width: 100%;
          // height: 18rem;
          min-height: 18rem;

          padding: 1.9rem 3rem;

          @include interact-default;

          border-radius: 1.5rem;

          @include grid(
            $template-columns: max-content 1fr max-content,
            $content: start
          );

          @include screen-size-px(512) {
            padding: 1.6rem;
          }

          .details-card {
            &-titles {
              width: 100%;

              overflow: hidden;

              grid-row: 1 / 2;
              grid-column: 1 / -1;

              margin-bottom: 2.7rem;

              @include grid($template-columns: max-content 1fr, $others: start);

              @include screen-size-px(720) {
                grid-template-columns: 1fr;
                grid-template-rows: auto;
              }

              .details-card-title {
                font-size: $font-size-l3;
                font-weight: $font-weight-l3;
                line-height: $line-height-default;
                font-family: $font-family-1;
                color: var(--color-comp-2);
              }

              .details-card-subtitle {
                font-size: $font-size-default;
                font-weight: $font-weight-l4;
                line-height: $line-height-default;
                font-family: $font-family-1;
                color: var(--color-comp-6);

                margin-left: 1rem;

                margin-bottom: -0.4rem;
              }
            }

            &-preview {
              grid-row: 2 / 3;
              grid-column: 1 / 2;

              width: 4.8rem;
              height: 4.8rem;

              & img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                border-radius: 0.4rem;
              }
            }

            &-logo {
              width: 9rem;
              height: 7rem;

              fill: var(--color-base-1);
            }

            &-info {
              grid-row: 2 / 3;
              grid-column: 2 / 3;
              height: 100%;

              justify-self: start;

              margin-left: 0.8rem;

              font-size: $font-size-l6;
              font-weight: $font-weight-l4;
              line-height: $line-height-default;
              font-family: $font-family-1;
              color: var(--color-comp-6);

              @include grid(
                $template-columns: 1fr,
                $justify: start,
                $align-content: space-between
              );
            }

            &-actions {
              grid-row: 2 / 3;
              grid-column: 3 / 4;

              margin-bottom: -3rem;

              @include layout-col;

              @include screen-size-px(512) {
                grid-row: 3 / 4;

                margin-bottom: 0;
                margin-top: 2rem;
              }
            }

            &-action {
              outline: none;

              border: 1px solid var(--color-comp-10);

              border-radius: 1.2rem;

              padding: 0.4rem 1rem;

              font-size: $font-size-l5;
              font-weight: $font-weight-l3;
              line-height: $line-height-default;
              font-family: $font-family-1;
              color: var(--color-comp-2);

              margin-bottom: -3rem;

              & svg {
                width: 2.4rem;
                height: 2.4rem;

                fill: var(--color-comp-2);

                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }

    &-report {
      &-info-small-screen {
        width: 100%;

        padding: 0 1.6rem;

        margin-top: -4rem;

        @include grid(
          $template-columns: repeat(2, 25rem),
          $justify-content: space-between
        );

        display: none;

        @include screen-size-px(1256) {
          display: grid;
        }

        @include screen-size-px(720) {
          grid-template-columns: 25rem;
          justify-content: center;
        }
      }
    }
  }

  &-details {
    // width: 100%;

    // @include layout-col-start;

    padding: 0 3rem;

    @include screen-size-px(1024) {
      padding: 0 2.5vw;
    }

    @include screen-size-px(720) {
      transform: translateY(-5rem);
    }

    &-agreements {
      width: 100%;
      height: 100%;

      @include grid(
        $template-columns: 1fr,
        $template-rows: 1fr,
        $others: start
      );

      // grid-gap: $shadow-resolve-padding;

      &-card {
        width: 100%;

        padding: $shadow-resolve-padding;

        border-radius: 1.5rem;

        @include grid(
          $template-columns: min-content 1fr,
          $template-rows: min-content,
          $others: start
        );

        & > * {
          grid-row: 1 / -1;
        }

        grid-gap: $shadow-resolve-padding;

        & svg {
          width: 7rem;
          height: 7rem;

          fill: var(--color-base-1);
        }

        &-info {
          color: var(--color-comp-6);
          font-size: $font-size-l5;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
        }
      }

      &-actions {
        align-self: end;
        width: 100%;

        @include layout-row-space-between;

        margin-bottom: 1rem;

        @include screen-size-px(512) {
          & > * {
            font-size: $font-size-l5;
          }
        }
      }
    }

    &-modal {
      &-services {
        width: 80rem;
        min-height: 30rem;

        @include screen-size-px(1024) {
          width: calc(100vw - 2rem);
        }

        @include screen-size-px(512) {
          min-height: 20rem;
        }

        background-color: var(--color-comp-16);

        padding: $shadow-resolve-padding;

        border-radius: 1rem;

        @include interact-default;

        @include grid(
          $template-columns: 1fr,
          $template-rows: min-content 1fr min-content,
          $align-items: start
        );

        grid-gap: 1.6rem;

        & > * {
          grid-column: 1 / -1;

          width: 100%;
        }

        .edit-services {
          &-actions {
            @include layout-row-space-between;
          }
        }
      }

      &-payments {
        min-height: 30rem;

        background-color: var(--color-comp-16);

        padding: $shadow-resolve-padding;

        border-radius: 1rem;

        @include interact-default;

        @include grid(
          $template-columns: 1fr,
          $template-rows: min-content 1fr min-content,
          $align-items: start
        );

        grid-gap: 1.6rem;

        & > * {
          grid-column: 1 / -1;

          width: 100%;
        }

        @include screen-size-px(720) {
          width: calc(100vw - 2rem);
        }

        .modal-payments {
          &-cards {
            @include layout-col-start;

            grid-gap: 1.6rem;

            & > * {
              width: 100%;
            }
          }

          &-discount {
            width: 100%;

            @include layout-col-start;

            grid-gap: 1.6rem;

            & > * {
              width: 100%;
            }
          }

          &-heading {
            @include layout-row-space-between;

            & svg {
              width: 3rem;
              height: 3rem;

              fill: var(--color-base-1);
            }
          }

          &-actions {
            width: 100%;
            @include layout-row-space-between;
          }
        }
      }

      &-people {
        width: 80rem;
        max-height: 75vh;
        min-height: 30rem;

        background-color: var(--color-comp-16);

        padding: $shadow-resolve-padding;

        border-radius: 1rem;

        @include interact-default;

        @include grid(
          $template-columns: 1fr,
          $template-rows: min-content 1fr min-content,
          $others: start
        );

        grid-gap: $shadow-resolve-padding;

        .edit-people {
          &-titlebar {
            width: 100%;

            @include layout-row-space-between;

            color: var(--color-comp-4);
            font-size: $font-size-default;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;

            & svg {
              width: 3rem;
              height: 3rem;

              fill: var(--color-base-1);
            }
          }

          &-selector {
            width: 100%;

            @include grid($template-columns: 1fr, $others: start);

            & > * {
              grid-column: 1 / -1;
            }

            &-title {
              color: var(--color-comp-7);
              font-size: $font-size-l5;
              font-weight: $font-weight-l4;
              line-height: $line-height-default;
              font-family: $font-family-1;
            }

            &-component {
              width: 100%;
            }
          }

          &-decide {
            width: 100%;

            padding: 0 $shadow-resolve-padding;

            @include grid($template-columns: 1fr, $others: start);

            grid-gap: $shadow-resolve-padding;

            & > * {
              grid-column: 1 / -1;
            }
          }

          &-real-estate {
            width: 100%;

            padding: 0 $shadow-resolve-padding;

            @include grid($template-columns: 1fr);

            grid-gap: 3rem;

            &-agent {
              @include layout-col-start;

              &-title {
                color: var(--color-comp-7);
                font-size: $font-size-l6;
                font-weight: $font-weight-l4;
                line-height: $line-height-default;
                font-family: $font-family-1;
              }

              &-name {
                position: relative;

                height: $font-size-l2;

                &-text {
                  @include grid($template-columns: max-content);

                  color: var(--color-comp-7);
                  font-size: $font-size-l2;
                  font-weight: $font-weight-l4;
                  line-height: $line-height-default;
                  font-family: $font-family-1;

                  transition: $transition-default;

                  position: absolute;
                  top: 0;
                  left: 0;
                  transform: translate(-50%, 0);

                  &:hover {
                    transform: translate(-50%, -1rem);

                    color: var(--color-comp-2);
                    text-shadow: $shadow-default;
                  }
                }
              }
            }

            &-category {
              width: 15rem;

              @include layout-col-start;

              &-title {
                color: var(--color-comp-7);
                font-size: $font-size-l6;
                font-weight: $font-weight-l4;
                line-height: $line-height-default;
                font-family: $font-family-1;
              }

              &-decide {
                width: 100%;
              }
            }
          }

          &-actions {
            width: 100%;

            @include layout-row-space-between;
          }
        }
      }

      &-internals {
        width: 30rem;

        padding: $shadow-resolve-padding;

        background-color: var(--color-comp-16);

        border-radius: 1rem;

        @include interact-default;

        @include layout-col-start;

        grid-gap: $shadow-resolve-padding;

        .edit-internals {
          &-entries {
            width: 100%;

            @include grid($template-columns: 1fr, $others: start);

            grid-gap: $shadow-resolve-padding;

            & > * {
              grid-column: 1 / -1;
            }
          }

          &-titlebar {
            width: 100%;

            @include layout-row-space-between;

            color: var(--color-comp-7);
            font-size: $font-size-default;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;

            & svg {
              width: 3rem;
              height: 3rem;

              fill: var(--color-base-1);
            }
          }

          &-actions {
            width: 100%;

            @include layout-row-space-between;
          }
        }
      }

      &-agreement {
        &-confirm {
          width: 85vw;
          height: 85vh;

          @include grid(
            $template-columns: 1fr,
            $template-rows: min-content 1fr min-content
          );

          grid-gap: $shadow-resolve-padding;

          @include screen-size-px(1024) {
            width: calc(100vw - 2rem);

            height: calc(100vh - 4rem);
          }

          &-toolbar {
            width: 100%;

            @include layout-row-space-between;

            padding-bottom: 0.5rem;

            border-bottom: 1px solid var(--color-comp-14);

            color: var(--color-base-1);
            font-size: $font-size-l2;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;

            & svg {
              width: 3rem;
              height: 3rem;

              fill: var(--color-base-1);
            }
          }

          &-actions {
            width: 100%;

            // @include layout-row-space-between;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            & .btn {
              width: 12rem;

              margin-bottom: 2rem;
            }
          }
        }

        &-settings {
          width: 45rem;

          @include grid(
            $template-columns: 1fr,
            $template-rows: min-content 1fr min-content
          );

          grid-gap: $shadow-resolve-padding;

          @include screen-size-px(512) {
            width: calc(100vw - 2rem);
          }

          &-toolbar {
            width: 100%;

            @include layout-row-space-between;

            padding-bottom: 0.5rem;

            border-bottom: 1px solid var(--color-comp-14);

            color: var(--color-base-1);
            font-size: $font-size-l2;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;

            & svg {
              width: 3rem;
              height: 3rem;

              fill: var(--color-base-1);
            }
          }

          &-actions {
            width: 100%;

            @include layout-row-space-between;
          }

          .add-new .btn {
            background-color: var(--color-comp-16);

            color: var(--color-comp-10);

            & svg {
              fill: var(--color-comp-10);

              transition: $transition-default;
            }

            &:hover {
              color: var(--color-base-1);

              & svg {
                fill: var(--color-base-1);
              }
            }

            &:active {
              color: var(--color-base-1);

              & svg {
                fill: var(--color-base-1);
              }
            }
          }
        }
      }
    }

    .details-section {
      &-1 {
        width: 100%;

        // padding: 0 3rem;

        @include grid(
          $template-columns: repeat(2, 1fr),
          $template-rows: min-content
        );

        grid-gap: 1.6rem;

        @include screen-size-px(1256) {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }
      }

      &-2 {
        // padding: 1.6rem 3rem;
        padding: 1.6rem;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
          width: 12rem;
          color: var(--color-comp-16);
          font-size: $font-size-l6;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;

          padding: 0.75rem 2rem;
        }

        .details-checkbox {
          @include layout-row;

          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-8);

          & input {
            margin-right: 0.8rem;
          }

          .icon-processing {
            @include layout-row;
          }
        }
      }

      &-3 {
        width: 100%;

        // padding: 1.6rem;
        padding: 1.6rem 0;

        @include grid($template-columns: 1fr 2.7fr);

        grid-gap: $shadow-resolve-padding;

        @include screen-size-px(1256) {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }

        &-sub-1 {
          align-self: start;

          width: 100%;
          min-width: 39rem;

          padding: 1.6rem;

          @include layout-col-start;

          grid-gap: 2rem;

          @include screen-size-px(512) {
            min-width: 28rem;
          }
        }

        &-sub-2 {
          align-self: start;

          width: 100%;
          height: 100%;
          padding: 1.6rem 0;
          padding-right: 1.6rem;

          @include grid(
            $template-columns: 1fr,
            $template-rows: 1fr min-content,
            $others: start
          );

          grid-gap: 3rem;

          @include screen-size-px(1256) {
            padding-right: 0;
          }
        }
      }

      &-5 {
        width: 100%;
        // padding: 3rem;
        padding: 3rem 0;

        @include grid(
          $template-columns: repeat(2, 1fr),
          $template-rows: min-content,
          $others: start
        );

        grid-gap: 3rem;

        @include screen-size-px(1024) {
          grid-template-columns: 1fr;
          grid-template-rows: auto;
        }
      }
    }

    .details-client-view-btn {
      font-size: $font-size-l7;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-16);
    }

    .details-heading {
      width: 100%;

      align-self: start;

      padding: $shadow-resolve-padding;

      &-main-title {
        font-size: $font-size-l2;
        font-weight: $font-weight-l1;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        padding: $shadow-resolve-padding;

        @include screen-size-px(512) {
          font-size: $font-size-l3;

          padding: 0;
          margin-bottom: 2rem;
        }
      }

      &-schedules {
        width: 100%;

        @include grid(
          $template-columns: repeat(3, 1fr),
          $template-rows: min-content
        );

        padding: 3rem;

        @include screen-size-px(512) {
          // grid-template-columns: 1fr;
          // grid-template-rows: auto;

          // grid-gap: 1.6rem;

          padding: 0;
        }

        .details-schedule-box {
          padding: 1rem 0.5rem;

          @include interact-default;

          @include layout-col-space-between;

          border-radius: 1.5rem;

          text-align: center;

          align-self: end;

          @include screen-size-px(512) {
            padding: 0.5rem 0.25rem;
          }

          &-1,
          &-3 {
            width: 11rem;
            height: 11rem;

            @include screen-size-px(512) {
              width: 8rem;
              height: 8rem;
            }
          }

          &-2 {
            width: 13rem;
            height: 13rem;

            @include screen-size-px(512) {
              width: 9rem;
              height: 9rem;
            }
          }

          .schedules-title {
            font-size: $font-size-l4;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-2);

            @include screen-size-px(512) {
              font-size: $font-size-l5;
            }
          }

          .schedules-subtitle {
            font-size: $font-size-l5;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-6);

            @include screen-size-px(512) {
              font-size: $font-size-l6;
            }
          }
        }
      }
    }

    .details-street-view {
      width: 100%;

      // aspect-ratio: 16 / 9;

      // @supports not (aspect-ratio: 16 / 9) {
      //   width: 66rem;
      //   height: 31rem;
      // }

      border-radius: 1rem;

      overflow: hidden;

      // & img {
      //   width: 100%;
      //   height: 100%;

      //   object-fit: cover;
      // }

      padding: $shadow-resolve-padding;
    }

    .details-card-attachment {
      width: 100%;

      @include layout-row-space-between;

      padding: 0.5rem $shadow-resolve-padding;

      border-radius: 0.8rem;

      transition: $transition-default;

      &:hover {
        @include interact-default;

        & svg {
          fill: var(--color-base-1);
        }
      }

      & svg {
        width: 2rem;
        height: 2rem;

        fill: var(--color-comp-7);

        transition: $transition-default;
      }

      &-active-icons {
        cursor: pointer;

        &:hover {
          fill: var(--color-base-1);
          @include interact-svg-default;
        }

        &:active {
          fill: var(--color-base-1);
          @include interact-svg-default(active);
        }
      }

      &-main {
        @include layout-row-start;

        grid-gap: 1rem;
      }
    }

    .details-states-card {
      width: 100%;

      min-height: 17rem;

      padding: $shadow-resolve-padding;

      @include interact-default;

      border-radius: 1.5rem;

      @include grid(
        $template-columns: 1fr,
        $template-rows: min-content 1fr repeat(3, min-content),
        $others: start
      );

      .states-card {
        &-titlebar {
          @include layout-row-start;
        }

        &-title {
          font-size: $font-size-l3;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          @include screen-size-px(512) {
            font-size: $font-size-l4;

            font-weight: $font-weight-l2;
          }
        }

        &-action-edit {
          margin-left: 2.2rem;

          margin-bottom: -1rem;

          &:hover {
            & svg {
              fill: var(--color-base-1);

              @include interact-svg-default;
            }
          }

          &:active {
            & svg {
              fill: var(--color-base-1);

              @include interact-svg-default(active);
            }
          }

          & svg {
            width: 2.4rem;
            height: 2.4rem;

            transition: $transition-default;

            fill: var(--color-comp-4);
          }
        }

        &-action-add {
          justify-self: end;

          width: auto;

          padding: 0.2rem 1rem;

          outline: none;
          border: none;

          border-radius: 0.8rem;

          background-color: transparent;

          font-size: $font-size-default;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-base-1);

          & svg {
            width: 1.2rem;
            height: 1.2rem;

            fill: var(--color-base-1);

            margin-right: 0.4rem;
          }
        }

        &-statuses {
          width: 100%;

          // padding: 0 $shadow-resolve-padding;

          font-size: $font-size-l6;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;

          @include layout-row-space-between;

          .states-card-status {
            &-confirmed {
              color: var(--color-base-5);
            }

            &-not-confirmed {
              color: var(--color-warn-1);
            }
          }
        }
      }

      .state-paid {
        & svg {
          height: 6rem;
          width: 6rem;
          fill: green;
        }
      }

      &-inner {
        width: 100%;

        padding: $shadow-resolve-padding;

        @include layout-row-start;

        .states-card-inner {
          &-preview {
            display: block;

            width: 4.1rem;
            height: 4.1rem;

            margin-right: 1rem;

            overflow: hidden;

            border-radius: 0.4rem;

            & img {
              width: 100%;
              height: 100%;

              object-fit: cover;
            }
          }

          &-logo {
            width: 4.1rem;
            height: 4.1rem;

            fill: var(--color-base-1);

            opacity: 0.5;

            margin-right: 1rem;
          }

          &-info {
            @include layout-col-space-between;

            .inner-info {
              &-title {
                font-size: $font-size-l5;
                font-weight: $font-weight-l2;
                line-height: $line-height-default;
                font-family: $font-family-1;
                color: var(--color-comp-2);

                justify-self: start;
              }

              &-subtitle {
                font-size: $font-size-l5;
                font-weight: $font-weight-l3;
                line-height: $line-height-default;
                font-family: $font-family-1;
                color: var(--color-comp-4);

                justify-self: start;
              }
            }
          }
        }
      }

      .details-internals {
        width: 100%;

        outline: none;
        border: none;

        @include grid($template-columns: 1fr, $others: start);

        grid-gap: 1rem;

        &-card {
          width: 75%;
          min-height: 2.5rem;

          outline: none;
          border: none;
          border-bottom: 1px solid var(--color-comp-9);

          @include layout-row-start;

          &-label {
            width: 9rem;

            font-size: $font-size-l6;
            font-weight: $font-weight-l4;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-9);
          }

          &-value {
            font-size: $font-size-default;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-5);
          }
        }
      }
    }

    .details-report {
      width: 100%;
      height: 100%;
      border-radius: 1.5rem;

      padding: $shadow-resolve-padding;

      @include interact-default;

      @include grid(
        $template-columns: 1fr,
        $template-rows: repeat(3, min-content) 1fr,
        $others: start
      );

      &-heading {
        width: 100%;

        padding: 0 $shadow-resolve-padding;

        @include grid($template-columns: 1fr, $others: start);
      }

      &-divider {
        width: 100%;
        height: 1px;

        background-color: var(--color-comp-11);

        margin-top: 0.5rem;
      }

      &-titlebar {
        width: 100%;

        @include layout-row-space-between;
      }

      &-title {
        font-size: $font-size-l3;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);
      }

      &-actions {
        @include layout-row;

        grid-gap: $shadow-resolve-padding;

        .btn {
          font-size: $font-size-default;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-base-1);

          padding: 0.2rem 0.5rem;

          background-color: transparent;

          & svg {
            width: 1.2rem;
            height: 1.2rem;

            fill: var(--color-base-1);

            margin-right: 0.4rem;
          }
        }
      }

      &-info {
        width: 100%;

        padding: $shadow-resolve-padding;

        @include grid(
          $template-columns: 1fr max-content,
          $justify-items: start
        );

        grid-gap: 1.6rem;

        @include screen-size-px(512) {
          grid-template-columns: 1fr;
        }

        &-preview {
          justify-self: start;
          display: block;
          height: 6rem;
          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 16 / 9) {
            width: 6rem;
          }

          border-radius: 0.4rem;

          margin-right: 1rem;

          overflow: hidden;

          & img {
            width: 100%;
            height: 100%;

            object-fit: cover;
          }
        }

        &-titles {
          align-self: start;

          height: 100%;

          @include grid(
            $template-columns: 1fr,
            $others: start,
            $align-content: space-between
          );

          overflow: hidden;
        }

        &-title {
          font-size: $font-size-l4;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          overflow: hidden;
          white-space: nowrap;
        }

        &-subtitle {
          font-size: $font-size-l6;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          overflow: hidden;
          white-space: nowrap;
        }

        .details-report-actions {
          align-self: start;
        }

        &-progressbar {
          width: 100%;

          padding: 0 $shadow-resolve-padding;

          margin: $shadow-resolve-padding 0;
        }

        &-main-actions {
          align-self: end;

          width: 100%;

          padding: $shadow-resolve-padding;

          @include grid(
            $template-columns: repeat(2, max-content),
            $justify-content: end
          );
          grid-gap: 1.6rem;

          @include screen-size-px(512) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
          }

          .btn {
            font-size: $font-size-l5;
            font-weight: $font-weight-l1;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-6);

            padding: 0.5rem 3.6rem;

            background-color: var(--color-comp-14);

            @include screen-size-px(512) {
              padding: 0.5rem 1.6rem;
            }
          }
        }
      }
    }
  }

  &-property-details {
    width: 100%;

    // padding: 0 3rem;

    .property-details-content {
      width: 100%;

      padding: 3rem;

      border-radius: 1.5rem;

      @include interact-default;

      @include grid(
        $template-columns: repeat(2, 1fr),
        $template-rows: min-content,
        $others: start
      );

      grid-gap: 2rem;

      @include screen-size-px(1256) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }

      .property-details-info {
        width: 100%;

        @include grid($template-columns: 1fr, $others: start);

        grid-gap: 2rem;

        &-wrapper {
          width: 100%;

          border: 1px solid var(--color-comp-12);

          padding: $shadow-resolve-padding;

          border-radius: 1rem;
        }

        &-titlebar {
          @include layout-row-start;

          margin-bottom: 2rem;
        }

        &-title {
          font-size: $font-size-l3;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          @include screen-size-px(512) {
            font-size: $font-size-default;
            font-weight: $font-weight-l2;
          }
        }

        &-edit {
          width: 3rem;
          height: 3rem;

          padding: 0.5rem;

          border-radius: 50%;

          &:hover {
            & svg {
              fill: var(--color-base-1);

              @include interact-svg-default;
            }
          }

          &:active {
            & svg {
              fill: var(--color-base-1);

              @include interact-svg-default(active);
            }
          }

          & svg {
            width: 2.4rem;
            height: 2.4rem;

            transition: $transition-default;

            fill: var(--color-comp-10);
          }
        }

        &-content {
          width: 100%;

          outline: none;

          border: none;

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          gap: 2.4rem;
        }

        &-w-full {
          width: 100%;
        }

        &-w-half {
          width: 47%;

          @include screen-size-px(512) {
            width: 100%;
          }
        }

        &-text {
          border-bottom: 1px solid var(--color-comp-11);

          @include grid(
            $template-columns: 1fr,
            $template-rows: repeat(2, min-content),
            $others: start
          );

          & input {
            width: 100%;

            grid-row: 2 / 3;

            padding: 0.8rem 0;

            border: none;
            outline: none;

            &:disabled {
              background-color: transparent;
            }

            &::placeholder {
              font-size: $font-size-l5;
              font-weight: $font-weight-l4;
              line-height: $line-height-default;
              font-family: $font-family-1;
              color: var(--color-comp-10);
            }
          }

          &-titles {
            width: 100%;
            grid-row: 1 / 2;
          }

          &-title {
            font-size: $font-size-l4;
            font-weight: $font-weight-l2;
            line-height: $line-height-default;
            font-family: $font-family-1;
            color: var(--color-comp-4);

            @include screen-size-px(512) {
              font-size: $font-size-default;

              font-weight: $font-weight-l3;
            }
          }
        }
      }

      .property-details-generated {
        width: 100%;

        height: 100%;

        padding: $shadow-resolve-padding 0;

        @include grid(
          $template-columns: 1fr,
          $template-rows: 1fr min-content,
          $others: start
        );

        @include screen-size-px(1256) {
          aspect-ratio: 16 / 9;

          @supports not (aspect-ratio: 16 / 9) {
            height: 38rem;
          }
        }

        &-content {
          width: 100%;

          height: 100%;

          // aspect-ratio: 16 / 9;

          // @supports not (aspect-ratio: 16 / 9) {
          //   height: 100%;
          // }

          border-radius: 1.5rem;

          overflow: hidden;

          // padding: 3rem;

          @include interact-default;

          font-size: $font-size-default;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-6);

          & picture {
            width: 100%;
            height: 100%;

            & img {
              width: 100%;
              height: 100%;

              object-fit: cover;

              border-radius: 1.5rem;

              vertical-align: middle;
            }
          }
        }

        &-tip {
          width: 100%;

          padding: $shadow-resolve-padding;

          font-size: $font-size-l5;
          font-weight: $font-weight-l2;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-8);

          @include layout-row-start;

          & svg {
            width: 2.4rem;
            height: 2.4rem;

            fill: var(--color-base-1);

            margin-right: 0.8rem;
          }
        }
      }
    }
  }

  &-details-communications {
    width: 100%;

    aspect-ratio: 16 / 9;

    @supports not (aspect-ratio: 16 / 9) {
      height: 38rem;
    }

    padding: $shadow-resolve-padding;

    border-radius: 1.5rem;

    @include interact-default;

    @include grid(
      $template-columns: 1fr,
      $template-rows: min-content 1fr min-content,
      $others: start
    );

    @include screen-size-px(512) {
      aspect-ratio: 1 / 1;

      padding: 0.6rem;
    }

    .details-communications {
      &-titlebar {
        width: 100%;

        padding: 0 $shadow-resolve-padding;

        @include layout-row-space-between;

        @include screen-size-px(512) {
          padding: 0 0.6rem;
        }
      }

      &-title {
        font-size: $font-size-l3;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-2);

        @include screen-size-px(512) {
          font-size: $font-size-l4;
        }
      }

      &-action-share {
        font-size: $font-size-l6;
        font-weight: $font-weight-l2;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-base-1);

        padding: 0.2rem 0.5rem;

        border-radius: 0.4rem;

        background-color: transparent;

        border: none;
        outline: none;

        & svg {
          width: 1.3rem;
          height: 1.3rem;

          margin-top: -0.5rem;

          fill: var(--color-base-1);

          margin-left: 0.5rem;
        }
      }

      &-actions {
        justify-self: end;

        padding: $shadow-resolve-padding;
      }

      &-content {
        width: 100%;

        height: 100%;

        padding: $shadow-resolve-padding;

        @include screen-size-px(512) {
          padding: 0.6rem;
        }
      }

      &-no-items {
        font-size: $font-size-default;
        font-weight: $font-weight-l4;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-6);
      }

      &-card-wrapper {
        width: 100%;
        height: auto;

        padding: 0.25rem $shadow-resolve-padding;

        @include grid($template-columns: 1fr, $template-rows: 1fr);

        @include screen-size-px(512) {
          padding: 0.25rem 0.6rem;
        }
      }
    }
  }

  &-details-card-email {
    position: relative;

    width: 100%;
    height: 100%;

    overflow: hidden;

    border-radius: 0.8rem;

    font-size: $font-size-l6;
    font-weight: $font-weight-l4;
    line-height: $line-height-default;
    font-family: $font-family-1;

    transition: $transition-default;

    padding: 0.5rem $shadow-resolve-padding;

    & .details-card-email-resend {
      transition: $transition-default;

      display: none;
    }

    &:hover {
      @include interact-default;

      & .details-card-email-resend {
        display: grid;
      }
    }

    @include grid($template-columns: max-content 1fr, $others: start);

    @include screen-size-px(512) {
      padding: 0.25rem 0.6rem;
    }

    .details-card-email {
      overflow: hidden;

      &-status {
        @include grid($template-columns: 1fr, $others: start);

        margin-right: 1.6rem;

        @include screen-size-px(512) {
          margin-right: 0.6rem;
        }

        &-date {
          color: var(--color-base-6);
        }

        &-state {
          @include layout-row-start;

          & svg {
            width: 1.2rem;
            height: 1.2rem;

            margin-right: 0.4rem;
          }

          &-opened {
            color: var(--color-base-5);

            & svg {
              fill: var(--color-base-5);
            }
          }

          &-sent {
            color: var(--color-comp-6);

            & svg {
              fill: var(--color-comp-6);
            }
          }
        }
      }

      &-info {
        width: 100%;

        overflow: hidden;

        & > * {
          width: 100%;

          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      &-resend {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;

        transition: $transition-default;

        font-size: $font-size-l6;
        font-weight: $font-weight-l1;
        line-height: $line-height-default;
        font-family: $font-family-1;
        color: var(--color-comp-6);

        outline: none;
        border: none;

        padding: 0.2rem 2rem;

        border-radius: 0.8rem;

        background-color: var(--color-comp-12);

        width: 8rem;

        & .icon-processing {
          @include layout-row;
        }
      }
    }
  }
}

.related-people {
  width: 100%;
  height: 100%;

  overflow: hidden;

  @include interact-default;

  border-radius: 1.5rem;

  padding: $shadow-resolve-padding;

  @include grid(
    $template-columns: 1fr,
    $template-rows: min-content 1fr,
    $others: start
  );

  &-wrapper {
    width: 100%;
    height: 100%;

    padding: $shadow-resolve-padding;
    padding-left: 0;

    @include grid($template-columns: 1fr, $template-rows: 1fr);

    @include screen-size-px(1256) {
      padding: $shadow-resolve-padding;
    }
  }

  .client-view-people-individual-card {
    @include grid(
      $template-columns: min-content 1fr,
      $template-rows: max-content,
      $justify-content: start
    );

    .people-individual-preview {
      width: 6.7rem;
      height: 6.7rem;

      border-radius: 50%;

      margin-right: 1rem;

      fill: var(--color-comp-14);

      // background-color: var(--color-comp-15);

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        border-radius: 50%;
      }
    }

    .people-individual-info {
      height: 100%;

      &,
      &-sub {
        @include grid(
          $template-columns: 1fr,
          $others: start,
          // $align-content: space-between
        );
      }

      font-size: $font-size-l7;
      font-weight: $font-weight-l4;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-2);

      .people-individual-name {
        font-size: $font-size-l5;
        font-weight: $font-weight-l3;

        margin-bottom: 0.6rem;
      }
    }
  }

  &-scrollable {
    width: 100%;
    height: 100%;
    // height: 48rem;

    overflow-y: auto;
    overflow-x: hidden;

    padding: $shadow-resolve-padding 0;

    @include scroll-bar-default;
  }

  &-titlebar {
    width: 100%;

    padding: 0 $shadow-resolve-padding;

    @include layout-col-start;

    margin-bottom: 1.2rem;

    &-content {
      width: 100%;

      @include layout-row-space-between;
    }

    &-titles {
      padding: 0;
    }

    &-title {
      justify-self: start;

      margin-left: $shadow-resolve-padding;

      font-size: $font-size-l3;
      font-weight: $font-weight-l3;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-comp-2);

      margin-bottom: 0.5rem;
    }

    &-action {
      font-size: $font-size-default;
      font-weight: $font-weight-l2;
      line-height: $line-height-default;
      font-family: $font-family-1;
      color: var(--color-base-1);

      padding: 0 0.5rem;

      border: none;
      outline: none;

      background-color: transparent;

      cursor: pointer;

      border-radius: 0.8rem;

      & svg {
        width: 1.2rem;
        height: 1.2rem;

        fill: var(--color-base-1);

        margin-right: 0.4rem;
      }
    }

    .related-people-divider-horizontal {
      width: 100%;
      height: 1px;

      background-color: var(--color-comp-11);

      // margin: 2rem 0;
    }
  }

  &-direct {
    width: 100%;

    padding: 0 3rem;

    @include grid($template-columns: 1.2fr 1fr);

    grid-gap: #{2 * $shadow-resolve-padding};

    @include screen-size-px(1024) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }

    .related-people-inspectors {
      width: 100%;

      height: auto;

      @include interact-default;

      padding: $shadow-resolve-padding;

      border-radius: 1.5rem;

      align-self: start;

      grid-row: 1 / 2;
      grid-column: 1 / 2;

      @include grid($template-columns: 1fr, $others: start);

      .people-inspectors {
        &-titlebar {
          width: 100%;

          @include grid($template-columns: min-content 1fr, $others: start);

          @include screen-size-px(512) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;

            justify-content: center;

            justify-items: center;

            grid-gap: 1.6rem;
          }
        }

        &-titles {
          height: 100%;

          margin-top: -1rem;

          @include grid(
            $template-columns: 1fr,
            $align-content: space-between,
            $others: start
          );

          @include screen-size-px(512) {
            justify-content: center;
            justify-items: center;
          }
        }

        &-subtitle {
          font-size: $font-size-l5;
          font-weight: $font-weight-l4;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-4);

          margin-top: 2rem;

          @include screen-size-px(512) {
            margin-top: 0;
          }
        }

        &-title {
          font-size: $font-size-l3;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);
        }

        &-preview {
          &-individual {
            width: 10rem;
            height: 10rem;

            border-radius: 50%;

            overflow: hidden;

            margin-right: 1.8rem;

            & img {
              width: 100%;
              height: 100%;

              object-fit: cover;
            }
          }

          &-company {
            width: 20rem;
            aspect-ratio: 16 / 9;

            @supports not (aspect-ratio: 16 / 9) {
              height: 11.25rem;
            }

            border-radius: 0.8rem;

            overflow: hidden;

            margin-right: 1.8rem;

            & img {
              width: 100%;
              height: 100%;

              object-fit: cover;
            }
          }
        }

        &-content {
          padding: 2.4rem 0;

          @include grid($template-columns: 1fr, $align-content: space-between);

          grid-gap: 4rem;
        }
      }
    }

    .related-people-clients {
      width: 100%;
      align-self: start;

      @include layout-col-start;

      grid-gap: 3rem;

      .related-people-client {
        width: 100%;

        padding: $shadow-resolve-padding;

        border-radius: 1.5rem;

        @include interact-default;

        @include grid($template-columns: 1fr, $others: start);

        .people-client-title {
          justify-self: start;

          font-size: $font-size-l4;
          font-weight: $font-weight-l3;
          line-height: $line-height-default;
          font-family: $font-family-1;
          color: var(--color-comp-2);

          margin-bottom: 2rem;

          margin-top: -1rem;

          & svg {
            width: 2.5rem;
            height: 2.5rem;

            fill: var(--color-comp-4);

            transition: $transition-default;

            margin-left: 1rem;

            margin-bottom: -0.5rem;

            &:hover {
              @include interact-svg-default;
              fill: var(--color-base-1);
            }

            &:active {
              @include interact-svg-default(active);
              fill: var(--color-base-1);
            }
          }
        }
      }

      .people-client-divider {
        width: 100%;

        height: 1px;

        background-color: var(--color-comp-11);

        margin: 2rem 0;
      }
    }
  }

  .related-people-brokers {
    width: 100%;

    padding: 0 3rem;

    margin-top: 3rem;
    margin-top: $shadow-resolve-padding;

    @include grid($template-columns: 1fr, $others: start);

    grid-gap: 3rem;

    .related-people-broker {
      width: 100%;
      justify-self: start;

      @include interact-default;

      padding: $shadow-resolve-padding;

      border-radius: 1.5rem;

      @include layout-col-start;

      .people-broker-title {
        justify-self: start;

        color: var(--color-comp-2);
        font-size: $font-size-l4;
        font-weight: $font-weight-l3;
        line-height: $line-height-default;
        font-family: $font-family-1;

        & svg {
          width: 2.5rem;
          height: 2.5rem;

          fill: var(--color-comp-4);

          transition: $transition-default;

          margin-left: 1rem;

          margin-bottom: -0.5rem;

          &:hover {
            @include interact-svg-default;
            fill: var(--color-base-1);
          }

          &:active {
            @include interact-svg-default(active);
            fill: var(--color-base-1);
          }
        }
      }

      .people-broker-content {
        width: 100%;

        margin-top: $shadow-resolve-padding;

        @include layout-row-space-between;

        .people-broker-actions {
          align-self: end;
          @include grid(
            $template-columns: repeat(2, 1fr),
            $template-rows: min-content
          );

          grid-gap: 2.4rem;

          .people-broker-action {
            padding: 0rem $shadow-resolve-padding;

            border-radius: 0.8rem;

            border: 1px solid var(--color-comp-10);

            color: var(--color-comp-6);
            font-size: $font-size-default;
            font-weight: $font-weight-l3;
            line-height: $line-height-default;
            font-family: $font-family-1;
          }
        }
      }
    }

    .people-brokers-divider-horizontal {
      width: 100%;
      height: 1px;

      background-color: var(--color-comp-11);

      margin: $shadow-resolve-padding 0;
    }
  }
}

.inspection-details-edit-individual {
  padding: $shadow-resolve-padding;

  width: 35rem;

  background-color: white;

  @include interact-default;

  border-radius: 0.8rem;

  @include layout-col-start;

  grid-gap: $shadow-resolve-padding;

  &-titlebar {
    width: 100%;

    border-bottom: 1px solid var(--color-comp-11);

    color: var(--color-comp-2);
    font-size: $font-size-l4;
    font-weight: $font-weight-l3;
    line-height: $line-height-default;
    font-family: $font-family-1;
  }

  &-form {
    width: 100%;

    border: none;

    @include layout-col-start;

    grid-gap: $shadow-resolve-padding;
  }

  &-actions {
    width: 100%;

    @include layout-row-space-between;
  }
}
