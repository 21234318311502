@use "./grids" as *;

@mixin layout-row-space-between() {
  @include grid($template-rows: min-content, $justify-content: space-between);

  & > * {
    @include grid-item-area($row-start: 1);
  }
}

@mixin layout-row-start() {
  @include grid($template-rows: min-content, $justify-content: start);

  & > * {
    @include grid-item-area($row-start: 1);
  }
}

@mixin layout-row() {
  @include grid($template-rows: min-content);

  & > * {
    @include grid-item-area($row-start: 1);
  }
}

@mixin layout-col-space-between() {
  @include grid($template-columns: 1fr, $align-content: space-between);

  & > * {
    @include grid-item-area($column-start: 1);
  }
}

@mixin layout-col-start() {
  @include grid($template-columns: 1fr, $align-content: start);

  & > * {
    @include grid-item-area($column-start: 1);
  }
}

@mixin layout-col() {
  @include grid($template-columns: 1fr);

  & > * {
    @include grid-item-area($column-start: 1);
  }
}

@mixin layout-col-mic-1fr() {
  @include grid($template-columns: 1fr, $template-rows: min-content 1fr);

  & > * {
    grid-column: 1 / -1;
  }
}

@mixin layout-col-2x-1fr() {
  @include grid($template-columns: 1fr, $template-rows: 1fr 1fr);

  & > * {
    grid-column: 1 / -1;
  }
}

@mixin layout-row-mic-1fr() {
  @include grid(
    $template-columns: min-content 1fr,
    $template-rows: 1fr,
    $content: start,
    $align-items: start
  );

  & > * {
    grid-row: 1 / -1;
  }
}
