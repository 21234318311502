@use "../../abstract" as *;

$btn-ratio: 1.1;
$action-numbers: (1, 2, 3, 4, 5, 6);

@mixin action-btn($number) {
  $btn-size: 6.6rem;
  $delay-base: 0.5s;
  $delay: #{($number - 1) * $delay-base};

  &-btn-#{$number} {
    // background-color: rgba(purple, 0.25);
    background-color: var(--color-base-1);

    width: $btn-size;
    height: $btn-size;

    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: $font-weight-l2;

    text-align: center;

    // border: 1px solid var(--color-base-1);

    border-radius: 50%;

    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;

    box-shadow: none;

    @include layout-col;

    z-index: -1;

    &-active {
      animation: move-btn-up-#{$number} 0.5s linear forwards;
    }

    &-inactive {
      animation: move-btn-down-#{$number} 0.5s ease forwards;
    }
  }
}

@mixin btn-animation($number) {
  $distance: #{-100% * $btn-ratio * $number};
  $distance-over: 2.5%;
  $distance-less: calc($distance - $distance-over);
  $distance-more: calc($distance + $distance-over);

  @keyframes move-btn-up-#{$number} {
    0% {
      transform: translateY(0);
      opacity: 0;
      z-index: -1;
      display: none;

      box-shadow: none;
    }

    60% {
      transform: translateY($distance-more);
      opacity: 1;
      z-index: 100;
      display: grid;

      box-shadow: $shadow-default;
    }

    70% {
      transform: translateY($distance-less);
      opacity: 1;
      z-index: 100;
      display: grid;

      box-shadow: $shadow-default;
    }

    80% {
      transform: translateY($distance-more);
      opacity: 1;
      z-index: 100;
      display: grid;

      box-shadow: $shadow-default;
    }

    90% {
      transform: translateY($distance-less);
      opacity: 1;
      z-index: 100;
      display: grid;

      box-shadow: $shadow-default;
    }

    100% {
      transform: translateY($distance);
      opacity: 1;
      z-index: 100;
      display: grid;

      box-shadow: $shadow-default;
    }
  }

  @keyframes move-btn-down-#{$number} {
    0% {
      transform: translateY($distance);
      opacity: 1;
      z-index: 100;
      display: grid;

      box-shadow: $shadow-default;
    }

    100% {
      transform: translateY(0);
      opacity: 1;
      z-index: -1;
      display: none;

      box-shadow: none;
    }
  }
}
@each $action-number in $action-numbers {
  @include btn-animation($action-number);
}

@mixin add-action-buttons() {
  @each $action-number in $action-numbers {
    @include action-btn($action-number);
  }
}

.footer-mobile {
  width: 100%;

  z-index: 100;

  position: fixed;
  bottom: 0;
  left: 0;

  @include layout-col;

  display: none;

  @include screen-size-px(1024) {
    display: grid;
  }

  .add-new {
    width: 100%;

    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(white, 1) 70%,
      rgba(white, 1) 100%
    );

    position: relative;

    @include layout-col;

    & .action {
      position: relative;
      $temp-border: 0.8rem;
      $temp-size: 5rem + 2 * $temp-border;

      width: $temp-size;
      height: $temp-size;

      display: inline-block;

      cursor: pointer;

      // border: $temp-border solid var(--color-comp-16);

      border-radius: 50%;

      margin-bottom: -4rem;

      @include layout-col;

      @include add-action-buttons();

      &-bg {
        grid-column: 1 / -1;
        grid-row: 1 / -1;

        z-index: 1000;

        background-color: var(--color-comp-11);

        width: $temp-size;
        height: $temp-size;

        border-radius: 50%;
      }

      & svg {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
        display: inline-block;

        z-index: 1000;

        // grid-column: 1 / -1;
        // grid-row: 1 / -1;

        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .svg-footer {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, calc(100% - 1px));

      fill: white;

      height: 4.5rem;

      width: 15rem;

      z-index: 100;
    }

    .svg-add {
      &-active {
        animation: rotate-45 0.5s ease forwards;
      }

      &-inactive {
        animation: rotate-back-45 0.5s ease forwards;
      }
    }
  }

  .actions {
    width: 100%;

    padding: 1.6rem 5vw;
    padding-bottom: 0.8rem;

    background-color: var(--color-base-1);

    @include layout-row-space-between;

    .actions-side {
      @include layout-row;
      grid-gap: 10vw;

      & svg {
        width: 2.2rem;
        height: 2.2rem;

        fill: var(--color-comp-16);
      }
    }
  }
}

@keyframes rotate-45 {
  0% {
    transform: rotate(0deg);

    fill: var(--color-base-1);
  }
  100% {
    transform: rotate(45deg);

    fill: var(--color-comp-3);
  }
}

@keyframes rotate-back-45 {
  0% {
    transform: rotate(45deg);

    fill: var(--color-comp-3);
  }

  100% {
    transform: rotate(0deg);

    fill: var(--color-base-1);
  }
}
