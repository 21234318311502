@use "../../abstract" as *;

.section {
  width: 100%;
  // height: 100%;
  // height: 40rem;

  border-radius: 1.6rem;

  background-color: var(--color-comp-16);

  box-shadow: $shadow-1;

  overflow: hidden;

  // TODO:
  $padding: 2.4rem;
  padding: $padding $padding 2rem $padding;

  @include grid(
    $template-columns: 1fr,
    $template-rows: repeat(2, min-content) 1fr,
    $align-content: start
  );

  & > * {
    width: 100%;

    grid-column: 1 / -1;
  }

  .title-bar {
    width: calc(100% - #{2 * $shadow-resolve-padding});

    .discrete-actions {
      display: none;

      @include layout-row;
      grid-gap: 1rem;

      // @include screen-size-l2 {
      //   display: none;
      // }
    }

    .aggregated-actions {
      @include layout-row;
      display: none;

      // @include screen-size-l2 {
      //   display: grid;
      // }
    }
  }

  .divider-horizontal {
    width: calc(100% - #{2 * $shadow-resolve-padding});
  }

  .title {
    font-family: $font-family-1;
    font-size: $font-size-l3;
    font-weight: $font-weight-l3;
    line-height: $line-height-l1;
    color: var(--color-comp-2);
  }

  .subtitle {
    font-family: $font-family-1;
    font-size: $font-size-l5;
    font-weight: $font-weight-l4;
    line-height: $line-height-l1;
    color: var(--color-comp-2);

    margin-left: 0.6rem;

    margin-bottom: -0.8rem;
  }

  .action {
    width: 7.1rem;
    height: 3.2rem;

    border-radius: 0.8rem;
    margin-left: 0.8rem;

    background-color: var(--color-comp-31);

    @include grid;
  }

  &-content {
    height: 100%;
    width: 100%;

    overflow-x: hidden;

    overflow-y: auto;
  }
}
