@use "../../abstract" as *;

$switch-width: 5.2rem;
$switch-pin-size: 1.6rem;
$switch-space: 0.5rem;
$switch-border-size: 0.1rem;
$switch-nav-size: $switch-width - $switch-pin-size - $switch-space;
$switch-height: $switch-pin-size + ($switch-space * 2) +
  ($switch-border-size * 2);
$switch-radius: calc($switch-height / 2);

$switch-transform-off: translate($switch-space, $switch-space);
$switch-transform-on: translate($switch-nav-size, $switch-space);

$switch-speed: 0.5s;

@mixin switch-animation($state) {
  animation: switch-#{$state} $switch-speed ease-in-out forwards;
}

.switch {
  width: $switch-width;
  height: $switch-height;
  border: $switch-border-size solid var(--color-comp-11);
  border-radius: $switch-radius;

  transition: $transition-default;

  display: inline-block;

  & > div {
    width: $switch-pin-size;
    height: $switch-pin-size;
    border-radius: 50%;

    transition: $transition-default;

    margin: 0;
    padding: 0;
    border: none;
  }

  &-on {
    background-color: var(--color-base-1);

    & > div {
      // @include switch-animation(on);

      transform: $switch-transform-on;
      background-color: var(--color-comp-16);
    }
  }

  &-off {
    background-color: var(--color-comp-10);
    border: $switch-border-size solid var(--color-comp-10);

    & > div {
      // @include switch-animation(off);
      transform: $switch-transform-off;
      background-color: var(--color-comp-16);
    }
  }

  &:hover {
    @include interact-default;
  }
}

// switch animations
@keyframes switch-on {
  0% {
    transform: $switch-transform-off;
  }
  100% {
    transform: $switch-transform-on;
  }
}

@keyframes switch-off {
  0% {
    transform: $switch-transform-on;
  }
  100% {
    transform: $switch-transform-off;
  }
}
